<div *ngIf="proposal">
  <mat-tab-group mat-align-tabs="left" animationDuration="0ms">

    <mat-tab label="Básico">
      <h3 class="proposal-detail-title">Cliente</h3>
      <div *ngIf="proposal.personDTO.typePerson == 'P'" class="proposal-detail">
        CPF: {{proposal.personDTO.cpf}}<br>
        Nome fantasia: {{proposal.personDTO.fantasyName}}<br>
        Nome: {{proposal.personDTO.name}}<br>
        Sobrenome: {{proposal.personDTO.surname}}<br>
        Nome da mãe: {{proposal.personDTO.motherName}}<br>
        Nacionalidade: {{proposal.personDTO.nationality}}<br>
        <ng-container *ngIf="proposal.personDTO.rne">
        Registro Nacional de Estrangeiro: {{proposal.personDTO.rne}}<br>
        </ng-container>
        Data de nascimento: {{proposal.personDTO.birthDate}}<br>
        Local de nascimento: {{proposal.personDTO.localBirth}}<br>
        Pessoa exposta politicamente? {{proposal.personDTO.pepIndicator}}<br>
        Gênero: {{proposal.personDTO.gender}}<br>
      </div>

      <div *ngIf="proposal.personDTO.typePerson == 'L'" class="proposal-detail">
        CNPJ: {{proposal.personDTO.cnpj}}<br>
        Razão social: {{proposal.personDTO.socialReason}}<br>
        Nome fantasia: {{proposal.personDTO.fantasyName}}<br>
        Apelido: {{proposal.personDTO.alliasName}}<br>
        Data de constituição: {{proposal.personDTO.dateConstitution}}<br>
        Faturamento medio mensal: {{proposal.personDTO.monthlyAverageBilling}}<br>
        Data de abertura: {{proposal.personDTO.openDate}}<br>
      </div>

      <div *ngIf="proposal.optInTermsAcceptance !== null && proposal.optInTermsAcceptance !== undefined" class="proposal-detail">
        Termo de Aceite FIDIC: {{!!proposal.optInTermsAcceptance ? "Sim" : "Não"}}
      </div>

      <div *ngIf="proposal.partnerId !== null" class="proposal-detail">
        Id do Cliente no Parceiro: {{proposal.partnerId}}
      </div>
      <h3 class="proposal-detail-title">Ativação</h3>
      <div class="proposal-detail">
        EC: {{proposal.merchant.merchantId}}<br>
        <span *ngIf="proposal.existsPaymentSplit && proposal.splitPgtoDetails && proposal.splitPgtoDetails.ecType">
          Split de Pagamentos: {{proposal.splitPgtoDetails.ecType === 'EC_PRINCIPAL' ? 'Master' : 'Seller'}}<br>
        </span>
        CPF/CNPJ: {{proposal.personDTO.cpf}}{{proposal.personDTO.cnpj}}<br>
        <span *ngIf="proposal.merchantKey">
            Merchant Key: {{proposal.merchantKey.merchantKey}}<br>
            Merchant Id: {{proposal.merchantKey.merchantId}}<br>
        </span>
      </div>
      <div class="proposal-detail" *ngFor="let activation of proposal.activationDataDTO">
        <b>{{activation.product}}</b><br>
        OS: {{activation.os}}<br>
        Número Lógico: {{activation.logicNumber}} <br>
        Status: {{getStatusTerminal(activation.status)}} <br>
        Data de ativação: {{activation.activeDate ? (activation.activeDate | date: 'dd/MM/yyyy') : '-'}} <br>
        Status da Os: {{activation.osStatus}} <br>
      </div>
      <h3 class="proposal-detail-title">Produto</h3>
      <div *ngFor="let product of proposal.product" class="proposal-detail">
        Produto: {{product.serviceName}} ({{product.serviceId}})<br>
        Operadora: {{product.mobileOperator}}<br>
        Valor: {{product.value}}{{product.salesValue}}<br>
        Modalidade: {{product.modality}}<br>
      </div>
      <div  class="proposal-detail">
        Quantidade Terminais Acatados: <b>{{proposal.approvedNumberTerminals}}<br></b>
        Quantidade Terminais Não Acatados: <b>{{proposal.deniedNumberTerminals}}<br></b>
      </div>
      <h3 class="proposal-detail-title">Aprovação da alçada comercial</h3>
      <div *ngIf="proposal.priceJudgment" class="proposal-detail">
        Gerente: {{getPriceJudgmentApproval(1)}}<br>
        Diretor: {{getPriceJudgmentApproval(2)}}<br>
        Diretor Executivo: {{getPriceJudgmentApproval(3)}}<br>
      </div>
      <h3 class="proposal-detail-title">Status</h3>
      <div class="proposal-detail">
        Proposta: {{proposal.proposalStatus}}<br>
        Pagamento: {{proposal.paymentStatus}}<br>
        Risco: {{proposal.riskCenterStatus}} <br>
        <span *ngIf="proposal.accreditationFlowDescription">Fluxo de credenciamento:
          {{proposal.accreditationFlowDescription}}<br></span>
      </div>

      <div *ngIf="proposal?.typeFlowCreditRisk !== null">
        <h3 class="proposal-detail-title">Fluxo de Aprovação Crédito e Risco</h3>
        <div class="proposal-detail">
          <li> {{proposal.typeFlowCreditRisk ? 'Stoneage' : 'Diligence'}}
        </div>
      </div>

      <div *ngIf="proposal.proposalRejectionReason != null && proposal.proposalRejectionReason.length > 0">
       <h3 class="proposal-detail-title">Motivos de Rejeição Crédito</h3>
        <div *ngFor="let reason of proposal.proposalRejectionReason" class="proposal-detail">
           <li> {{reason.rejectionCd}} - {{reason.reasonDescription}} <br>
        </div>
      </div>

      <ng-container *ngIf="creditAndRiskProposalNote?.length > 0">
        <h3 class="proposal-detail-title">Observações de crédito e risco</h3>
        <div class="proposal-detail " *ngFor="let item of creditAndRiskProposalNote; let i = index">
          Descrição: {{item.proposalNote}}<br>
          Data: {{item.lastModifiedDate}}<br>
        </div>
      </ng-container>
      <div *ngIf="proposal.deliveryInformations" class="proposal-detail">
        <h3 class="proposal-detail-title">Informações de Entrega</h3>
        Tipo: {{getDeliveryType(proposal.deliveryInformations[0].deliveryType)}}
      </div>
      <div *ngIf="proposal.deliveryInformations" class="proposal-detail">
        <h3 class="proposal-detail-title">{{ proposal.economicGroupConvenienceId ? "Grupo Econômico por Conveniência" : "Grupo Econômico"}}</h3>
        <div *ngIf="!proposal.economicGroupConvenienceId;else other_content">
          Nome: {{proposal.economicGroup ? proposal.economicGroup.name:'SEM GRUPO'}}
        </div>
        <ng-template #other_content>Nome: {{proposal.economicGroupConvenience ? proposal.economicGroupConvenience.name:'SEM GRUPO'}}</ng-template>
      </div>

    </mat-tab>
    <mat-tab label="Histórico">
      <div>
        <table mat-table [dataSource]="proposal.history" style="width: 100%">
          <ng-container matColumnDef="proposalStatus">
            <th mat-header-cell *matHeaderCellDef> Status da proposta </th>
            <td mat-cell *matCellDef="let history"> {{history.proposalStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="paymentStatus">
            <th mat-header-cell *matHeaderCellDef> Status do pagamento </th>
            <td mat-cell *matCellDef="let history"> {{history.paymentStatus}} </td>
          </ng-container>
          <ng-container matColumnDef="data">
            <th mat-header-cell *matHeaderCellDef> Data do status </th>
            <td mat-cell *matCellDef="let history"> {{history.dateTime}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
      </div>
    </mat-tab>
    <mat-tab label="EC">
      <br>
      <div class="proposal-detail">
        Instituição: {{proposal.merchant.institutionId}}<br>
        Service Contract: {{proposal.merchant.idServiceContract}}<br>
        CNAE: {{proposal.merchant.cnae}}<br>
        MCC: {{proposal.merchant.mcc}}<br>
        Cartão estrangeiro: {{proposal.merchant.foreignCard}}<br>
        Transação digitada: {{proposal.merchant.keyedTransac}}<br>
        Ecommerce: {{proposal.merchant.ecommerceIndicator}}<br>

        <h3 class="proposal-detail-title">Faturamento</h3>
        Faturamento anual: {{proposal.merchant.annualBillingVolume}}<br>
        Volume de dinheiro anual: {{proposal.merchant.annualVolumeOfCash}}<br>
        Volume de vendas no cartão: {{proposal.merchant.annualVolumeOfSalesCard}}<br>
        Volume de vendas no cartão do grupo: {{proposal.merchant.annualVolumeSalesCardGroup}}<br>
        Volume de vendas mensal: {{proposal.merchant.monthlySalesVolume}}<br>
        Ticket Médio: {{proposal.merchant.averageTicket}}<br>

        <h3 class="proposal-detail-title">Hierarquia</h3>
        Id do tipo de canal: {{proposal.merchant.idHierarchyChannelType}}<br>
        <ng-container *ngIf="proposal.merchant.resellerCode">
          Código Reseller: {{proposal.merchant.resellerCode}}<br>
        </ng-container>
        Descrição do tipo de canal: {{proposal.merchant.hierarchyChannelTypeDescription}}<br>
        Codigo do canal: {{proposal.merchant.codeHierarchyChannel}}<br>
        Descrição do canal: {{proposal.merchant.hierarchyChannelDescription}}<br>
        Código do sub-canal{{proposal.merchant.codeHierarchySubChannel}}<br>
        Descrição do sub-canal:{{proposal.merchant.hierarchySubDescription}}<br>
        Id do agente: {{proposal.merchant.idHierarchyChannelAgent}}<br>
        CPF/CNPJ do agente: {{proposal.merchant.agentCpfCnpj}}<br>
        Nome do agente: {{proposal.merchant.agentName}}<br>
        E-mail do agente: {{proposal.merchant.agentEmail}}<br>

        <h3 class="proposal-detail-title">Outros</h3>

        Permissão de Bacen: {{proposal.merchant.bacenPermission}}<br>
        Percentual de cartão não presente: {{proposal.merchant.percentageCardNotPresent}}<br>
        Percentual de cartão presente: {{proposal.merchant.percentageCardPresent}}<br>
        Percentual Internet: {{proposal.merchant.percentageInternet}}<br>
        Antecipação manual: {{proposal.merchant.prepaymentIndicator}}<br>
        Taxa de Antecipação(re):{{proposal.merchant.releasedPrepaymentFee}}<br>
        Taxa de Antecipação(un):{{proposal.merchant.unreleasedPrepaymentFee}}<br>
        Transação recorrente: {{proposal.merchant.recurringTransaction}}<br>
        Service day 0:{{proposal.merchant.serviceDay0}}<br>
        Service day 17:{{proposal.merchant.serviceDay17}}<br>
        Service day 15-30:{{proposal.merchant.serviceDay1530}}<br>
        Service day 814: {{proposal.merchant.serviceDay814}}<br>
        Service day acima de 30: {{proposal.merchant.serviceDayOver30}}<br>
        site do ec: {{proposal.merchant.urlMerchant}}<br>
      </div>
    </mat-tab>
    <mat-tab label="Endereço">
      <br>
      <div>
        <div *ngFor="let address of proposal.addressDetailedDTO" class="proposal-detail">
          <h3 class="proposal-detail-title">{{address.type}}</h3>
          Cep: {{address.zipCode}}<br>
          Rua: {{address.street}}<br>
          Número: {{address.number}}<br>
          Complemento: {{address.complement}}<br>
          UF: {{address.uf}}<br>
          Bairro: {{address.neighborhoodDescription}}<br>
          Cidade: {{address.cityDescription}}<br>
          Estado: {{address.stateDescription}}<br>
          País: {{address.countryDescription}}<br>
          IBGE CODE: {{address.ibgeCityCode}}<br>
          Rua enviada: {{address.sentStreet}}<br>
          Bairro enviado: {{address.sentNeighborhoodDescription}}<br>
          Cidade enviada: {{address.sentCityDescription}}<br>
          UF enviado: {{address.sentUf}}<br><br>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Dados bancários">
      <br>
      <div>
        <div *ngFor="let bankAccount of proposal.bankAccountDetailedDTO" class="proposal-detail">
          <h3 class="proposal-detail-title">{{bankAccount.description}}</h3>
          Moeda: {{bankAccount.currency}}<br>
          Banco: {{bankAccount.bankNumber}}<br>
          Agencia: {{bankAccount.agencyNumber}}<br>
          Conta: <ng-container *ngIf="hideShowAccount">{{bankAccount.accountNumber}}<ng-container *ngIf="bankAccount.accountType !== 'PAYMENT'">
            -{{bankAccount.accountDigit}}</ng-container></ng-container>
            <fa-icon *ngIf="!hideShowAccount && !decryptConfig" class="bank_hide_icon" icon="eye" (click)='hideShowAccount=!hideShowAccount;'></fa-icon>
            <fa-icon *ngIf="hideShowAccount && !decryptConfig" class="bank_hide_icon" icon="eye-slash" (click)='hideShowAccount=!hideShowAccount;'></fa-icon><br>
          Tipo: {{getAccountModalityDescription(bankAccount.accountType)}}<br>
          Titular: {{bankAccount.accountOwnerName}}<br>
          <ng-container *ngIf="isAnticipationExternal(bankAccount.prepaymentEnable, bankAccount.prepaymentMerchantType)">
            Antecipação automática e Agenda Externa habilitada? {{bankAccount.prepaymentEnable && !bankAccount.prepaymentMerchantType ? 'Sim' : 'Não'}}<br>
          </ng-container>
          <ng-container *ngIf="!isAnticipationExternal(bankAccount.prepaymentEnable, bankAccount.prepaymentMerchantType)">
            Antecipação automática habilitada? {{bankAccount.prepaymentEnable ? 'Sim' : 'Não'}}<br>
          </ng-container>
          <ng-container *ngIf="bankAccount.prepaymentEnable">
            Inicio da antecipação automática: {{bankAccount.dtStartAutoPrepayment}}<br>
          <ng-container *ngIf="canShowEndAutoPrepayment(bankAccount.dtEndAutoPrepayment)">
            Fim da antecipação automatica: {{bankAccount.dtEndAutoPrepayment}}<br>
          </ng-container>
          Frequencia da antecipação: {{getAutoprepaymentFrequencyPtDescription(bankAccount.frequencyAutoPrepayment)}}<br>
          Período da antecipação: {{bankAccount.periodAutoPrepayment}}<br>
          </ng-container>
          <ng-container *ngIf="bankAccount.prepaymentEnable">
            Taxa de Antecipação: {{bankAccount.prePaymentFeeMerchantBankingAccount | number: '1.2-2':'pt'}}%<br>
          </ng-container>
          <ng-container *ngIf="bankAccount.type !== 'INSTALLMENT'">
            Taxa MDR: <label *ngIf="bankAccount.prepaymentFee">{{bankAccount.prepaymentFee | number: '1.2-2':'pt'}}%</label><br>
          </ng-container>
          <ng-container *ngIf="bankAccount.type === 'INSTALLMENT'">
            Taxa MDR parcelado até 6x: <label *ngIf="bankAccount.prepaymentFeeLessThanSix">{{bankAccount.prepaymentFeeLessThanSix | number: '1.2-2':'pt'}}%</label><br>
            Taxa MDR parcelado maior que 6x: <label *ngIf="bankAccount.prepaymentFeeMoreThanSeven">{{bankAccount.prepaymentFeeMoreThanSeven | number: '1.2-2':'pt'}}%</label><br>
          </ng-container>
          Tipo da transação: {{bankAccount.prepaymentTransactionType}}<br><br>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Contatos">
      <br>
      <div>
        <div class="proposal-detail">
          <h3 class="proposal-detail-title"></h3>
          Nome: {{contact.name}}<br>
          Telefone 1: {{contact.phoneNumber1}}<br>
          Telefone 2: {{contact.phoneNumber2}}<br>
          Celular: {{contact.mobileNumber}}<br>
          E-mail: {{contact.email}}<br><br>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Licença profissional" *ngIf="proposal.professionalLicense">
      <br>
      <div *ngIf="isInvoice(proposal.professionalLicense.licenseTypeName)">
        <div *ngFor="let invoice of proposal.professionalLicense.invoiceValues" class="proposal-detail">
          <h3 class="proposal-detail-title">Nota fiscal</h3>
          Número da nota fiscal: <b>{{invoice.invoiceNumber}}</b><br>
          Valor da nota: <b>{{invoice.invoiceValue | currency: 'BRL'}}</b><br>
          Tipo de produto: <b>{{invoice.productType}}</b><br>
          Data de Emissão: <b>{{invoice.invoiceDate}}</b><br><br>
        </div>
      </div>

      <div *ngIf="isOperatingLicense(proposal.professionalLicense.licenseTypeName)" class="proposal-detail">
        <h3 class="proposal-detail-title">Alvará de Funcionamento</h3>
        Número do Processo: <b>{{proposal.professionalLicense.processNumber}}</b><br>
        Número do DML: <b>{{proposal.professionalLicense.dmlNumber}}</b><br>
        Validade do documento: <b>{{proposal.professionalLicense.validityDate}}</b><br><br>
      </div>

      <div *ngIf="isProfessionalCouncil(proposal.professionalLicense.licenseTypeName)" class="proposal-detail">
        <h3 class="proposal-detail-title">Registro profissional</h3>
        Número do documento: <b>{{proposal.professionalLicense.numberDocument}}</b><br>
        Nome da Mãe: <b>{{proposal.professionalLicense.motherName}}</b><br>
        Nacionalidade: <b>{{proposal.professionalLicense.nationality}}</b><br>
        Categoria do registro profissional: <b>{{proposal.professionalLicense.professionalCouncilCategory}}</b><br>
        Validade do documento: <b>{{proposal.professionalLicense.validityDate}}</b><br><br>
      </div>

    </mat-tab>

    <mat-tab *ngIf="proposal.partners.length>0" label="Sócios">
      <br>
      <div>
          <div *ngFor="let partner of proposal.partners; let i = index" class="proposal-detail">
            <h3 class="proposal-detail-title">Socio {{i+1}}</h3>
            <ng-container *ngIf="partner.type === 'P'">
              CPF/CNPJ: {{partner.cnpjCpf}}<br/>
              Nome: {{partner.firstName}} {{partner.middleName}} {{partner.lastName}}<br/>
              Nacionalidade: {{partner.nationality}}<br/>
              <ng-container *ngIf="partner.rne">
                Registro Nacional de Estrangeiro: {{partner.rne}}<br/>
                </ng-container>
                Participação Social: {{partner.percPartSoc | number:'0.2-2'}}% <br/>
                  Nascimento: {{partner.birthDate | date: 'dd/MM/yyyy'}} <br/>
                  Nome Mãe: {{partner.motherName}}<br/>
                  Politicamente Exposta: {{partner.pep=== true ? 'Sim':'Não'}}<br/>
            </ng-container>
            <ng-container *ngIf="partner.type === 'L'">
              CPF/CNPJ: {{partner.cnpjCpf}}<br/>
              Nome: {{partner.socialReason}}<br/>
              Data da fundação: {{partner.openDate | date: 'dd/MM/yyyy'}} <br/>
              Participação Social: {{partner.percPartSoc | number:'0.2-2'}}% <br/>
            </ng-container>
            <ul *ngIf="partner.pep && partner.politicallyExposedPersonResponse != null" class="list-pep">
              <li >Nome: {{partner.politicallyExposedPersonResponse.name}}</li>
              <li >Cargo: {{partner.politicallyExposedPersonResponse.responsibility}}</li>
              <li >Órgão: {{partner.politicallyExposedPersonResponse.governmentAgency}}</li>
            </ul>
            <div *ngFor="let legalRepresentative of partner.legalRepresentative; let i = index" class="proposal-detail">
            <ng-container *ngIf="legalRepresentative">
              <h3 class="proposal-detail-title">Representante Legal</h3>
              CPF: {{legalRepresentative.cpf}}   <br/>
              Nome: {{legalRepresentative.name}}<br/>
              Nacionalidade: {{legalRepresentative.nationality}} <br/>
              <ng-container *ngIf="legalRepresentative.rne">
                Registro Nacional de Estrangeiro: {{legalRepresentative.rne}}<br/>
                </ng-container>
              Nascimento: {{legalRepresentative.birthDate  | date: 'dd/MM/yyyy'}} <br/>
              Politicamente Exposta: {{legalRepresentative.politicallyExposedPerson != null ? 'Sim':'Não'}}<br/>
              <ul *ngIf="legalRepresentative.politicallyExposedPerson != null" class="list-pep">
                <li >Nome: {{legalRepresentative.politicallyExposedPerson.name}}</li>
                <li >Cargo: {{legalRepresentative.politicallyExposedPerson.responsibility}}</li>
                <li >Órgão: {{legalRepresentative.politicallyExposedPerson.governmentAgency}}</li>
              </ul>
            </ng-container>
          </div>

          </div>
      </div>
    </mat-tab>
    <mat-tab label="Validação Serpro">
      <h3 class="proposal-detail-title">Validação Serpro</h3>
      <fd-flat-nodes [valueDataSource]="proposalHistorySerproNode"></fd-flat-nodes>
    </mat-tab>
    <mat-tab label="Log Error Cong. Serpro" *ngIf="showLogSerproError()" >
      <h3 class="proposal-detail-title">Log Erro Congelado Serpro</h3>
      <div *ngFor="let errorSerpro of proposal.serproErroLogDTO" >
          httpStatus: {{errorSerpro.httpStatusResponse}}
          <br/>
          <br/>
          request: {{errorSerpro.request}}
          <br/>
          <br/>
          response: {{errorSerpro.response}}
          <br/>
          <br/>
          ExceptionMessage: {{errorSerpro.exceptionMessage}}
          <br/>
          <br/>
          Data Hora: {{errorSerpro.createdDate}}
        <hr/>
      </div>
    </mat-tab>

    <mat-tab label="Log Integração (Gateway/BW)" *ngIf="proposal.proposalStatusId == 40">
      <div *ngIf="proposal.proposalStatusId == 40 && proposal.integrationStatus">
        <div class="proposal-detail">
          <h3 class="proposal-detail-title">Retorno da integração com o Gateway (SE)</h3>
            Código: <b>{{proposal.integrationStatus.messageSe ? proposal.integrationStatus.messageSe.responseCode : '-'}}</b><br>
            Mensagem: <b>{{proposal.integrationStatus.messageSe ? proposal.integrationStatus.messageSe.responseMessage : '-'}}</b><br>
            Status HTTP: <b>{{proposal.integrationStatus.messageSe ? proposal.integrationStatus.messageSe.httpStatus : '-'}}</b><br>
        </div>

        <div class="proposal-detail">
          <h3 class="proposal-detail-title">Retorno da integração com o BankWorks (BW)</h3>
            Erro: <b>{{proposal.integrationStatus.messageBw ? proposal.integrationStatus.messageBw.step : '-'}}</b><br>
            Status HTTP: <b>{{proposal.integrationStatus.messageBw ? proposal.integrationStatus.messageBw.httpStatus : '-'}}</b><br>
        </div>
        <hr/>
      </div>
      <div *ngIf="proposal.proposalStatusId == 40 && !proposal.integrationStatus">
        Não foi possível buscar o status da integração
      </div>

    </mat-tab>

    <mat-tab label="Base de produtos">
      <div *ngIf="proposal.proposalStatusId != 10">
        <div class="proposal-detail">
          -
        </div>
      </div>

      <div *ngIf="proposal.proposalStatusId === 10 && (proposal.baseProductStatus === successSendingProductsBase || proposal.baseProductStatus === notAllowedSendingProductsBase || proposal.baseProductStatus === allowedSendingProductsBase ) ">
        <div class="proposal-detail">
          <div *ngIf="proposal.baseProductStatus === successSendingProductsBase">
            <h3 class="proposal-detail-title">Sucesso ao integrar com a base de produtos contratados.</h3>
          </div>

          <div *ngIf="proposal.baseProductStatus === notAllowedSendingProductsBase">
            <h3 class="proposal-detail-title">Proposta não enviada para base de produtos contratados.</h3>
          </div>

          <div *ngIf="proposal.baseProductStatus === allowedSendingProductsBase">
            <h3 class="proposal-detail-title">Aguardando processamento.</h3>
            O processamento irá acontecer em alguns instantes
          </div>

        </div>
      </div>

      <div *ngIf="proposal.proposalStatusId === 10 && proposal.baseProductStatus === errorSendingProductsBase">
        <div class="proposal-detail">
          <div *ngIf="proposal.baseProductStatus === errorSendingProductsBase && proposal.integrationProductHistory">
            <h3 class="proposal-detail-title">Erro ao integrar com base de produtos contratados.</h3>
            Descrição: <b>{{proposal.integrationProductHistory.conteudo.error}}</b><br>
            Passo: <b>{{proposal.integrationProductHistory.conteudo.step}}</b><br>
            Data: <b>{{proposal.integrationProductHistory.conteudo.requestDate | date: 'dd/MM/yyyy'}}</b><br><br>
          </div>
          <div *ngIf="proposal.baseProductStatus === errorSendingProductsBase && !proposal.integrationProductHistory">
            <h3 class="proposal-detail-title">Erro ao integrar com base de produtos contratados.</h3>
            Não foi possivel buscar mais detalhes sobre o erro.
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Split de Pagamentos" *ngIf="proposal.existsPaymentSplit">
      <div class="proposal-detail">
        {{proposal.messagePaymentSplit}}
      </div>

    </mat-tab>
    <mat-tab label="Tabela Progressiva" *ngIf="proposal.economicGroupConvenienceId">
      <br>
      <h3 class="proposal-detail-title title-text-align">Tabela Progressiva de Desconto</h3>
      <br>
      <div *ngFor="let formGroup of formGroups; let i = index">
        <form [formGroup]="formGroup">
          <div
            fxLayout="row"
            fxLayoutGap="20px"
            fxLayoutAlign="center"
            class="row"
            [ngClass]="{ 'row--even': i % 2 != 0 }">
            <div>
              <fd-currency-input [parentForm]="formGroup" [field]="fields.initialRange"></fd-currency-input>
            </div>
            <div>
              <fd-currency-input [parentForm]="formGroup" [field]="fields.finalRange"></fd-currency-input>
            </div>
            <div fxFlex="20">
              <fd-input [parentForm]="formGroup" [field]="fields.discountPercentage"></fd-input>
            </div>
          </div>
        </form>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
