import { DatePipe, DOCUMENT } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { finalize } from 'rxjs/operators';
import { ApiStatusService } from '../api-status/services/api-status.service';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { DateFormats } from '../shared/enums/date-formats.enum';
import { MonitoringProposalTabsEnum } from '../shared/enums/monitoring-proposal-tabs.enum';
import { UserTypeEnum } from '../shared/enums/user-type.enum';
import { FdAlertComponent, ModalDefinitions } from '../shared/fd-form-components/fd-alert/fd-alert.component';
import { FdFieldConfig, FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { cpfCnpjMask } from '../shared/masks/document-masks';
import { NumberMask } from '../shared/masks/number-mask';
import { Messages } from '../shared/messages/messages';
import { ProposalModel } from '../shared/models/proposal.model';
import { SearchProposalModel } from '../shared/models/search-proposal.model';
import { AuthService } from '../shared/service/auth.service';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { ExportExcelService } from '../shared/service/export-excel.service';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { LoadingService } from '../shared/service/loading.service';
import { sanitizeSearchFilter } from '../shared/utils/sanitize-search-filter';
import { ProposalDetailComponent } from './components/proposal-detail/proposal-detail.component';
import { AccreditationChannelEnum } from './enums/accreditation-channel.enum';
import { ProposalCodeEnum } from './enums/proposal-code.enum';
import { ExceededSlaTimeModel } from './models/ExceededSlaTime.model';
import { ResubmitProposalModel } from './models/resubmit-proposal.model';
import { ProposalService } from './services/proposal.service';
import * as moment from 'moment';
import {ConfigAppService} from "../config-app/service/config-app.service";
import { DateCompareValidator } from '../shared/validators/date-compare-validator';
import { CompareTypeEnum } from '../shared/enums/compare-type.enum';

@Component({
  selector: 'app-search-proposal',
  templateUrl: './search-proposals.component.html',
  styleUrls: ['./search-proposals.component.scss']
})

export class SearchProposalsComponent implements OnInit {
  ALLOWED_MAX = 30;

  tabPendingCredit = false;
  tabAffiliated = false;
  tabSerproUnavailable = false;
  scheduledMaintenanceActive = false;
  tabAffiliatedNotEnable = false;
  tabBaseProductContracted = false;
  tabPendingNotEnable = false;
  tabPaymentSplitError = false;
  sizeItems = 50;
  totalPages = 0;
  pageNumber = 0;
  exportValid = false;
  ACTIONS_TAB_INDEX = 3;
  actionsTabActive = false;
  institutionList: Item[];
  serviceContractList: Item[];
  channelTypeList: Item[];
  channelList: Item[];
  subChannelList: Item[];
  agentChannelList: Item[];
  accreditationSourceList: Item[];
  accreditationChannelApiList: Item[];
  today = new Date();
  activeTab: MonitoringProposalTabsEnum = MonitoringProposalTabsEnum.PENDING_CREDIT.valueOf();
  sessionChannelCode;
  sessionSubChannelCode;
  sessionChannelTypeCode;
  isAccreditationSourceApi: boolean = false;
  private readonly INSTITUTION_PAY_PAL = "00000009";

  exceededSlaTime: ExceededSlaTimeModel = new ExceededSlaTimeModel();
  dataSource = new MatTableDataSource<ProposalModel>();
  dataExport = new Array<ProposalModel>();

  formGroup: FormGroup;
  fields: FdFieldConfigs;

  pageSelection = 1;

  filterAll: Item = { label: 'Todos', value: 'all' };

  checkAllAffiliatedNotEnabled = false;
  checkAllAffiliated = false;
  checkAllPendingCreditError = false;
  checkAllSerproError = false;
  checkAllBaseProductError = false;
  checkAllPendingNotEnable = false;
  checkAllPaymentSplitError = false;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren('childTabs') childTabs: QueryList<MatTabGroup>;

  constructor(
    private formBuilder: FormBuilder,
    private sharedService: HierarchyService,
    private apiStatusService: ApiStatusService,
    private service: ProposalService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private errorService: ErrorService,
    private authService: AuthService,
    private proposalService: ProposalService,
    private exportExcelService: ExportExcelService,
    private dialogService: DialogService,
    private hierarchyService: HierarchyService,
    private loadingService: LoadingService,
    private configAppService: ConfigAppService,
    @Inject(DOCUMENT) private document: any

  ) { }

  getRangeConfiguration() {
    this.configAppService.getConfigByDescription("RANGE_EXPORT_PROPOSALS")
      .subscribe(response => {
        this.ALLOWED_MAX = Number(response.value);
      }, (err: HttpErrorResponse) => {
        console.error("Failed get to number proposal to export. ", err);
        this.ALLOWED_MAX = 30;
      });
  }

  getScheduledMaintenanceFlag() {
    this.apiStatusService.getAllApiStatus().subscribe(items => {
      if(items && items.length){
        this.scheduledMaintenanceActive = items.some(x => x.offlineStatus && x.showMessage);
      }
    })
  }

  getProposalsExceededSla() {
    this.proposalService.proposalExceededSla().subscribe( (exceededSlaTime: ExceededSlaTimeModel) => {
      this.exceededSlaTime = exceededSlaTime;
    }, error => console.error(error));
  }

  setSupervisorHierarchy() {
    const userData = this.authService.getUserData();

    if (!userData) {
      return;
    }

    this.sessionChannelCode = userData.channelCode;
    this.sessionChannelTypeCode = userData.channelType;
    this.sessionSubChannelCode = userData.subChannelCode;
  }

  get hasSubChannel() {
    return !!this.sessionSubChannelCode;
  }

  get hasCredentialChannelPermission() {
    return this.authService.isUserInRoles([AdminRolesEnum.ACCREDITATION_CHANNELS]);
  }

  get institutionIsPayPal() {
    return this.formGroup.value.institutionNumber === this.INSTITUTION_PAY_PAL;
  }

  ngOnInit() {
    this.startForms();
    this.getAllProposalStatus();
    this.getScheduledMaintenanceFlag();
    this.getProposalsExceededSla();
    this.getRangeConfiguration();
    this.pageSelection = 1;

    if (this.isSupervisor) {
      this.setSupervisorHierarchy();

      if (!this.hasSubChannel) {
        this.formControls.subChannel.enable();
        this.loadSubChannels(null, this.sessionChannelCode, this.sessionChannelTypeCode);
      }
    }

    this.formGroup.controls.startDate.setValue(this.today);
    this.formGroup.controls.endDate.setValue(this.today);

    this.formControls.startDate.setValidators(
      [
        DateCompareValidator(this.formControls.endDate, CompareTypeEnum.LESS_THAN_OR_EQUAL),
      ]
    );

    this.formControls.endDate.setValidators(
      [
        DateCompareValidator(this.formControls.startDate, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
      ]
    );
  }

  clearCheckAll() {
    this.checkAllAffiliatedNotEnabled = false;
    this.checkAllAffiliated = false;
    this.checkAllPendingCreditError = false;
    this.checkAllSerproError = false;
    this.checkAllBaseProductError = false;
    this.checkAllPendingNotEnable = false;
    this.checkAllPaymentSplitError = false;
  }

  toggleSelectAll(event: MatCheckboxChange) {
    this.checkAllAffiliatedNotEnabled = false;
    this.checkAllAffiliated = false;
    this.checkAllPendingCreditError = true;
    this.checkAllSerproError = false;
    this.checkAllBaseProductError = false;
    this.checkAllPendingNotEnable = false;
    this.checkAllPaymentSplitError = false;


    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.filter(item => item.proposalStatusCode === ProposalCodeEnum.PENDING_CREDIT).forEach(item => item.selected = event.checked);
    }
  }

  toggleSelectAllBaseProductError(event: MatCheckboxChange) {
    this.checkAllAffiliatedNotEnabled = false;
    this.checkAllAffiliated = false;
    this.checkAllPendingCreditError = false;
    this.checkAllSerproError = false;
    this.checkAllBaseProductError = true;
    this.checkAllPendingNotEnable = false;
    this.checkAllPaymentSplitError = false;

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.filter(item => item.baseProductStatus === 2).forEach(item => item.selected = event.checked);
    }
  }

  toggleSelectAllPaymentSplitError(event: MatCheckboxChange) {
    this.checkAllAffiliatedNotEnabled = false;
    this.checkAllAffiliated = false;
    this.checkAllPendingCreditError = false;
    this.checkAllSerproError = false;
    this.checkAllBaseProductError = false;
    this.checkAllPendingNotEnable = false;
    this.checkAllPaymentSplitError = true;

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.filter(item => item.statusSplit === 2).forEach(item => item.selected = event.checked);
    }
  }

  toggleSelectAllAffiliated(event: MatCheckboxChange) {
    this.checkAllAffiliatedNotEnabled = false;
    this.checkAllAffiliated = true;
    this.checkAllPendingCreditError = false;
    this.checkAllSerproError = false;
    this.checkAllBaseProductError = false;
    this.checkAllPendingNotEnable = false;
    this.checkAllPaymentSplitError = false;

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.filter(item => item.proposalStatusCode === ProposalCodeEnum.AFFILIATED || item.proposalStatusCode === ProposalCodeEnum.PENDING_AFFILIATED)
        .forEach(item => item.selected = event.checked);
    }
  }

  toggleSelectAllAffiliatedNotEnable(event: MatCheckboxChange) {
    this.checkAllAffiliatedNotEnabled = true;
    this.checkAllAffiliated = false;
    this.checkAllPendingCreditError = false;
    this.checkAllSerproError = false;
    this.checkAllBaseProductError = false;
    this.checkAllPendingNotEnable = false;
    this.checkAllPaymentSplitError = false;

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.filter(item => item.proposalStatusCode === ProposalCodeEnum.AFFILIATED_NOT_ENABLED)
        .forEach(item => item.selected = event.checked);
    }
  }

  toggleSelectAllSerpro(event: MatCheckboxChange) {
    this.checkAllAffiliatedNotEnabled = false;
    this.checkAllAffiliated = false;
    this.checkAllPendingCreditError = false;
    this.checkAllSerproError = true;
    this.checkAllBaseProductError = false;
    this.checkAllPendingNotEnable = false;
    this.checkAllPaymentSplitError = false;

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.filter(item => item.proposalStatusCode === ProposalCodeEnum.SERPRO_UNAVAILABLE).forEach(item => item.selected = event.checked);
    }
  }

  toggleSelectAllPendingNotenable(event: MatCheckboxChange) {
    this.checkAllAffiliatedNotEnabled = false;
    this.checkAllAffiliated = false;
    this.checkAllPendingCreditError = false;
    this.checkAllSerproError = false;
    this.checkAllBaseProductError = false;
    this.checkAllPendingNotEnable = true;
    this.checkAllPaymentSplitError = false;

    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.filter(item => item.proposalStatusCode === ProposalCodeEnum.AFFILIATED_PENDING_ENABLED).forEach(item => item.selected = event.checked);
    }
  }

  get hasSelectedProposals() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.filter(item => item.selected).length;
  }

  get hasSerproUnavailable() {
    if (!this.dataSource || !this.dataSource.data) {
      return false;
    }
    const pendingFiltered = this.dataSource.data.filter(item => this.isSerproUnavailable(item));

    return pendingFiltered && pendingFiltered.length > 0;
  }

  get hasPendingNotenable() {
    if (!this.dataSource || !this.dataSource.data) {
      return false;
    }
    const pendingFiltered = this.dataSource.data.filter(item => this.isPendingNotenable(item));

    return pendingFiltered && pendingFiltered.length > 0;
  }

  get hasPendingCreditProposals() {
    if (!this.dataSource || !this.dataSource.data) {
      return false;
    }
    const pendingFiltered = this.dataSource.data.filter(item => this.isPendingCredit(item));

    return pendingFiltered && pendingFiltered.length > 0;
  }

  get hasAffiliatedProposals() {
    if (!this.dataSource || !this.dataSource.data) {
      return false;
    }
    const pendingFiltered = this.dataSource.data.filter(item => this.isAffiliated(item));

    return pendingFiltered && pendingFiltered.length > 0;
  }

  isSerproUnavailable(proposal: ProposalModel) {
    return proposal.proposalStatusCode === ProposalCodeEnum.SERPRO_UNAVAILABLE;
  }

  isPendingNotenable(proposal: ProposalModel) {
    return proposal.proposalStatusCode === ProposalCodeEnum.AFFILIATED_PENDING_ENABLED;
  }

  isPendingCredit(proposal: ProposalModel) {
    return proposal.proposalStatusCode === ProposalCodeEnum.PENDING_CREDIT;
  }

  isAffiliated(proposal: ProposalModel) {
    return proposal.proposalStatusCode === ProposalCodeEnum.AFFILIATED;
  }

  get hasAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.ALTER_PROPOSAL_STATUS]);
  }

  get hasAccessChannels() {
    return this.authService.isUserInRoles([AdminRolesEnum.ACCREDITATION_CHANNELS]);
  }

  resubmitProposaslsErrorBaseProduct(proposalList: ProposalModel[]) {
    this.loadingService.show();
    const markedProposals: ResubmitProposalModel = {
      proposalNumberList: proposalList.map(item => item.proposalNumber)
    }

    if (markedProposals.proposalNumberList && markedProposals.proposalNumberList.length) {
      this.proposalService.resubmitProposalsErrorBaseProduct(markedProposals)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(response => {
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.searchAsBaseProductError());
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
    }
  }

  resubmitProposalsErrorPaymentSplit(proposalList: ProposalModel[]) {
    this.loadingService.show();
    const markedProposals: ResubmitProposalModel = {
      proposalNumberList: proposalList.map(item => item.proposalNumber)
    }

    if (markedProposals.proposalNumberList && markedProposals.proposalNumberList.length) {
      this.proposalService.resubmitProposalsErrorPaymentSplit(markedProposals)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(response => {
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.searchAsPaymentSplitError());
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
    }
  }

  resubmitProposaslsNotAffiliated(proposalList: ProposalModel[]) {
    this.loadingService.show();
    const markedProposals: string[] = proposalList.map(item => item.proposalNumber);

    if (markedProposals && markedProposals.length) {
      this.proposalService.resubmitAffiliatedNotEnable(markedProposals)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(response => {
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.searchAsAffiliatedNotEnable());
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
    }
  }

  resubmitProposaslsAffiliated(proposalList: ProposalModel[]) {
    this.loadingService.show();
    const markedProposals: string[] = proposalList.map(item => item.proposalNumber);

    if (markedProposals && markedProposals.length) {
      this.proposalService.resubmitAffiliatedProposal(markedProposals)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(response => {
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.searchAsAffiliated());
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));
    }
  }


  resubmitCreditProposals(proposalList: ProposalModel[]) {
    this.loadingService.show();
    const markedProposals: ResubmitProposalModel = {
      proposalNumberList: proposalList.map(item => item.proposalNumber)
    }


    if (markedProposals.proposalNumberList && markedProposals.proposalNumberList.length) {
      this.proposalService.resubmitCreditProposals(markedProposals)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(response => {
          if (!response) {
            this.dialogService.openDialog(Messages.EDIT_SAVE_ERROR);
            return;
          }
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.searchAsPendingCredit());
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR))
    }
  }

  resubmitProposal(proposalNumber: string) {
      console.log('aqui');
  }

  resubmitSelectedProposalsSerpro() {
    if (this.dataSource && this.dataSource.data) {

      const selectedProposals = this.dataSource.data.filter(item => item.selected);

      const creditProposals = selectedProposals.filter(item => item.proposalStatusCode === ProposalCodeEnum.SERPRO_UNAVAILABLE);
      let ids: string[] = [];
      if (creditProposals && creditProposals.length) {

        for(let item of creditProposals)
        {
          ids.push(item.proposalNumber);
        }
      }
      if(ids.length>0)
      {
        this.proposalService.resubmitSerproProposals(ids).toPromise()
        .then(response=>
        {
          this.searchAsSerproUnavailable();
        }).catch(error=>
        {
          console.log(error);
        });
      }

    }
  }

  resubmitSerproProposals(proposalList: ProposalModel[]) {
    this.loadingService.show();
    const markedProposals: ResubmitProposalModel = {
      proposalNumberList: proposalList.map(item => item.proposalNumber)
    }


    if (markedProposals.proposalNumberList && markedProposals.proposalNumberList.length) {
      this.proposalService.resubmitCreditProposals(markedProposals)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(response => {
          if (!response) {
            this.dialogService.openDialog(Messages.EDIT_SAVE_ERROR);
            return;
          }
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.searchAsPendingCredit());
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR))
    }
  }

  resubmitPendingNotenable(proposalList: ProposalModel[]){
    this.loadingService.show();
    const markedProposals: ResubmitProposalModel = {
      proposalNumberList: proposalList.map(item => item.proposalNumber)
    }


    if (markedProposals.proposalNumberList && markedProposals.proposalNumberList.length) {
      this.proposalService.resubmitPendingNotenable(markedProposals)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(response => {
          if (!response) {
            this.dialogService.openDialog(Messages.EDIT_SAVE_ERROR);
            return;
          }
          this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.searchAsPendingdNotEnable());
        }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR))
    }
  }

  resubmitSelectedPendingNotenable() {
    if (this.dataSource && this.dataSource.data) {

      const selectedProposals = this.dataSource.data.filter(item => item.selected);

      const affiliatedPedingEnableProposals = selectedProposals.filter(item => item.proposalStatusCode === ProposalCodeEnum.AFFILIATED_PENDING_ENABLED);

      if (affiliatedPedingEnableProposals && affiliatedPedingEnableProposals.length) {
        this.resubmitPendingNotenable(affiliatedPedingEnableProposals);
      }

    }
  }

  resubmitSelectedProposals() {
    if (this.dataSource && this.dataSource.data) {

      const selectedProposals = this.dataSource.data.filter(item => item.selected);

      const creditProposals = selectedProposals.filter(item => item.proposalStatusCode === ProposalCodeEnum.PENDING_CREDIT);

      if (creditProposals && creditProposals.length) {
        this.resubmitCreditProposals(creditProposals);
      }

    }
  }

  resubmitSelectedProposalsErrorBaseProducts() {
    if (this.dataSource && this.dataSource.data) {

      const selectedProposals = this.dataSource.data.filter(item => item.selected);

      const errorBaseProductProposasls = selectedProposals.filter(item => item.baseProductStatus === 2);

      if (errorBaseProductProposasls && errorBaseProductProposasls.length) {
        this.resubmitProposaslsErrorBaseProduct(errorBaseProductProposasls);
      }

    }
  }

  resubmitSelectedProposalsPaymentSplitError() {
    if (this.dataSource && this.dataSource.data) {

      const selectedProposals = this.dataSource.data.filter(item => item.selected);

      const errorPaymentSplitProposals = selectedProposals.filter(item => item.statusSplit === 2);

      if (errorPaymentSplitProposals && errorPaymentSplitProposals.length) {
        this.resubmitProposalsErrorPaymentSplit(errorPaymentSplitProposals);
      }

    }
  }

  resubmitSelectedProposalsAffiliated() {
    if (this.dataSource && this.dataSource.data) {

      const selectedProposals = this.dataSource.data.filter(item => item.selected);

      const errorAffiliated = selectedProposals.filter(item => item.proposalStatusCode === ProposalCodeEnum.AFFILIATED || item.proposalStatusCode === ProposalCodeEnum.PENDING_AFFILIATED);

      if (errorAffiliated && errorAffiliated.length) {
        this.resubmitProposaslsAffiliated(errorAffiliated);
      }

    }
  }

  resubmitSelectedProposalsNotAffiliated() {
    if (this.dataSource && this.dataSource.data) {

      const selectedProposals = this.dataSource.data.filter(item => item.selected);
      const notEnableProposals = selectedProposals
        .filter(item => item.proposalStatusCode === ProposalCodeEnum.AFFILIATED_NOT_ENABLED);

      if (notEnableProposals && notEnableProposals.length) {
        this.resubmitProposaslsNotAffiliated(notEnableProposals);
      }

    }
  }

  get hasProposals() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  canActivateActionsTab() {
    if (!this.hasAccess) {
      this.dataSource = new MatTableDataSource<ProposalModel>();
      this.dialogService.openDialog(Messages.ACCESS_PERMISSION_DENIED);
      return false;
    }
    return true;
  }

  enableActionsTab() {
    this.pageSelection = 4;
    if (this.canActivateActionsTab()) {
      this.actionsTabActive = true;
      this.dataSource = new MatTableDataSource<ProposalModel>();
      if(this.tabPendingCredit){
        this.searchAsPendingCredit();
      }else if(this.tabAffiliated){
        this.searchAsAffiliated();
      }else if(this.tabSerproUnavailable){
        this.searchAsSerproUnavailable();
      } else if (this.tabAffiliatedNotEnable) {
        this.searchAsAffiliatedNotEnable();
      }else if (this.tabPendingNotEnable){
        this.searchAsPendingdNotEnable();
      }else if(this.tabBaseProductContracted) {
        this.searchAsBaseProductError();
      }else if(this.tabPaymentSplitError) {
        this.searchAsPaymentSplitError();
      } else {
        this.searchAsPendingCredit();
      }
    }
    this.childTabs.forEach(childTab => {
      childTab.realignInkBar();
   });
  }

  enableSearchTab() {
    this.actionsTabActive = false;
    this.searchProposals();
  }

  searchAsPendingCredit() {
    this.dataSource = new MatTableDataSource<ProposalModel>();
    this.activeTab = MonitoringProposalTabsEnum.PENDING_CREDIT;
    this.pageNumber = 0;
    this.tabPendingCredit = true;
    this.tabAffiliated = false;
    this.tabSerproUnavailable = false;
    this.tabAffiliatedNotEnable = false;
    this.tabBaseProductContracted = false;
    this.tabPendingNotEnable = false;
    this.tabPaymentSplitError = false;
    this.findProposals(ProposalCodeEnum.PENDING_CREDIT);
  }

  searchAsAffiliated() {
    this.dataSource = new MatTableDataSource<ProposalModel>();
    this.activeTab = MonitoringProposalTabsEnum.AFFILIATED;
    this.pageNumber = 0;
    const affiliatedCode = ['10', '43'];
    this.tabPendingCredit = false;
    this.tabAffiliated = true;
    this.tabSerproUnavailable = false;
    this.tabAffiliatedNotEnable = false;
    this.tabBaseProductContracted = false;
    this.tabPendingNotEnable = false;
    this.tabPaymentSplitError = false;
    this.findProposals(affiliatedCode.toString(), false, true);
  }

  searchAsSerproUnavailable() {
    this.dataSource = new MatTableDataSource<ProposalModel>();
    this.activeTab = MonitoringProposalTabsEnum.SERPRO;
    this.pageNumber = 0;
    const serproUnavailable = '38';
    this.tabPendingCredit = false;
    this.tabAffiliated = false;
    this.tabSerproUnavailable = true;
    this.tabAffiliatedNotEnable = false;
    this.tabBaseProductContracted = false;
    this.tabPendingNotEnable = false;
    this.tabPaymentSplitError = false;
    this.findProposals(serproUnavailable);
  }

  searchAsAffiliatedNotEnable() {
    this.dataSource = new MatTableDataSource<ProposalModel>();
    this.activeTab = MonitoringProposalTabsEnum.NOT_AFFILIATED;
    this.pageNumber = 0;
    const notAffiliated = '51';
    this.tabPendingCredit = false;
    this.tabAffiliated = false;
    this.tabSerproUnavailable = false;
    this.tabAffiliatedNotEnable = true;
    this.tabBaseProductContracted = false;
    this.tabPendingNotEnable = false;
    this.tabPaymentSplitError = false;
    this.findProposals(notAffiliated);
  }


  searchAsPendingdNotEnable() {
    this.dataSource = new MatTableDataSource<ProposalModel>();
    this.activeTab = MonitoringProposalTabsEnum.PENDING_NOTENABLE;
    this.pageNumber = 0;
    const pendingNotEnable = '50';
    this.tabPendingCredit = false;
    this.tabAffiliated = false;
    this.tabSerproUnavailable = false;
    this.tabAffiliatedNotEnable = false;
    this.tabBaseProductContracted = false;
    this.tabPendingNotEnable = true;
    this.tabPaymentSplitError = false;
    this.findProposals(pendingNotEnable);
  }

  searchByStatus(status: string, pageNumber: number, baseProductError: boolean) {
    this.pageNumber = pageNumber;
    this.findProposals(status, baseProductError);
  }

  searchAsBaseProductError() {
    this.dataSource = new MatTableDataSource<ProposalModel>();
    this.activeTab = MonitoringProposalTabsEnum.PRODUCT_BASE;
    this.pageNumber = 0;
    const affiliatedCode = '10';
    this.tabPendingCredit = false;
    this.tabAffiliated = false;
    this.tabSerproUnavailable = false;
    this.tabAffiliatedNotEnable = false;
    this.tabBaseProductContracted = true;
    this.tabPendingNotEnable = false;
    this.tabPaymentSplitError = false;
    this.findProposals(affiliatedCode, true);
  }

  searchAsPaymentSplitError() {
    this.dataSource = new MatTableDataSource<ProposalModel>();
    this.activeTab = MonitoringProposalTabsEnum.PAYMENT_SPLIT;
    this.pageNumber = 0;
    this.tabPendingCredit = false;
    this.tabAffiliated = false;
    this.tabSerproUnavailable = false;
    this.tabAffiliatedNotEnable = false;
    this.tabBaseProductContracted = false;
    this.tabPendingNotEnable = false;
    this.tabPaymentSplitError = true;
    this.findProposals(undefined, undefined, undefined, true);
  }


  onselectedTabChange(event: MatTabChangeEvent) {

    this.dataExport = new Array<ProposalModel>();

    this.exportValid = false;
    this.actionsTabActive = event.index === this.ACTIONS_TAB_INDEX;
    this.sizeItems = 50;
    this.totalPages = 0;
    this.pageNumber = 0;
  }

  // EXPORT

  exportProposals(): void {
    if (!this.formGroup.valid) {
      return;
    }
    this.exportValid = false;

    const filter = new SearchProposalModel();

    let startDate = moment(this.formGroup.value.startDate);
    let endDate = moment(this.formGroup.value.endDate);
    let days = endDate.diff(startDate, 'days');

    if(days >= this.ALLOWED_MAX) {
      this.loadingService.hide();
      let message: Messages = Messages.RANGE_GREATER_THAN_CONFIGURED;
      message.description = message.description.replace("@dias@", this.ALLOWED_MAX.toString());
      this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: message
      });
      return;
    }

    filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
    filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();

    filter.institution = this.formGroup.value.institutionNumber;
    if(this.isSupervisor){
      filter.serviceContract = this.authService.getUserData().serviceContracts[0].toString();
    }else{
      filter.serviceContract = this.formGroup.value.serviceContract;
    }

    filter.channelCode = this.formGroup.value.channel;
    filter.subChannelCode = this.formGroup.value.subChannel;
    filter.channelType = this.formGroup.value.channelType;
    filter.agentId = this.formGroup.value.agentChannel;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.status = status || this.formGroup.value.proposalsState;
    filter.export = true;

    //TODO: Adicionar no endpoint de export o default = 2 caso o campo nao seja enviado.
    if(this.hasAccessChannels) {
      filter.accreditationSource = this.formGroup.value.accreditationSource;
    } else {
      filter.accreditationSource = '2';
    }

    filter.accreditationChannelApi = this.formGroup.value.accreditationChannelApi;

    if (!filter.startDate || !filter.endDate) {
      this.loadingService.hide();
      const dialogRef = this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: Messages.DATA_REQUIRED_SEARCH_ERROR
      });
      return;
    }

    if(!this.isSupervisor){
      if (!filter.serviceContract || !filter.institution || filter.serviceContract == 'all' || filter.serviceContract == 'all') {
        this.loadingService.hide();
        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.DATA_REQUIRED_EXPORT_ERROR
        });
        return;
      }
    }

    this.service.searchExportProposalsFilter(
      filter
    )
      .subscribe(item => {
        this.dataExport = item;
        this.exportHandler();
        this.renameHeader("ableAnticipation", "Antecipação Habilitada?");
        this.renameHeader("diligenceResponseDate", "StoneAgeResponseDate");
        this.renameHeader("quantityCaptureSolution", "qtd de Terminais")
        this.removeProperties(this.dataExport, "id", "accreditationChannel");
        this.exportExcelService.exportAsExcelFile(
          this.dataExport,
          'proposals-backoffice' + '_' +
          this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy') + '_' +
          this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy')
        );
        if (!this.institutionIsPayPal) {
          this.exportValid = true;
        }

      }, error => {

        if (!this.institutionIsPayPal) {
          this.exportValid = true;
        }

        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EXPORT_ERROR
        });
      });

  }

  removeProperties(proposal: ProposalModel[], ...keysToDelete: string[]) {
    proposal.forEach(p =>
        keysToDelete.forEach(k => {
          delete p[k];
        })
      );
  }

  exportHandler()
  {
    this.dataExport.forEach(e=> {
      if(e!=null || e.activations!=null)
      {
        delete e.activations
      }
    });
  }

  renameHeader(currentHeader: string, newHeader: string) {
    let dataToExport = new Array<ProposalModel>();
    this.dataExport.forEach(e => {
      dataToExport.push(JSON.parse(JSON.stringify(e).replace(currentHeader, newHeader)));
    })
    this.dataExport = dataToExport;
  }

  showLogSerproError():boolean
  {
    let userRole = AdminRolesEnum.SERPRO_LOCK_EVALUATOR;
    let roles: AdminRolesEnum[] = [];
    roles.push(userRole);
    return this.authService.isUserInRoles(roles);
  }

  joinActivationData(item: any[]) {
    item.forEach(
      (proposal) => {
        proposal.activations.forEach((activation) => {
          if (!proposal.logicNumber && !proposal.rnid) {
            proposal.logicNumber = activation.logicNumber;
            proposal.rnid = activation.os;
          } else {
            proposal.logicNumber += '; ' + activation.logicNumber;
            proposal.rnid += '; ' + activation.os;
          }
        });
        delete proposal.activations;
      });
  }

  searchProposals() {
    if (!this.formGroup.valid) {
      return;
    }
    this.dataSource = new MatTableDataSource<ProposalModel>();
    this.pageNumber = 0;
    this.findProposals();
  }

  findProposals(status?: string, baseProductContracted?: boolean, filterCheckBw?: boolean, splitPaymentFilter?: boolean): void {
    this.clearCheckAll();
    this.loadingService.show();

    const filter = new SearchProposalModel();

    filter.startDate = this.datePipe.transform(this.formGroup.value.startDate, 'dd-MM-yyyy');
    filter.endDate = this.datePipe.transform(this.formGroup.value.endDate, 'dd-MM-yyyy');
    filter.page = this.pageNumber.toString();
    filter.size = this.sizeItems.toString();
    filter.institution = this.formGroup.value.institutionNumber;
    filter.serviceContract = this.formGroup.value.serviceContract;
    filter.channelCode = this.formGroup.value.channel;
    filter.subChannelCode = this.formGroup.value.subChannel;
    filter.channelType = this.formGroup.value.channelType;
    filter.agentId = this.formGroup.value.agentChannel;
    filter.cpfCnpj = this.formGroup.value.cpfCnpj;
    filter.status = status || this.formGroup.value.proposalsState;
    filter.accreditationSource = this.formGroup.value.accreditationSource;
    filter.accreditationChannelApi = this.formGroup.value.accreditationChannelApi;
    filter.hasCredentialChannelPermission = this.hasCredentialChannelPermission;
    filter.baseProductContracted = baseProductContracted || false;
    filter.filterPaymentSplit = splitPaymentFilter || false;
    filter.filterCheckBw = filterCheckBw || false;

    if (!filter.startDate || !filter.endDate) {
      this.loadingService.hide();
      const dialogRef = this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: Messages.DATA_REQUIRED_SEARCH_ERROR
      });
      return;
    }

    this.service.searchPageableProposals(filter)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.exportValid = false;
        this.dataSource = new MatTableDataSource(item.response);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.totalPages = item.totalItens;
        this.pageNumber = item.page;
        if (item.response.length > 0 && !this.institutionIsPayPal) {
          this.exportValid = true;
        }
      }, error => {

        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
      });
  }

  findByProposalNumber(): void {
    this.loadingService.show();

    if (!this.formGroup.value.proposalNumber) {
      this.loadingService.hide();
      const dialogRef = this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: Messages.DATA_REQUIRED_SEARCH_ERROR
      });
      return;
    }


    this.pageSelection = 2;
    this.service.searchByProposalNumber(this.formGroup.value.proposalNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource = new MatTableDataSource(item);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

      }, error => {

        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
        console.log(error);
      });
  }

  findByMerchantId(): void {

    this.loadingService.show();

    if (!this.formGroup.value.merchantId) {
      this.loadingService.hide();
      const dialogRef = this.dialog.open(FdAlertComponent, {
        disableClose: true,
        width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
        data: Messages.DATA_REQUIRED_SEARCH_ERROR
      });
      return;
    }

    this.pageSelection = 3;
    this.service.searchByMerchantId(this.formGroup.value.merchantId)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(item => {
        this.dataSource = new MatTableDataSource(item);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }, error => {

        const dialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.SEARCH_ERROR
        });
        console.log(error);
      });
  }

  changePage(event: PageEvent): void {
    this.sizeItems = event.pageSize;
    this.pageNumber = event.pageIndex;

    switch (this.pageSelection) {
      case 1: {
        this.findProposals();
        break;
      }
      case 2: {
        this.findByProposalNumber();
        break;
      }
      case 3: {
        this.findByMerchantId();
        break;
      }
      case 4: {
        if (this.tabAffiliatedNotEnable) {
          this.searchByStatus(ProposalCodeEnum.AFFILIATED_NOT_ENABLED, this.pageNumber, false);
        }
        if (this.tabAffiliated) {
          this.searchByStatus(['10', '43'].toString(), this.pageNumber, false);
        }
        if (this.tabPendingCredit) {
          this.searchByStatus(ProposalCodeEnum.PENDING_CREDIT, this.pageNumber, false);
        }
        if (this.tabSerproUnavailable) {
          this.searchByStatus(ProposalCodeEnum.SERPRO_UNAVAILABLE, this.pageNumber, false);
        }
        if (this.tabBaseProductContracted) {
          this.searchByStatus('10', this.pageNumber, true);
        }
        if(this.tabPendingNotEnable){
          this.searchByStatus(ProposalCodeEnum.AFFILIATED_PENDING_ENABLED, this.pageNumber, false);
        }
        break;
      }
      default: {
        this.findProposals();
        break;
      }
    }


  }

  filterDropdowns(filterValue: string, listParam: Item[], field: FdFieldConfig) {
    const list = listParam.filter(x => sanitizeSearchFilter(x.value.toString().trim()).indexOf(filterValue) > -1 || sanitizeSearchFilter(x.label.trim().toLowerCase()).indexOf(filterValue) > -1);
    (field as FdSelectConfig).items = list;
  }

  clearFilters() {
    this.formGroup.controls.startDate.setValue(this.today);
    this.formGroup.controls.endDate.setValue(this.today);
    this.formGroup.controls.institutionNumber.setValue('all');
    this.formGroup.controls.proposalsState.setValue('all');

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');

    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channelType.setValue('');

    this.formGroup.controls.channel.disable();
    this.formGroup.controls.channel.setValue('');

    if (this.isSupervisor) {
      if (this.hasSubChannel) {
        this.formGroup.controls.subChannel.disable();
      }
      this.formGroup.controls.subChannel.setValue('');

      this.formGroup.controls.agentChannel.disable();
      this.formGroup.controls.agentChannel.setValue('');
    }
    else {
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');

      this.formGroup.controls.agentChannel.disable();
      this.formGroup.controls.agentChannel.setValue('');
    }

    this.formGroup.controls.cpfCnpj.setValue('');

  }

  selectedInstitution(): void {
    this.formGroup.controls.serviceContract.setValue(null);
    this.loadServiceContracts(null, this.formGroup.value.institutionNumber);
    if (this.institutionIsPayPal) {
      this.exportValid = false;
    }
  }

  loadInstitutions(filterValue: string): void {

    if (filterValue) {
      this.filterDropdowns(filterValue, this.institutionList, this.fields.institutionNumber);
      return;
    }


    this.loadingService.show();

    this.formGroup.controls.institutionNumber.setValue(this.filterAll.value);

    (this.fields.serviceContract as FdSelectConfig).items = [this.filterAll];
    (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
    (this.fields.channel as FdSelectConfig).items = [this.filterAll];
    (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channel.disable();
    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agentChannel.disable();


    this.formGroup.controls.serviceContract.setValue('');
    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agentChannel.setValue('');


    this.hierarchyService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          this.institutionList = list;
          (this.fields.institutionNumber as FdSelectConfig).items = [this.filterAll];
          (this.fields.institutionNumber as FdSelectConfig).items.push(...list);
        }
      }, (error: HttpErrorResponse) => {
        console.log(error);
      });
  }

  getAllProposalStatus(): void {
    this.service.searchAllProposalStatusAndGetId()
      .subscribe(list => {
        if (list != null) {
          (this.fields.proposalsState as FdSelectConfig).items = [this.filterAll];
          (this.fields.proposalsState as FdSelectConfig).items.push(...list);
        }
      });
    this.formGroup.controls.proposalsState.setValue(this.filterAll.value);
  }

  loadServiceContracts(filterValue: string, institutionNumber: string): void {

    if (filterValue) {
      this.filterDropdowns(filterValue, this.serviceContractList, this.fields.serviceContract);
      return;
    }

    this.formGroup.controls.serviceContract.setValue('');

    (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
    (this.fields.channel as FdSelectConfig).items = [this.filterAll];
    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];
    (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];

    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agentChannel.disable();
    this.formGroup.controls.channelType.disable();
    this.formGroup.controls.channel.disable();

    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agentChannel.setValue('');

    this.formGroup.controls.serviceContract.setValue(this.filterAll.value);

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    if (institutionNumber !== 'all') {
      this.loadingService.show();
      this.hierarchyService.serviceContractByInstitution(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.serviceContractList = data;
            (this.fields.serviceContract as FdSelectConfig).items = [this.filterAll];
            (this.fields.serviceContract as FdSelectConfig).items.push(...data);
            this.formGroup.controls.serviceContract.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.serviceContract.disable();
          this.formGroup.controls.serviceContract.setValue('');

          // if (error.status === 404) {
          //     this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          //     return;
          // }
          // this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.serviceContract.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agentChannel.disable();
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.disable();

      this.formGroup.controls.serviceContract.setValue('');
      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agentChannel.setValue('');
    }
  }


  // FORMS
  startForms(): void {

    this.formGroup = this.formBuilder.group({
      startDate: [''],
      endDate: [''],
      institutionNumber: [''],
      serviceContract: [''],
      channelType: [''],
      channel: [''],
      subChannel: [''],
      agentChannel: [''],
      cpfCnpj: [''],
      proposalsState: [''],
      proposalNumber: [''],
      merchantId: [''],
      accreditationSource: [''],
      accreditationChannelApi: [''],
    });

    this.fields = {
      startDate: {
        label: 'Data Inicial',
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        controlName: 'startDate',
        messages: {
          invalid: 'Data inválida',
          lessThanOrEqual: 'A data inicial deve ser menor ou igual a data final'
        }
      },
      endDate: {
        label: 'Data Final',
        valueFormat: DateFormats.YEAR_MONTH_DAY_WITH_HYPHEN,
        controlName: 'endDate',
        messages: {
          invalid: 'Data inválida',
          greaterThanOrEqual: 'A data final deve ser maior ou igual a data inicial'
        }
      },
      institutionNumber: {
        label: 'Nº da Instituição',
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'institutionNumber'
      },
      serviceContract: {
        label: 'Service Contract',
        items: [this.filterAll],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'serviceContract'
      },
      channel: {
        label: 'Canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'channel',
        messages: {
          required: 'Informe um canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'subChannel',
        messages: {
          required: 'Informe um sub canal'
        }
      },
      channelType: {
        label: 'Tipo de canal',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'channelType',
        messages: {
          required: 'Informe um tipo de canal'
        }
      },
      agentChannel: {
        label: 'Agente',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'agentChannel',
        messages: {
          required: 'Informe um Agente'
        }
      },
      cpfCnpj: {
        label: 'CPF/CNPJ do cliente',
        mask: cpfCnpjMask,
        controlName: 'cpfCnpj'
      },
      proposalsState: {
        label: 'Status da Proposta',
        items: [],
        controlName: 'proposalsState'
      },
      proposalNumber: {
        label: 'Nº da proposta',
        controlName: 'proposalNumber'
      },
      merchantId: {
        label: 'Merchant Id',
        controlName: 'merchantId',
        mask: NumberMask
      },
      accreditationSource: {
        label: 'Canal Credenciamento',
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'accreditationSource',
      },
      accreditationChannelApi: {
        label: 'Sub Canal Credenciamento',
        items: [],
        searchable: true,
        searchPlaceholder: "Digite algo",
        controlName: 'accreditationChannelApi',
      },
    };
    this.formGroup.controls.serviceContract.setValue(this.filterAll.value);
  }

  loadChannelTypes(filterValue: string, institutionNumber: string): void {

    if (filterValue) {
      this.filterDropdowns(filterValue, this.channelTypeList, this.fields.channelType);
      return;
    }

    (this.fields.channel as FdSelectConfig).items = [this.filterAll];
    (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];

    this.formGroup.controls.channel.disable();
    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agentChannel.disable();

    this.formGroup.controls.channelType.setValue('');
    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agentChannel.setValue('');

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    if (institutionNumber !== 'all' && this.formGroup.value.serviceContract !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channelType(institutionNumber)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelTypeList = data;
            (this.fields.channelType as FdSelectConfig).items = [this.filterAll];
            (this.fields.channelType as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channelType.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channelType.disable();
          this.formGroup.controls.channelType.setValue('');
          // if (error.status === 404) {
          //     this.dialogService.openDialog(Messages.CHANNEL_TYPE_NOT_FOUND);
          //     return;
          // }
          // this.dialogService.openDialog(Messages.CHANNEL_TYPE_LOAD_ERROR);
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agentChannel.disable();

      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agentChannel.setValue('');
    }
  }

  loadChannels(filterValue: string, channelType: string): void {

    if (!channelType) {
      return;
    }

    if (filterValue) {
      this.filterDropdowns(filterValue, this.channelList, this.fields.channel);
      return;
    }

    (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];

    this.formGroup.controls.subChannel.disable();
    this.formGroup.controls.agentChannel.disable();

    this.formGroup.controls.channel.setValue('');
    this.formGroup.controls.subChannel.setValue('');
    this.formGroup.controls.agentChannel.setValue('');

    if (!channelType) {
      channelType = this.formGroup.value.channelType;
    }
    if (channelType !== 'all') {
      this.loadingService.show();
      this.hierarchyService.channel(channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.channelList = data;
            (this.fields.channel as FdSelectConfig).items = [this.filterAll];
            (this.fields.channel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.channel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          // if (error.status === 404) {
          //     this.dialogService.openDialog(Messages.CHANNEL_NOT_FOUND);
          //     return;
          // }
          // this.dialogService.openDialog(Messages.CHANNEL_LOAD_ERROR);
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agentChannel.disable();

      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agentChannel.setValue('');
    }
  }

  loadSubChannels(filterValue: string, channel: string, channelType: string): void {

    if (!channelType || !channel) {
      return;
    }

    if (filterValue) {
      this.filterDropdowns(filterValue, this.subChannelList, this.fields.subChannel);
      return;
    }

    (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];
    this.formGroup.controls.agentChannel.disable();
    this.formGroup.controls.agentChannel.setValue('');

    if (!channel) {
      channel = this.formGroup.value.channel;
    }
    if (!channelType) {
      channelType = this.formGroup.value.channelType;
    }
    if (channel !== 'all') {
      this.loadingService.show();
      this.hierarchyService.subChannel(channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.subChannelList = data;
            (this.fields.subChannel as FdSelectConfig).items = [this.filterAll];
            (this.fields.subChannel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.subChannel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          // if (error.status === 404) {
          //     this.dialogService.openDialog(Messages.SUB_CHANNEL_NOT_FOUND);
          //     return;
          // }

          // this.dialogService.openDialog(Messages.SUB_CHANNEL_LOAD_ERROR);

          console.log(error);
        });
    }
    else {
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agentChannel.disable();
      this.formGroup.controls.agentChannel.setValue('');
    }
  }

  loadAgentChannels(filterValue: string, subChannel: string, channel: string, channelType: string): void {

    if (filterValue) {
      this.filterDropdowns(filterValue, this.agentChannelList, this.fields.agentChannel);
      return;
    }

    if (this.isSupervisor) {
      channel = this.formGroup.value.channel ? this.formGroup.value.channel : this.sessionChannelCode;
      channelType = this.formGroup.value.channelType ? this.formGroup.value.channelType : this.sessionChannelTypeCode;
      subChannel = this.formGroup.value.subChannel ? this.formGroup.value.subChannel : this.sessionSubChannelCode;
    }

    if (!channelType || !channel || !subChannel) {
      return;
    }

    else {
      if (!subChannel) {
        subChannel = this.formGroup.value.subChannel
      }
      if (!channel) {
        channel = this.formGroup.value.channel;
      }
      if (!channelType) {
        channelType = this.formGroup.value.channelType;
      }
    }

    this.formGroup.controls.agentChannel.setValue('');

    if (subChannel !== 'all') {
      this.loadingService.show();
      this.hierarchyService.agentChannel(subChannel, channel, channelType)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          if (data) {
            this.agentChannelList = data;
            (this.fields.agentChannel as FdSelectConfig).items = [this.filterAll];
            (this.fields.agentChannel as FdSelectConfig).items.push(...data);
            this.formGroup.controls.agentChannel.enable();
          }
        }, (error: HttpErrorResponse) => {
          this.formGroup.controls.agentChannel.disable();
          this.formGroup.controls.agentChannel.setValue('');
          // if (error.status === 404) {
          //     this.dialogService.openDialog(Messages.AGENT_CHANNEL_NOT_FOUND);
          //     return;
          // }
          // this.dialogService.openDialog(Messages.AGENT_CHANNEL_LOAD_ERROR);
          console.log(error);
        });
    }
    else {
      this.formGroup.controls.agentChannel.disable();
      this.formGroup.controls.agentChannel.setValue('');
    }
  }

  get isSupervisor() {
    return this.authService.getUserData() && this.authService.getUserData().userType === UserTypeEnum.SUPERVISOR;
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  detail(idProposal: number) {
    const dialogRef = this.dialog.open(ProposalDetailComponent, {
      width: '45%',
      height: '95%',
      data: idProposal
    });
  }

  loadAccreditationSource(filterValue: string) {
    if (filterValue) {
      this.filterDropdowns(filterValue, this.accreditationSourceList, this.fields.accreditationSource);
      return;
    }

    this.proposalService.findAllAccreditationSource()
      .subscribe(response => {
        if (response != null) {
          const mapResponse = response.map(key => ({
            value: key.id,
            label: key.sourceDescription,
          })) as Item[];
          this.accreditationSourceList = mapResponse;
          (this.fields.accreditationSource as FdSelectConfig).items = [this.filterAll];
          (this.fields.accreditationSource as FdSelectConfig).items.push(...mapResponse);
        }
      }, (error: HttpErrorResponse) => {
        console.error(error);
      });
  }

  loadAccreditationChannelApi(filterValue: string) {
    this.formControls.accreditationChannelApi.disable();

    if (filterValue) {
      this.filterDropdowns(filterValue, this.accreditationChannelApiList, this.fields.accreditationChannelApi);
      return;
    }

    const mappedAccreditationChannel = Object.values(AccreditationChannelEnum).map(key => ({
      value: key,
      label: key,
    })) as Item[];

    this.accreditationChannelApiList = mappedAccreditationChannel;
    (this.fields.accreditationChannelApi as FdSelectConfig).items = [this.filterAll];
    (this.fields.accreditationChannelApi as FdSelectConfig).items.push(...mappedAccreditationChannel);
  }

  onSelectionAccreditationSource() {
    const fiservOnlineApi = 'Fiserv Online Api';
    const selectedValue = this.formGroup.value.accreditationSource;
    const label = (this.fields.accreditationSource as FdSelectConfig).items
      .find(e => e.value === selectedValue).label;

    if (label === fiservOnlineApi || selectedValue === 'all') {
      this.isAccreditationSourceApi = true;
      this.formControls.accreditationChannelApi.enable();
    } else {
      this.isAccreditationSourceApi = false;
      this.formControls.accreditationChannelApi.disable();
      this.formControls.accreditationChannelApi.setValue('');
    }
  }
}
