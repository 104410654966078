export enum Routes {
    PRICE_PREMIUM_UF = '/price-premium-uf',
    PRICE_CAMPAIGN = '/price-campaign',
    ECONOMICAL_GROUP = '/price-economical-group',
    USERS = '/users',
    CONFIGURATION_SETUP = '/configuration-setup',
    CNAE_COMPLIANCE_REGISTER = '/cnae-compliance-register',
    ECOMMERCE_CNAE_WHITELIST = '/ecommerce-cnae-whitelist',
    COMPLIANCE_PROPOSAL_LIST = '/compliance-proposals',
    CONFIGURATION_CHECKLIST = '/configuration-checklist',
    PAYMENT_GATEWAY = '/payment-gateway',
    SERVICE_CONFIG = '/service-config',
    PRICE_CHECK_CONFIG = '/price-check-config',
    SEARCH_PROPOSALS = '/search-proposals',
    PROFILES = '/profiles',
    BW_SERVICE_CONTRACT_CONFIG = '/bw-service-contract-config',
    OPERATIONS_PROPOSALS = '/operations-proposals',
    CREDIT_RISK = '/credit-risk-proposals',
    APIS_STATUS = '/scheduled-maintenance',
    CONFIGURATION_PROFESSIONAL_LICENSE = '/configuration-professional-license',
    PRICING_PROPOSALS = '/pricing-proposals',
    CONFIGURATION_APPLICATION = '/configuration-app',
    CONCILIATORS = '/conciliators',
    INTEGRATION_TOKEN_CONFIGURATION = '/integration-token-configuration',
    TOPIC_CONFIGURATION = '/topic-configuration',
    REPORTS = '/reports',
    CNAE_CREDIT_RISK_REGISTER = '/cnae-credit-risk-register',
    FAQ_CATEGORIES = '/faq-category',
    FAQ_CONFIGURATION = '/faq-configuration',
    FEATURE_TOGGLE = '/engine-configuration',
    THREE_DS_CONFIGURATION = '/three-ds-configuration',
    CONFIGURATION_SIMULATOR = '/configuration-simulator',
    RESELLER_CLOVER_CONFIGURATION = '/reseller-clover-configuration',
    PASSWORD_RECOVERY = '/password-recovery',
    WORKFLOW = '/workflow'
    // ECONOMICAL_GROUP_CONVENIENCE = '/price-economical-group-convenience',
}
