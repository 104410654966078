import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators, AbstractControl } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { ComplianceActivitiesService } from 'src/app/cnae-compliance-register/services/compliance-activity.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { CnaeService } from 'src/app/cnae-compliance-register/services/cnae.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { FdSelectConfig } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { takeWhile, finalize } from 'rxjs/operators';
import { ComplianceActivitiesSaveModel, ComplianceActivitiesModel } from 'src/app/cnae-compliance-register/models/compliance-activities.model';
import { Messages } from 'src/app/shared/messages/messages';
import { FdAlertComponent, ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';

@Component({
  selector: 'app-edit-cnaes',
  templateUrl: './edit-cnaes.component.html',
  styleUrls: ['./edit-cnaes.component.scss']
})
export class EditCnaesComponent implements OnInit {

  formGroup: FormGroup;
  fields: FdFieldConfigs;
  selectedInstitution: string;
  alive = true;

  ngOnDestroy() {
    this.alive = false;
  }


  constructor(
    private complianceActivitiesService: ComplianceActivitiesService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<EditCnaesComponent>,
    private loadingService: LoadingService,
    private cnaeService: CnaeService,
    private errorService: ErrorService,
    private dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: {
      institution: string,
      activities: ComplianceActivitiesModel
    },
    private dialog: MatDialog) { }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
  
    setTimeout(() => {
      (this.fields.serviceContract as FdSelectConfig).items.map(x => x.selected = x.value === this.data.activities.serviceContractId);
      this.formControls.serviceContract.setValue(this.data.activities.serviceContractId);
      this.getCnaeList('');
    },2500)
   
  }

  getCnaeList(filterValue: string) {

    // No caso de CPF, os CNAE's são buscados da API do config.
    if (filterValue && filterValue.length < 3) {
      (this.fields.cnae as FdSelectConfig).items = [];
      return;
    }

    this.loadingService.show();

    this.cnaeService.getCnaeList(this.formControls.serviceContract.value, filterValue || '', true)
      .pipe(takeWhile(() => this.alive))
      .subscribe(cnaeList => {
        if (cnaeList != null) {
          this.cnaeDropdown.items = [];
          this.cnaeDropdown.items.push(...cnaeList);
          this.formControls.cnae.setValue(this.data.activities.cnae);
          this.cnaeDropdown.items.map(x => x.selected = x.value === this.data.activities.cnae);
        }
        this.loadingService.hide();
      }, error => {
        this.loadingService.hide();
        this.cnaeDropdown.items = [];
      });
  }

  get cnaeDropdown() {
    return (this.fields.cnae as FdSelectConfig);
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    this.formControls.status.setValue(this.data.activities.complianceActivitiesStatusId);
    (this.fields.status as FdSelectConfig).items.map(x => x.selected = x.value === this.data.activities.complianceActivitiesStatusId);
    this.formControls.institution.setValue(this.data.activities.institution);
    this.changeSelectedInstitution(this.data.activities.institution);
    
  }

  public isFormGroupValid(formGroup?: FormGroup): boolean {
    const formToBeValidated = !!formGroup ? formGroup : this.formGroup;
    if (formToBeValidated.invalid) {
      this.showErrorMessages(formToBeValidated);
      return false;
    }
    return true;
  }


  private showErrorMessages(formToBeValidated: FormGroup | FormArray) {
    let control;
    Object.keys(formToBeValidated.controls)
      .reverse()
      .forEach(field => {
        control = formToBeValidated.get(field);
        if (control instanceof FormArray ||
          control instanceof FormGroup) {
          this.showErrorMessages(control);
        } else {
          if (control && control.invalid) {
            control.markAsTouched();
          }
        }
      });
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      status: ['', Validators.required],
      cnae: ['', Validators.required],
      serviceContract: ['', Validators.required],
      institution: ['', Validators.required],
    });
  }

  get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  close() {
    this.dialogRef.close();
  }

  get hasServiceContract() {
    return this.formControls.serviceContract && this.formControls.serviceContract.value;
  }

  save() {
    let code: ComplianceActivitiesSaveModel = new ComplianceActivitiesSaveModel();

    code.serviceContract = this.formControls.serviceContract.value;

    code.activities = [];

    const model = this.formGroup.getRawValue();
    if (model.cnae && model.status) {
      code.activities.push({
        cnaeNumber: model.cnae,
        status: model.status
      });
    }

    const filteredArray = code.activities.map(x => x.cnaeNumber);

    // Verifica se o array possui valores duplicados
    if (code.activities.length > 0 && filteredArray.length !== new Set(filteredArray).size) {
      this.dialogService.openDialog(Messages.DUPLICATED_CNAE_IDS)
      return;
    }

    this.loadingService.show();

    this.complianceActivitiesService
      .saveComplianceCnaes(code)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {

        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });

        successDialogRef.afterClosed().subscribe(obs => {
          this.dialogRef.close();
        });
      }, error => {
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      });
  }

  createFields(): FdFieldConfigs {
    return {
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      },
      cnae: {
        label: 'CNAE',
        controlName: 'cnae',
        disabled: true,
        items: [],
        searchable: true,
        searchPlaceholder: 'Insira o CNAE',
        messages: {
          required: 'campo obrigatório'
        }
      },
      status: {
        label: 'Status',
        controlName: 'status',
        items: [
          {
            value: 'UNWANTED',
            label: 'Indesejado'
          },
          {
            value: 'RESTRICTED',
            label: 'Restrito'
          },
        ],
        messages: {
          required: 'campo obrigatório'
        }
      }
    };
  }

}
