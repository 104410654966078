<h3 fxLayoutAlign="center">Configurar tecnologia</h3>
<form [formGroup]="formGroup">
    <div fxLayout="column" fxLayoutAlign="center">
        <div fxLayoutGap="20px">
            <div fxFlex="50">
                <fd-input [parentForm]="formGroup" [field]="fields.institutionNumber"></fd-input>
            </div>
            <div fxFlex="50">
                <fd-input [parentForm]="formGroup" [field]="fields.seriviceContract"></fd-input>
            </div>
        </div>
        <div fxLayoutGap="20px">
            <div fxFlex="50">
                <fd-input [parentForm]="formGroup" [field]="fields.serviceID"></fd-input>
            </div>
            <div fxFlex="50">
                <fd-input [parentForm]="formGroup" [field]="fields.typeTerminal"></fd-input>
            </div>
        </div>
        <div>
            <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
        </div>
        <div *ngIf="isVenda" fxLayoutGap="20px">
            <div fxFlex="50">
                <fd-select [parentForm]="formGroup" [field]="fields.type" (selectionChange)="checkType()">
                </fd-select>
            </div>
            <div fxFlex="50">
                <fd-select [parentForm]="formGroup" [field]="fields.producer">
                </fd-select>
            </div>
        </div>
        <div *ngIf="!isVenda" fxLayoutGap="20px">
            <div fxFlex="50">
                <fd-select [parentForm]="formGroup" [field]="fields.type" (selectionChange)="checkType()">
                </fd-select>
            </div>
        </div>       
       <div fxFlexAlign="center">
            <mat-slide-toggle formControlName="status" (change)="toggleChange($event)"> {{tooglemessage}} </mat-slide-toggle>
        </div>
        <div class="options-image-container">
            <h4>Imagem tecnologia</h4>
            <fd-image-uploader style="width: 100%;" (onImageSelected)="onImageSelected($event)" (onImageDeleted)="onImageDeleted()" [imageUrl]="imageLoadedUrl"></fd-image-uploader>
        </div>

        <fd-input
            [hidden]="imageFile == null && imageLoadedUrl == null"
            [parentForm]="formGroup"
            [field]="fields.imageDescription"
            fxFlex=30 >
        </fd-input>
    </div>
</form>

<div mat-dialog-actions align="center">
    <div>
        <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
        <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
    </div>
</div>