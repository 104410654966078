import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { finalize, map } from 'rxjs/operators';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { UserTypeEnum } from 'src/app/shared/enums/user-type.enum';
import { ModalDefinitions } from 'src/app/shared/fd-form-components/fd-alert/fd-alert.component';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { EmailMask } from 'src/app/shared/masks/email-mask';
import { PhoneMask } from 'src/app/shared/masks/phone-mask';
import { Messages } from 'src/app/shared/messages/messages';
import { UserEditModel, UserModel } from 'src/app/shared/models/user.model';
import { AuthService } from 'src/app/shared/service/auth.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { ErrorService } from 'src/app/shared/service/error.service';
import { HierarchyService } from 'src/app/shared/service/hierarchy.service';
import { LoadingService } from 'src/app/shared/service/loading.service';
import { RequiredIfValidator } from 'src/app/shared/validators/required-if-validator';
import { BaseConciliatorModel } from '../../../conciliator/models/conciliator.model';
import { ConciliatorService } from '../../../conciliator/services/conciliator.service';
import { ProfileModel } from '../../../shared/models/profile.model';
import { UserService } from '../../services/user.service';
import {
  ServiceContractSelectModalComponent,
  ServiceContractSelectModalDialogData
} from '../add-user/service-contract-select-modal/service-contract-select-modal.component';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss']
})
export class EditUserComponent implements OnInit {

  loadState: boolean = true;
  formGroup: FormGroup;
  typeChanged = false;
  passwordDefinitionMethods: Item[] = [
    {
      value: SecretDefinitionMethodEnum.FORM,
      label: 'Definir a senha agora'
    },
    {
      value: SecretDefinitionMethodEnum.EMAIL,
      label: "Receber um link por e-mail"
    }
  ];
  fields: FdFieldConfigs;
  userTypes: Item[] = [];
  lastUserTypeSelected: UserTypeEnum;
  currentStep: UserCreateStepEnum = UserCreateStepEnum.DATA_CREATE;
  tokenChannelOptions: Item[] = [
    {
      value: 'SMS',
      selected: true,
      label: 'SMS'
    },
    {
      value: 'EMAIL',
      label: 'E-mail'
    }
  ];

  conciliators: Item[] = [];
  isReqCodeAndWorkdayEnabledToAdminWithoutFullAccess: boolean;
  allServiceContracts: InstitutionAndServiceContractModel[];


  get isInitialStep() {
    return this.currentStep === UserCreateStepEnum.DATA_CREATE;
  }

  get isPasswordStep() {
    return this.currentStep === UserCreateStepEnum.PASSWORD_CREATE;
  }


  get isTokenStep() {
    return this.currentStep === UserCreateStepEnum.TOKEN_VALIDATE;
  }

  get isPasswordDefinitionAsEmail() {
    return this.formGroup &&
      this.formGroup.value &&
      this.formGroup.value.passwordDefinitionMethod &&
      this.formGroup.value.passwordDefinitionMethod === SecretDefinitionMethodEnum.EMAIL
  }

  get isPasswordDefinitionAsForm() {
    return this.formGroup &&
      this.formGroup.value &&
      this.formGroup.value.passwordDefinitionMethod &&
      this.formGroup.value.passwordDefinitionMethod === SecretDefinitionMethodEnum.FORM;
  }

  get shouldShowRequestCodeAndWorkday() {
    let isInstitution07Service = this.formGroup?.value?.institution == '00000007';
    return (this.isFullAccessMarked || isInstitution07Service) || (!this.isFullAccessMarked && this.isReqCodeAndWorkdayEnabledToAdminWithoutFullAccess) && !this.isConciliator
  }

  constructor(
    private userService: UserService,
    private authService: AuthService,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private hierarchyService: HierarchyService,
    private loadingService: LoadingService,
    private dialogService: DialogService,
    public dialogRef: MatDialogRef<EditUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserModel,
    private conciliatorService: ConciliatorService,
    private formBuilder: FormBuilder
  ) { }

  get userType(): UserTypeEnum {
    if (this.isAdmin) {
      return UserTypeEnum.ADMIN;
    }
    else if (this.isSupervisor) {
      return UserTypeEnum.SUPERVISOR;
    }
    else if (this.isVendor) {
      return UserTypeEnum.VENDOR;
    }
    else if (this.isConciliator) {
      return UserTypeEnum.CONCILIATOR;
    }
  }

  ngOnInit() {
    this.formGroup = this.createFormGroup();
    this.fields = this.createFields();

    if (this.isAdmin || this.isConciliator) {
      this.loadProfiles();
    }

    if (this.isConciliator) {
      this.loadConciliators();
    }

    this.setFieldValues(this.data);


    this.formGroup.controls.userProfile.setValidators(
      [
        RequiredIfValidator(() => this.isAdmin)
      ]
    );

    this.formGroup.controls.institution.setValidators(
      [
        RequiredIfValidator(() => this.isVendor)
      ]
    );

    this.formGroup.controls.channelType.setValidators(
      [
        RequiredIfValidator(() => this.isVendor || !this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SUPERVISOR_HIERARCHY]))
      ]
    );

    this.formGroup.controls.channel.setValidators(
      [
        RequiredIfValidator(() => this.isVendor || !this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SUPERVISOR_HIERARCHY]))
      ]
    );

    this.formGroup.controls.conciliatorId.setValidators(
      [
        RequiredIfValidator(() => this.isConciliator)
      ]
    );


    if (this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE])) {
      this.userTypes.push({
        value: UserTypeEnum.SUPERVISOR,
        label: 'Supervisor'
      })
    }
    if (this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE])) {
      this.userTypes.push({
        value: UserTypeEnum.ADMIN,
        label: 'Administrador'
      })
    }
    if (this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE])) {
      this.userTypes.push({
        value: UserTypeEnum.VENDOR,
        label: 'Vendedor'
      })
    }
    if (this.authService.isUserInRoles([AdminRolesEnum.CONCILIATOR_BACKOFFICE_USER_MASTER])) {
      this.userTypes.push({
        value: UserTypeEnum.CONCILIATOR,
        label: 'Conciliador'
      });
    }
    this.setUserType(this.userType, false);
  }

  loadConciliators() {
    (this.fields.conciliatorId as FdSelectConfig).items = [];
    this.conciliatorService.getAllActiveConciliators().pipe(map(p => this.mapToConciliatorModel(p))).subscribe(data => {
      (this.fields.conciliatorId as FdSelectConfig).items.push(...data);
    });
  }

  private mapToConciliatorModel(response: BaseConciliatorModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response
      .map(value => ({
        value: value.id,
        label: value.name,
      }));
  }

  setUserType(userType: UserTypeEnum, typeChanged: boolean) {
    if (!userType || !typeChanged) { return; }

    this.formGroup.controls.institution.setValue('');

    this.clearFieldValues([
      userType !== UserTypeEnum.ADMIN ? HierarchyFieldsEnum.SERVICE_CONTRACT : null,
      HierarchyFieldsEnum.AGENT_CHANNEL,
      HierarchyFieldsEnum.CHANNEL_TYPE,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.CHANNEL,
    ])

    this.loadInstitutions();

    if (userType === UserTypeEnum.ADMIN) {
      this.loadProfiles();
      this.formGroup.controls.serviceContracts.enable();
      this.formGroup.controls.userProfile.enable();
    } else if (userType === UserTypeEnum.VENDOR) {
      this.formControls.institution.setValue('');
      if (this.lastUserTypeSelected === UserTypeEnum.ADMIN) {
        this.formGroup.controls.channel.enable();
        this.formGroup.controls.subChannel.enable();
        this.formGroup.controls.agent.enable();
        this.formGroup.controls.channelType.enable();
      }
      this.formGroup.controls.userProfile.setValue(null);
      this.formGroup.controls.userProfile.disable();
      this.loadChannelTypes();
    }
    else if (userType === UserTypeEnum.SUPERVISOR) {

      this.formControls.institution.setValue('');

      this.data.serviceContracts = [];
      this.data.institution = "";
      this.data.channelType = "";
      this.data.channel = "";
      this.data.subChannel = "";

      if (this.lastUserTypeSelected === UserTypeEnum.ADMIN) {
        this.formGroup.controls.channel.enable();
        this.formGroup.controls.subChannel.enable();
        this.formGroup.controls.channelType.enable();
      }
      this.formGroup.controls.userProfile.setValue(null);
      this.formGroup.controls.userProfile.disable();
      this.loadInstitutions();
    } else if (userType === UserTypeEnum.CONCILIATOR) {
      (this.fields.channel as FdSelectConfig).items = [];
      (this.fields.subChannel as FdSelectConfig).items = [];
      (this.fields.agent as FdSelectConfig).items = [];
      (this.fields.channelType as FdSelectConfig).items = [];
      this.formGroup.controls.channel.disable();
      this.formGroup.controls.subChannel.disable();
      this.formGroup.controls.agent.disable();
      this.formGroup.controls.channelType.disable();
      this.formGroup.controls.channel.setValue('');
      this.formGroup.controls.subChannel.setValue('');
      this.formGroup.controls.agent.setValue('');
      this.formGroup.controls.channelType.setValue('');
      this.formGroup.controls.userProfile.enable();
      this.formGroup.controls.conciliatorId.enable();
      this.loadConciliators();
      this.formGroup.value.serviceContracts = [];
    }
    this.lastUserTypeSelected = userType;
    this.data.type = userType;
    this.typeChanged = typeChanged;
  }

  get formControls() {
    return this.formGroup.controls;
  }

  onFullAccessChange() {
    if (!this.formControls.fullAccess.value) {
      this.formControls.serviceContracts.setValue(null);
    }
  }

  setFieldValues(data: UserModel) {
    this.formControls.name.setValue(data.name);
    this.formControls.userType.setValue(data.type);
    this.formControls.email.setValue(data.email);
    this.formControls.mobileNumber.setValue(data.mobileNumber);
    this.formControls.fullAccess.setValue(data.fullAccess);
    this.formControls.conciliatorId.setValue(data.conciliatorId);
    this.formControls.requestCode.setValue(data.requestCode);
    this.formControls.workday.setValue(data.workday);

    if (this.isAdmin) {
      this.formControls.serviceContracts.setValue(data.serviceContracts);
      this.loadAllServiceContracts();
    }
  }

  get isVendor() {
    return this.data.type === UserTypeEnum.VENDOR && this.authService.isUserInRoles([AdminRolesEnum.USERS_VENDOR_WRITE])
  }

  get isSupervisor() {
    return this.data.type === UserTypeEnum.SUPERVISOR && this.authService.isUserInRoles([AdminRolesEnum.USERS_SUPERVISOR_WRITE])
  }

  get isAdmin() {
    return this.data.type === UserTypeEnum.ADMIN && this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE])
  }

  get isConciliator() {
    return this.data.type === UserTypeEnum.CONCILIATOR && this.authService.isUserInRoles([AdminRolesEnum.CONCILIATOR_BACKOFFICE_USER_MASTER]);
  }

  createFormGroup(): FormGroup {
    return this.formBuilder.group({
      name: ['', Validators.required],
      mobileNumber: ['', Validators.required],
      email: ['', Validators.required],
      fullAccess: [''],
      userProfile: [''],
      userType: ['', RequiredIfValidator(() => this.isSupervisor || this.isAdmin)],
      institution: [''],
      serviceContracts: ['', RequiredIfValidator(() => !this.isFullAccessMarked)],
      channelType: [''],
      channel: [''],
      subChannel: [''],
      agent: [''],
      conciliatorId: ['', Validators.required],
      requestCode: [''],
      workday: ['', Validators.maxLength(50)]
    });
  }

  returnOnlyDigits(value: string) {
    if (value) {
      return value.replace(/[^0-9]/g, '');
    }
  }

  getServiceContracts() {
    const { serviceContracts } = this.formGroup.value;

    if (this.isAdmin) {
      return this.isFullAccessMarked ? [] : this.serializeServiceContracts(serviceContracts);
    }

    return serviceContracts === '' ? [] : [serviceContracts];
  }

  get isFullAccessMarked() {
    return this.formGroup &&
      this.formGroup.value &&
      !!this.formGroup.value.fullAccess;
  }

  serializeServiceContracts(serviceContractList: Item[]) {
    if (!serviceContractList || !serviceContractList.length && !this.isFullAccessMarked) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }
    return serviceContractList.map(x => x.value);
  }

  save(): void {
    this.loadingService.show();

    const data: UserEditModel = {
      agent: this.formGroup.value.agent || null,
      channel: this.formGroup.value.channel || null,
      channelType: this.formGroup.value.channelType || null,
      subChannel: this.formGroup.value.subChannel || null,
      cpf: this.data.cpfCnpj,
      email: this.formGroup.value.email,
      userType: this.data.type,
      institution: this.formGroup.value.institution,
      name: this.formGroup.value.name,
      mobileNumber: this.returnOnlyDigits(this.formGroup.value.mobileNumber),
      serviceContracts: this.getServiceContracts(),
      idProfile: this.formGroup.value.userProfile,
      conciliatorId: this.formGroup.value.conciliatorId || null,
      requestCode: this.formGroup.value.requestCode,
      workday: this.formGroup.value.workday
    };

    if (this.isFullAccessMarked) {
      this.formGroup.controls.serviceContracts.disable();
    }
    else {
      this.formGroup.controls.serviceContracts.enable();
    }

    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    if (this.isConciliator) {
      const serviceContractsFilter: any[] = data.serviceContracts;
      data.serviceContracts = serviceContractsFilter.filter(item => item !== undefined);
    }

    if(this.isVendor && !this.formGroup.value.subChannel){
      this.loadingService.hide();
      this.dialogService.openDialog(Messages.ALL_SUBCHANNELS_WITH_SAME_CPF_ALERT, () => this.continueUpdateUser(data));
      return;
    }

    this.continueUpdateUser(data);
  }

  continueUpdateUser(data: UserEditModel) {
    this.loadingService.show();
    this.userService.updateUser(data)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe((response) => {
        if (response && response.error) {
          this.dialogService.openDialog(Messages.EDIT_SAVE_ERROR);
          return;
        }
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.close());
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.EDIT_SAVE_ERROR));

  }

  sendEmailPassword() {
    this.loadingService.show();

    const data: UserModel = {
      agent: this.formGroup.value.agent || null,
      channel: this.formGroup.value.channel || null,
      channelType: this.formGroup.value.channelType || null,
      subChannel: this.formGroup.value.subChannel || null,
      cpfCnpj: this.data.cpfCnpj,
      email: this.formGroup.value.email,
      type: this.data.type,
      institution: this.formGroup.value.institution,
      name: this.formGroup.value.name,
      mobileNumber: this.returnOnlyDigits(this.formGroup.value.mobileNumber),
      serviceContracts: this.getServiceContracts(),
      profileId: this.formGroup.value.userProfile
    }

    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.DATA_REQUIRED_ERROR);
      return;
    }

    this.userService.sendPasswordEmail(data).
      pipe(finalize(() => this.loadingService.hide())).
      subscribe(() => this.dialogService.openDialog(Messages.RESEND_PASSWORD_EMAIL));
  }

  close() {
    this.dialogRef.close();
  }

  setSelectedServiceContracts(response: InstitutionAndServiceContractModel[], selectedItems: Item[]) {

    if (!selectedItems || !selectedItems.length) {
      return;
    }

    const selectedItemsCodes = selectedItems.map(x => {
      x.selected = true;
      return x.value;
    });
    response.forEach(institution => {
      institution.serviceContracts.map(item => item.selected = selectedItemsCodes.includes(item.value));
    })
  }

  openDialog() {
    const dialogData: ServiceContractSelectModalDialogData = {
      itemGroups: this.allServiceContracts,
      title: 'service contracts',
      formControl: this.formGroup.controls.serviceContracts,
      formGroup: this.formGroup,
      confirmCallbackFn: () => this.shouldShowRequestCodeAndWorkdayToAdminWithoutFullAccessMarked(this.allServiceContracts, this.formGroup.controls.serviceContracts.value),
      cancelCallbackFn: () => this.shouldShowRequestCodeAndWorkdayToAdminWithoutFullAccessMarked(this.allServiceContracts, this.formGroup.controls.serviceContracts.value)
    };

    const dialogRef = this.dialog.open(ServiceContractSelectModalComponent, {
      width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
      height: '500px',
      data: dialogData,
      disableClose: true
    });
  }

  loadAllServiceContracts() {
    this.hierarchyService.getAllServiceContracts()
      .subscribe(allServiceContracts => {
        this.allServiceContracts = allServiceContracts;
        this.setSelectedServiceContracts(allServiceContracts, this.formGroup.value.serviceContracts);
        this.shouldShowRequestCodeAndWorkdayToAdminWithoutFullAccessMarked(allServiceContracts, this.formGroup.value.serviceContracts);
      }, (err: HttpErrorResponse) => this.errorService.handleXHRError(err, Messages.SERVICE_CONTRACT_LOAD_ERROR));
  }


  loadInstitutions(): void {

    if (this.isAdmin) {
      return;
    }

    this.clearFieldValues([
      HierarchyFieldsEnum.SERVICE_CONTRACT,
      HierarchyFieldsEnum.CHANNEL_TYPE,
      HierarchyFieldsEnum.CHANNEL,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    if(!this.loadingService.isLoadingActive()){
      this.loadingService.show();
    }

    this.hierarchyService.institutionByUserType(this.userType)
      .subscribe(list => {
        if (list != null) {
          (this.fields.institution as FdSelectConfig).items = [];
          (this.fields.institution as FdSelectConfig).items.push(...list);
          if (this.loadState) {
            setTimeout(() => {
              if (!this.typeChanged) {
                this.formControls.institution.setValue(this.data.institution);
                this.loadServiceContracts();
                return;
              }
              this.loadingService.hide();
            }, 200);
          }
          else{
            this.loadingService.hide();
          }
          return;
        }
        this.loadingService.hide();
      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.INSTITUTION_NOT_FOUND);
          return;
        }

        this.dialogService.openDialog(Messages.INSTITUTION_LOAD_ERROR);
      });
  }

  loadServiceContracts(clearAllFields?: boolean): void {

    if(!this.loadingService.isLoadingActive()){
      this.loadingService.show();
    }

    if (!!clearAllFields) {
      this.loadState = false;
      this.data.serviceContracts = [];
      this.data.institution = "";
      this.data.channelType = "";
      this.data.channel = "";
      this.data.subChannel = "";
    }

    this.clearFieldValues([
      HierarchyFieldsEnum.SERVICE_CONTRACT,
      HierarchyFieldsEnum.CHANNEL_TYPE,
      HierarchyFieldsEnum.CHANNEL,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    let institutionNumber = this.formGroup.value.institution;


    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }
    this.hierarchyService.serviceContractByInstitutionAndUserType(institutionNumber, this.userType)
      .subscribe(data => {
        if (data) {
          (this.fields.serviceContracts as FdSelectConfig).items = [];
          (this.fields.serviceContracts as FdSelectConfig).items.push(...data);
          this.formGroup.controls.serviceContracts.enable();

          if (this.loadState) {
            if (this.isVendor || this.isSupervisor) {
              this.formControls.serviceContracts.setValue((this.data.serviceContracts as Item[])[0].value);
              (this.fields.serviceContracts as FdSelectConfig).items
                .map(item => item.selected = item.value === this.formControls.serviceContracts.value);
            }
            else {
              this.formControls.serviceContracts.setValue(this.data.serviceContracts);
            }

            if (this.isVendor || this.isSupervisor) {
              if (!this.typeChanged) {
                setTimeout(() => {
                  this.loadChannelTypes();
                }, 200)
                return;
              }
              this.loadingService.hide();
            }
            else{
              this.loadingService.hide();
            }
            return;
          }
        }
        this.loadingService.hide();
      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.formGroup.controls.serviceContracts.disable();
        this.formGroup.controls.serviceContracts.setValue('');

        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
  }

  canResendEmail() {
    return this.authService.isUserInRoles([AdminRolesEnum.RESEND_FIRST_ACCESS_EMAIL]) && !this.data.passwordSet;
  }


  loadChannelTypes(): void {

    let institutionNumber = this.formGroup.value.institution;
    const arr = this.isSupervisor && this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SUPERVISOR_HIERARCHY]) ? [{ value: '', label: 'Todos' }] : [];

    this.clearFieldValues([
      HierarchyFieldsEnum.CHANNEL_TYPE,
      HierarchyFieldsEnum.CHANNEL,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);


    if (this.isAdmin || !institutionNumber) {
      this.loadingService.hide();
      return;
    }

    if(!this.loadingService.isLoadingActive()){
      this.loadingService.show();
    }

    this.hierarchyService.channelType(institutionNumber)
      .subscribe(data => {
        if (data) {
          (this.fields.channelType as FdSelectConfig).items = arr;
          (this.fields.channelType as FdSelectConfig).items.push(...data);
          this.formGroup.controls.channelType.enable();

          if (this.loadState) {

            this.formControls.channelType.setValue(this.data.channelType || '');

            (this.fields.channelType as FdSelectConfig).items
              .map(item => item.selected = item.value === this.formControls.channelType.value);

            if (!this.typeChanged) {
              setTimeout(() => {
                this.loadChannels();
              }, 200)
              return;
            }
            this.loadingService.hide();
          }
          else{
            this.loadingService.hide();
          }
          return;
        }
        this.loadingService.hide();
      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.formGroup.controls.channelType.disable();
        this.formGroup.controls.channelType.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.CHANNEL_TYPE_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.CHANNEL_TYPE_LOAD_ERROR);
      });
  }

  loadChannels(): void {

    const arr = this.isSupervisor && this.authService.isUserInRoles([AdminRolesEnum.OPTIONAL_SUPERVISOR_HIERARCHY]) ? [{ value: '', label: 'Todos' }] : [];

    this.clearFieldValues([
      HierarchyFieldsEnum.CHANNEL,
      HierarchyFieldsEnum.SUB_CHANNEL,
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    let channelType = this.formGroup.value.channelType;

    if(!channelType){
      this.loadingService.hide();
      return;
    }

    if(!this.loadingService.isLoadingActive()){
      this.loadingService.show();
    }

    this.hierarchyService.channel(channelType)
      .subscribe(data => {
        if (data) {
          (this.fields.channel as FdSelectConfig).items = arr;
          (this.fields.channel as FdSelectConfig).items.push(...data);
          this.formGroup.controls.channel.enable();

          if (this.loadState) {

            this.formControls.channel.setValue(this.data.channel || '');
            (this.fields.channel as FdSelectConfig).items
              .map(item => item.selected = item.value === this.formControls.channel.value);

            if (!this.typeChanged) {
              setTimeout(() => {
                this.loadSubChannels();
              }, 200)
              return;
            }
            this.loadingService.hide();
          }
          else{
            this.loadingService.hide();
          }
          return;
        }
        this.loadingService.hide();
      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.formGroup.controls.channel.disable();
        this.formGroup.controls.channel.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.CHANNEL_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.CHANNEL_LOAD_ERROR);
      });
  }

  loadSubChannels(): void {

    let channel = this.formControls.channel.value;
    let channelType = this.formControls.channelType.value;

    const subChannelArr = this.isSupervisor || this.isVendor ? [{ value: '', label: 'Todos' }] : [];

    if(!this.loadingService.isLoadingActive()){
      this.loadingService.show();
    }

    (this.fields.agent as FdSelectConfig).items = [];
    this.formGroup.controls.agent.disable();

    if (!channel) {
      channel = this.formGroup.value.channel;
    }

    this.hierarchyService.subChannel(channel, channelType)
      .subscribe(data => {
        if (data) {
          (this.fields.subChannel as FdSelectConfig).items = subChannelArr;
          (this.fields.subChannel as FdSelectConfig).items.push(...data);
          this.formGroup.controls.subChannel.enable();

          if (this.loadState) {
            if (!this.hasUserHierarchyInformation()) {
              this.formControls.subChannel.setValue('');
              (this.fields.subChannel as FdSelectConfig).items
                .map(item => item.selected = item.value === '');
            }
            else {
              const userHierarchyArr = this.getUserHierarchyInformation();
              if (userHierarchyArr.length > 1) {
                this.formControls.subChannel.setValue('');
                (this.fields.subChannel as FdSelectConfig).items
                  .map(item => item.selected = item.value === '');
              }
              else {
                this.formControls.subChannel.setValue(userHierarchyArr[0].subChannel || '');
                (this.fields.subChannel as FdSelectConfig).items
                  .map(item => item.selected = item.value === this.formControls.subChannel.value);
              }
            }

            if (!this.typeChanged) {
              setTimeout(() => {
                this.loadAgentChannels();
              }, 200)
              return;
            }
            this.loadingService.hide();
          }
          else{
            this.loadingService.hide();
          }
          return;
        }
        this.loadingService.hide();
      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.formGroup.controls.subChannel.disable();
        this.formGroup.controls.subChannel.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SUB_CHANNEL_NOT_FOUND);
          return;
        }

        this.dialogService.openDialog(Messages.SUB_CHANNEL_LOAD_ERROR);
      });
  }

  loadAgentChannels(): void {
    if (this.isSupervisor) {
      this.loadingService.hide();
      return;
    }

    this.clearFieldValues([
      HierarchyFieldsEnum.AGENT_CHANNEL
    ]);

    if(!this.loadingService.isLoadingActive()){
      this.loadingService.show();
    }

    const agentArr = this.isVendor ? [{ value: '', label: 'Todos' }] : [];

    if (!this.formGroup.value.subChannel) {
      this.loadingService.hide();
      return;
    }

    let subChannel = this.formGroup.value.subChannel;
    let channel = this.formGroup.value.channel;
    let channelType = this.formGroup.value.channelType;

    this.hierarchyService.agentChannel(subChannel, channel, channelType)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.agent as FdSelectConfig).items = agentArr;
          (this.fields.agent as FdSelectConfig).items.push(...data);
          this.formGroup.controls.agent.enable();
          if (this.loadState) {
            if (!this.hasUserHierarchyInformation()) {
              this.formControls.agent.setValue('');
              (this.fields.agent as FdSelectConfig).items
                .map(item => item.selected = item.value === '');
            }
            else {
              const userHierarchyArr = this.getUserHierarchyInformation();
              if (userHierarchyArr.length > 1) {
                this.formControls.agent.setValue('');
                (this.fields.agent as FdSelectConfig).items
                  .map(item => item.selected = item.value === '');
              }
              else {
                this.formControls.agent.setValue(userHierarchyArr[0].agentId || '');
                (this.fields.agent as FdSelectConfig).items
                  .map(item => item.selected = item.value === this.formControls.agent.value);
              }
            }

            this.loadState = false;
          }
          else{
            this.loadingService.hide();
          }
          return;
        }
        this.loadingService.hide();
      }, (error: HttpErrorResponse) => {
        this.loadingService.hide();
        this.formGroup.controls.agent.disable();
        this.formGroup.controls.agent.setValue('');
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.AGENT_CHANNEL_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.AGENT_CHANNEL_LOAD_ERROR);
      });
  }


  loadProfiles() {
    (this.fields.userProfile as FdSelectConfig).items = [];

    const loadProfileWithCache = true;
    this.userService.getAllProfiles(loadProfileWithCache)
      .pipe(map(p => this.mapToProfileModel(p)))
      .subscribe(data => {
        (this.fields.userProfile as FdSelectConfig).items.push(...data);
        if (this.loadState) {
          this.formControls.userProfile.setValue(this.data.profileId);
          (this.fields.userProfile as FdSelectConfig).items
            .map(item => item.selected = item.value === this.formControls.userProfile.value);
        }
      });
  }

  changeProfile(profile: number) {
    this.formGroup.value.userProfile = profile;
    (this.fields.userProfile as FdSelectConfig).items
      .map(item => item.selected = item.value === this.formControls.userProfile.value);
  }

  private mapToProfileModel(response: ProfileModel[]): Item[] {
    if (!response) {
      return [];
    }
    return response
      .map(value => ({
        value: value.id,
        label: value.description,
      }));
  }

  hasUserHierarchyInformation() {
    return !!this.data.userInformation &&
      !!this.data.userInformation.length &&
      !!this.data.userInformation[0].hierarchyComplementaryInformation &&
      !!this.data.userInformation[0].hierarchyComplementaryInformation.length;
  }

  getUserHierarchyInformation() {
    if (!this.hasUserHierarchyInformation()) {
      return;
    }
    return this.data.userInformation[0].hierarchyComplementaryInformation;
  }

  clearFieldValues(fields: HierarchyFieldsEnum[]) {
    fields.forEach(item => {
      switch (item) {
        case HierarchyFieldsEnum.INSTITUTION:
          (this.fields.institution as FdSelectConfig).items = [];
          this.formGroup.controls.institution.disable();
          this.formGroup.controls.institution.setValue('');
          break;

        case HierarchyFieldsEnum.SERVICE_CONTRACT:
          (this.fields.serviceContracts as FdSelectConfig).items = [];
          this.formGroup.controls.serviceContracts.disable();
          this.formGroup.controls.serviceContracts.setValue('');
          break;

        case HierarchyFieldsEnum.CHANNEL_TYPE:
          (this.fields.channelType as FdSelectConfig).items = [];
          this.formGroup.controls.channelType.disable();
          this.formGroup.controls.channelType.setValue('');
          break;

        case HierarchyFieldsEnum.CHANNEL:
          (this.fields.channel as FdSelectConfig).items = [];
          this.formGroup.controls.channel.disable();
          this.formGroup.controls.channel.setValue('');
          break;

        case HierarchyFieldsEnum.AGENT_CHANNEL:
          (this.fields.agent as FdSelectConfig).items = [];
          this.formGroup.controls.agent.disable();
          this.formGroup.controls.agent.setValue('');
          break;

        case HierarchyFieldsEnum.SUB_CHANNEL:
          (this.fields.subChannel as FdSelectConfig).items = [];
          this.formGroup.controls.subChannel.disable();
          this.formGroup.controls.subChannel.setValue('');
          break;
      }
    })
  }

  createFields(): FdFieldConfigs {
    return {
      name: {
        label: 'Nome',
        controlName: 'name',
        maxLength: 50,
        messages: {
          required: 'Informe um nome'
        }
      },
      fullAccess: {
        label: 'Todos os services contracts',
        controlName: 'fullAccess',
        messages: {
        }
      },
      mobileNumber: {
        label: 'Telefone',
        mask: PhoneMask,
        controlName: 'mobileNumber',
        messages: {
          required: 'Informe um telefone',
          invalid: 'telefone inválido'
        }
      },
      email: {
        label: 'E-mail',
        mask: EmailMask,
        controlName: 'email',
        messages: {
          required: 'Informe um e-mail',
          invalid: 'e-mail inválido'
        }
      },
      userProfile: {
        label: 'Perfil',
        items: [],
        controlName: 'userProfile',
        messages: {
          required: 'Informe um perfil'
        }
      },
      userType: {
        label: 'Tipo do usuario',
        items: this.userTypes,
        controlName: 'userType',
        messages: {
          required: 'Informe o tipo'
        }
      },
      institution: {
        label: 'Nº da Instituição',
        items: [],
        controlName: 'institution',
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Instituição inválida'
        }
      },
      serviceContracts: {
        label: 'Service Contract',
        items: [],
        showValueOnPlaceholder: true,
        controlName: 'serviceContracts',
        messages: {
          required: 'Informe um service contract'
        }
      },
      channel: {
        label: 'Canal',
        items: [],
        controlName: 'channel',
        messages: {
          required: 'Informe um canal'
        }
      },
      subChannel: {
        label: 'Sub Canal',
        items: [],
        controlName: 'subChannel',
        messages: {
          required: 'Informe um sub canal'
        }
      },
      channelType: {
        label: 'Tipo de canal',
        items: [],
        controlName: 'channelType',
        messages: {
          required: 'Informe um tipo de canal'
        }
      },
      agent: {
        label: 'Agente',
        items: [],
        controlName: 'agent',
        messages: {
          required: 'Informe um Agente'
        }
      },
      token: {
        label: 'Token',
        controlName: 'token',
        messages: {
          required: 'Informe o token'
        }
      },
      conciliatorId: {
        label: 'Conciliadora',
        items: this.conciliators,
        controlName: 'conciliatorId',
        messages: {
          required: 'Informe a conciliadora'
        }
      },
      requestCode: {
        label: 'Código Requisição/Observação',
        controlName: 'requestCode',
      },
      workday: {
        label: 'Workday',
        controlName: 'workday',
        maxLength: 50,
      }
    };
  }

  shouldShowRequestCodeAndWorkdayToAdminWithoutFullAccessMarked(allInstitutionAndServiceContracts: InstitutionAndServiceContractModel[], serviceContractSelected: Item[] ){
    let institutions = allInstitutionAndServiceContracts
            .filter(item => item
              .serviceContracts
              .some( sc => serviceContractSelected.some(scSelected => scSelected.value === sc.value)))
            .map(institution => institution.id);
    this.isReqCodeAndWorkdayEnabledToAdminWithoutFullAccess = this.isAdmin && !this.isFullAccessMarked && institutions.includes("00000007");
  }

}

export enum UserCreateStepEnum {
  DATA_CREATE = 1,
  TOKEN_VALIDATE = 2,
  PASSWORD_CREATE = 3
}

export enum UserProfileTypeEnum {
  VENDOR = 'VENDOR',
  ADMIN = 'ADMIN'
}

export enum SecretDefinitionMethodEnum {
  FORM = 'FORM',
  EMAIL = 'EMAIL'
}

export enum HierarchyFieldsEnum {
  SERVICE_CONTRACT = 'SERVICE_CONTRACT',
  INSTITUTION = 'INSTITUTION',
  CHANNEL_TYPE = 'CHANNEL_TYPE',
  CHANNEL = 'CHANNEL',
  AGENT_CHANNEL = 'AGENT_CHANNEL',
  SUB_CHANNEL = 'SUB_CHANNEL'
}

export interface InstitutionAndServiceContractModel {
  id: string;
  name: string;
  serviceContracts: Item[];
}
