import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { WorkflowModel } from '../models/workflow.model';

@Injectable({
  providedIn: 'root'
})
export class WorkflowService {

  constructor(private http: HttpClient) { }

  getWorkflow(institution: string, serviceContract: number): Observable<WorkflowModel> {
    const institutiontQuery = serviceContract ? `/${institution}` : '';
    const serviceContractQuery = serviceContract ? `/${serviceContract}` : '';

    return this.http.get<WorkflowModel>(`${environment.apiUrls.workflow}${institutiontQuery}${serviceContractQuery}`);
  }

  editWorkflow(workflow: WorkflowModel){
    return this.http.post<void>(`${environment.apiUrls.workflow}/update`, workflow);
  }

  downloadWorkflowHistory(institution: string, serviceContract: string) {
    return this.http.get<any>(`${environment.apiUrls.workflow}/download-file/${institution}/${serviceContract}`, { responseType: 'blob' as 'json' });
  }
}
