// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import 'zone.js/dist/zone';
import * as ApiBaseUat from './api-base/api-base-uat';
export const apiBase = `${ApiBaseUat.apiBase}/bwa`;
export const environment = {
  production: true,
  apiKey: 'IeBgvvjzmqQU47qXRyKwYX0ohFucum0H',
  apiName: 'fd-backoffice-ui',
  ACCREDITATION_URL: `${ApiBaseUat.accreditationUrl}/`,
  ADMIN_URL: `${ApiBaseUat.adminUrl}/`,
  CONCILIATOR_URL: `${ApiBaseUat.conciliatorUrl}/`,
  profile: 'UAT',
  apiUrls: {
    backoffice: `${apiBase}/backoffice`,
    markup: `${apiBase}/backoffice/markup`,
    authService: `${apiBase}/backoffice/auth`,
    exportUrl: `${apiBase}/backoffice/proposal/export/start-date`,
    searchProposal: `${apiBase}/backoffice/proposal/pageable`,
    proposal: `${apiBase}/backoffice/proposal`,
    proposalNumber: `${apiBase}/backoffice/proposal/proposalNumber`,
    merchantId: `${apiBase}/backoffice/proposal/merchantId`,
    proposalConfig: `${apiBase}/backoffice/proposal-config`,
    simulatorConfig: `${apiBase}/backoffice/simulator-config`,
    configChecklist: `${apiBase}/backoffice/checklist`,
    serviceContract: `${apiBase}/backoffice/proposal/serviceContract`,
    serviceContractList: `${apiBase}/backoffice/service-contract`,
    searchProposalStatus: `${apiBase}/backoffice/proposal/status`,
    premiumUf: `${apiBase}/backoffice/state`,
    priceCampaign: `${apiBase}/backoffice/campaign`,
    revenueRange: `${apiBase}/backoffice/campaign/billing-range`,
    priceTechnology: `${apiBase}/backoffice/campaign/price-technology`,
    serviceConfig: `${apiBase}/backoffice/service-config`,
    proposalDetail: `${apiBase}/backoffice/proposal`,
    user: `${apiBase}/backoffice/user`,
    userEmail: `${apiBase}/backoffice/user-email`,
    userBatch: `${apiBase}/backoffice/user/batch`,
    userBulk: `${apiBase}/backoffice/bulk-user`,
    economicalGroup: `${apiBase}/backoffice/economic-group`,
    profiles: `${apiBase}/backoffice/profile`,
    roles: `${apiBase}/backoffice/roles`,
    channelType: `${apiBase}/backoffice/hierarchy/channel-type`,
    channel: `${apiBase}/backoffice/hierarchy/channel`,
    subChannel: `${apiBase}/backoffice/hierarchy/sub-channel`,
    agentChannel: `${apiBase}/backoffice/hierarchy/agent-channel`,
    pricingCheckConfig: `${apiBase}/backoffice/pricing-check-config`,
    vendors: `${apiBase}/backoffice/payment-gateway-config/vendors`,
    gatewayConfiguration: `${apiBase}/backoffice/payment-gateway-config`,
    alterProposalStatus: `${apiBase}/backoffice/proposal/alter-status`,
    alterProposalStatusPendingEnabledSubmission: `${apiBase}/backoffice/proposal/alter-status-pending-enabled-submission`,
    terminalType: `${apiBase}/backoffice/terminal-type`,
    complianceActivities: `${apiBase}/backoffice/compliance-activities`,
    resellerCloverConnfiguration: `${apiBase}/backoffice/reseller`,
    cnaeCreditRisk: `${apiBase}/backoffice/cnae-credit-risk`,
    cnae: `${apiBase}/backoffice/cnae`,
    compliancePendingProposals: `${apiBase}/backoffice/compliance/pending-proposals`,
    complianceProposalUpdate: `${apiBase}/backoffice/compliance/status-proposal-change`,
    complianceProposalHistory: `${apiBase}/backoffice/compliance/proposal-history`,
    accreditationFlow: `${apiBase}/backoffice/accreditation-flow`,
    complianceProposalDetail: `${apiBase}/backoffice/compliance-cnae`,
    operationsProposals: `${apiBase}/backoffice/operation-proposal/proposals`,
    downloadOperationsProposals: `${apiBase}/backoffice/operation-proposal/download-file`,
    operationsProposalsUpdate: `${apiBase}/backoffice/operation-proposal/update-status`,
    operationsProposalDetail: `${apiBase}/backoffice/operation-proposal/ecommerce/details/`,
    operationsProposalDetailPosSmart: `${apiBase}/backoffice/operation-proposal/pos-smart/details/`,
    operationsProposalEdiDetail: `${apiBase}/backoffice/operation-proposal/edi/details/`,
    operationsProposalTefDetail: `${apiBase}/backoffice/operation-proposal/tef/details/`,
    creditRiskProposals: `${apiBase}/backoffice/credit-risk/proposals`,
    creditRiskObservationSet: `${apiBase}/backoffice/credit-risk/proposal-note/`,
    creditRiskNote: `${apiBase}/backoffice/credit-risk/credit-risk-note/`,
    basicProposalDetails: `${apiBase}/backoffice/proposal-details/basic`,
    historyProposalDetails: `${apiBase}/backoffice/proposal-details/history`,
    merchantProposalDetails: `${apiBase}/backoffice/proposal-details/merchant`,
    addressProposalDetails: `${apiBase}/backoffice/proposal-details/address`,
    bankDataProposalDetails: `${apiBase}/backoffice/proposal-details/bank-data`,
    contactsProposalDetails: `${apiBase}/backoffice/proposal-details/contacts`,
    banks: `${apiBase}/backoffice/bank/`,
    ecommerceCnaeWhitelist: `${apiBase}/backoffice/exception-cnaes-ecommerce`,
    setPendingBoardingFile: `${apiBase}/backoffice/proposal/set-pending-boarding-file`,
    apiStatus: `${apiBase}/backoffice/api-status`,
    configurationProfessionalLicense: `${apiBase}/backoffice/professional-license-config`,
    professionalLicense:`${apiBase}/backoffice/professional-license`,
    pricingProposals: `${apiBase}/backoffice/pricequeue`,
    pageablePricingProposals:`${apiBase}/backoffice/pricequeue/pageable`,
    pricingProposalHistory:`${apiBase}/backoffice/pricequeue/proposal-history`,
    pricingProposalsJudgmentUser:`${apiBase}/backoffice/pricequeue/judgmentuser`,
    pricingProposalsJudgmentDetails: `${apiBase}/backoffice/pricequeue/download-file`,
    alterProposalStatusSerpro: `${apiBase}/backoffice/proposal-flow/back`,
    configApp: `${apiBase}/backoffice/config-app`,
    conciliator: `${apiBase}/backoffice/conciliator-company`,
    integrationTokenConfig: `${apiBase}/backoffice/integration-config`,
    topicConfig:`${apiBase}/backoffice/topic-config`,
    reports: `${apiBase}/backoffice/reports`,
    retryIntegration: `${apiBase}/backoffice/proposal/retry-integration`,
    exceededSlaTime: `${apiBase}/backoffice/proposal/exceeded-sla-time`,
    alterStatusBaseProduct: `${apiBase}/backoffice/proposal/base-products/status`,
    userRegistrationProfiles: `${apiBase}/backoffice/profile/users-registration`,
    alterStatusPaymentSplit: `${apiBase}/backoffice/proposal/payment-split/status`,
    faqCategory: `${apiBase}/backoffice/faq/category`,
    economicalGroupConvenience: `${apiBase}/backoffice/economic-group-conv`,
    progressiveDiscount: `${apiBase}/backoffice/progressive-discount`,
    accreditationSource: `${apiBase}/backoffice/accreditation-source`,
    operationsProposalDetailPosSitef: `${apiBase}/backoffice/operation-proposal/pos-sitef/details/`,
    threeDsConfiguration: `${apiBase}/backoffice/threeds-config`,
    workflow: `${apiBase}/backoffice/workflow`
  },
  institutionId: '00000007',
  institutionName: 'FIRST DATA',
  serviceContractId: 149,
  serviceContractName: 'Merchant Retail CX'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
