<h3>Usuários</h3>
<div fxLayout="row" fxLayoutAlign="left stretch">
  <div fxLayoutGap="20px" class="filter-container" *ngIf="hasWriteAccess">
    <div fxFlex="10" fxFlexAlign="center">
      <button class="fd-button" (click)="addUser()">
        <div class="d-flex  justify-content-center">
          <fa-icon icon='plus-circle' size="1x"></fa-icon> Novo usuário
        </div>
      </button>
    </div>
  </div>


  <div *ngIf="hasWriteAccess" fxFlex="17" fxFlexAlign="center">
    <button class="fd-button" (click)="uploadBlockList()" matTooltip="Bloquear usuários em lote">
      <div class="d-flex  justify-content-left">
        <fa-icon icon='user-lock' size="1x"></fa-icon> Bloquear
      </div>
    </button>
    <div fxFlex="10" fxFlexAlign="center">
      <fa-icon class="fd-icon" icon='download' size="1x" (click)="downloadTemplate($event)"
        method="downloadBlockUsersTemplate" fileName="bloquear_usuarios_template" matTooltip="Download do modelo de arquivo">
      </fa-icon>
    </div>
  </div>
  <div *ngIf="hasWriteAccess" fxFlex="17" fxFlexAlign="center">
    <button  class="fd-button" (click)="uploadImportUsers()" matTooltip="Importar usuários em lote">
      <div class="d-flex  justify-content-center">
        <fa-icon icon='user-lock' size="1x"></fa-icon> Importar
      </div>
    </button>
    <div fxFlex="10" fxFlexAlign="center">
      <fa-icon class="fd-icon" icon='download' size="1x" (click)="downloadTemplate($event)"
        method="downloadImportUsersTemplate" fileName="importar_usuarios_template" matTooltip="Download do modelo de arquivo">
      </fa-icon>
    </div>
  </div>
  <div fxFlex="12" fxFlexAlign="center">
    <button mat-button [matMenuTriggerFor]="export" class="fd-button--export"> Exportar
    </button>
    <mat-menu #export="matMenu">
      <button mat-menu-item (click)="exportUsers(xlsx)">
        <fa-icon icon="download" class="menu-icon"></fa-icon> XLSX
      </button>
    </mat-menu>
  </div>
</div>

<form [formGroup]="formGroup">
  <div #uploadBlockUserList>
    <ngx-mat-file-input *ngIf="hasWriteAccess" class="hiddenUpload" #uploadBlockUserList (change)="startUpload($event.target.files, $event)"
      name="upload-start" method="uploadBlockUsersList" accept="*/*"></ngx-mat-file-input>
  </div>

  <div #uploadImportUser>
    <ngx-mat-file-input *ngIf="hasWriteAccess" class="hiddenUpload" (change)="startUpload($event.target.files, $event)"
      name="upload-start" method="uploadImportUsers" accept="*/*"></ngx-mat-file-input>
  </div>

  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px" class="filter-container">
      <div fxFlex="50">
        <fd-select (loadItemsFn)="loadInstitutions()" [parentForm]="formGroup" [field]="fields.institution"
                   (selectionChange)="loadServiceContracts(formGroup.value.institution)">
        </fd-select>
      </div>
      <div fxFlex="50">
        <fd-select [parentForm]="formGroup" [field]="fields.serviceContract">
        </fd-select>
      </div>
      <div fxFlex="30">
        <fd-input [parentForm]="formGroup" [field]="fields.cpf1">
        </fd-input>
      </div>
      <div fxFlex="30">
        <fd-select [parentForm]="formGroup" [field]="fields.userType">
        </fd-select>
      </div>
      <div fxFlex="10" fxFlexAlign="center">
        <fd-button mat-flat-button [content]="'Buscar'" (trigger)="search()"></fd-button>
      </div>
    </div>
  </div>
</form>

<div class='list'>
  <app-list-users [dataSource]="dataSource" [totalItens]=[totalItens] (disableItem)="inactivateUser($event)"
                  (enableItem)="activateUser($event)" (editItem)="editUser($event)" (itemDetails)="viewUserDetails($event)"
                  (changePage)="changePage($event)" [page]="pageNumberFilter">
  </app-list-users>
</div>
