import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AccountModalityEnum } from './../../enums/account-modality.enum';
import { PrepaymentFrequencyPt } from '../../enums/prepayment-frequency-pt.enum';
import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {ProposalService} from '../../services/proposal.service';
import { ProgressiveDiscountService } from '../../services/progressive-discount.service';
import { AuthService } from '../../../shared/service/auth.service';
import { AdminRolesEnum } from '../../../shared/enums/admin-roles.enum';
import {LoadingService} from '../../../shared/service/loading.service';
import {finalize} from 'rxjs/operators';
import {BaseProductsStatusEnum} from '../../../shared/enums/base-products-status.enum';
import { registerLocaleData } from '@angular/common';
import pt from '@angular/common/locales/pt';
import * as moment from 'moment';
import { CreditAndRiskProposalNoteModel } from 'src/app/credit-risk-proposals/models/credit-risk-proposal-details.model';
import { OperationProposalService } from 'src/app/operations-proposals/services/operation-proposal.service';
import { ProgressiveDiscountModel } from 'src/app/shared/models/progressive-discount.model';
import { FdTreeDataNodeModel } from 'src/app/shared/components/fd-flat-nodes/models/fd-tree-data-node.model';
import { ProposalHistorySerproV2DTO } from 'src/app/shared/models/proposal-history-serpro-v2.model';
import { ProposalModel } from 'src/app/shared/models/proposal.model';
import { ConfigAppService } from 'src/app/config-app/service/config-app.service';
import { HttpErrorResponse } from '@angular/common/http';
import {PricingProposalDetailModel} from "../../../pricing-proposals/models/pricing-proposal-detail.model";

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss']
})
export class ProposalDetailComponent implements OnInit {

  proposal: any;
  progressiveDiscount: ProgressiveDiscountModel[] = new Array<ProgressiveDiscountModel>();
  public fields: FdFieldConfigs;
  public formGroups = new Array<FormGroup>();

  notAllowedSendingProductsBase = BaseProductsStatusEnum.NOT_ALLOWED_SENDING;
  allowedSendingProductsBase = BaseProductsStatusEnum.ALLOWED_SENDING;
  errorSendingProductsBase = BaseProductsStatusEnum.ERROR;
  successSendingProductsBase = BaseProductsStatusEnum.SUCCESS;
  creditAndRiskProposalNote: CreditAndRiskProposalNoteModel[];
  proposalHistorySerproV2: ProposalHistorySerproV2DTO[];

  hideShowAccount = false;
  decryptConfig;

  columnsToDisplay = ['proposalStatus', 'paymentStatus', 'data'];
  contact = new Contact();
  proposalHistorySerproNode = new Array<ProposalHistorySerproNode>()

  constructor(private dialogRef: MatDialogRef<ProposalDetailComponent>,
              @Inject(MAT_DIALOG_DATA) public proposalModel: ProposalModel,
              private loadingService: LoadingService,
              private operationProposalService: OperationProposalService,
              private service: ProposalService, private authService: AuthService,
              private progressiveDiscountService: ProgressiveDiscountService,
              private formBuilder: FormBuilder,private configAppService: ConfigAppService,
              ) { }

  ngOnInit() {
    registerLocaleData( pt );
    const observable = this.service.proposalDetail(this.proposalModel.id.toString());
    this.loadingService.show();
    observable.pipe(finalize(() => this.loadingService.hide()))
      .subscribe(p => {
      this.proposal = p;
      this.tableProgressiveDiscpunt()
      this.contactRefactor();
    });
    this.getProposalNote();
    this.getSerproValidationHistory(this.proposalModel.id.toString());
    this.shouldDecryptBankAccount();
  }

  shouldDecryptBankAccount(){
    this.configAppService.getConfigByDescription("SHOULD_DECRYPT_BANK_ACCOUNT_FOR_PROPOSAL_DETAIL")
      .subscribe(response => {
        this.decryptConfig = Number(response.value);
        this.hideShowAccount = Boolean(this.decryptConfig);
      });
  }

  getPriceJudgmentApproval(approverTypeId: number): string {
    if (this.proposal.priceJudgment.length == 0) {
       return 'Não se aplica';
    }
    const judgment = this.proposal.priceJudgment?.find(
      j => j.approverTypeId === approverTypeId
    );
    if (judgment?.judgment === true) {
      return 'Aprovado';
    } else if (judgment?.judgment === false) {
      return 'Recusado';
    } else {
      if (this.proposal.proposalStatusId == "35") {
         return 'Aguardando';
      }
      return 'Não se aplica';
    }
  }

  tableProgressiveDiscpunt(): void {
    let economicGroupConvenienceId = this.proposal.economicGroupConvenienceId;

    if(economicGroupConvenienceId){
      this.fields = this.createFields();
      this.progressiveDiscountService.progressiveDiscount(this.proposalModel.id.toString())
      .subscribe(pd => {
        this.formGroups = this.buildProgressiveDiscountFormGroupArray(pd);
      })
    }
  }

  private createFields(): FdFieldConfigs {
    return {
      initialRange: {
        label: 'Range inicial de faturamento',
        controlName: 'initialRange',
        valueFormat: 'number',
        readonly: true,
      },
      finalRange: {
        label: 'Range final de faturamento',
        controlName: 'finalRange',
        readonly: true,
      },
      discountPercentage: {
        label: '% de Desconto',
        controlName: 'discountPercentage',
        isPercent: true,
        readonly: true,
      }
    };
  }

  private buildProgressiveDiscountFormGroupArray(progressiveDiscounts: Array<ProgressiveDiscountModel>): Array<FormGroup> {
    return progressiveDiscounts.map(model => this.convertProgressiveDiscountModelToFormGroup(model));
  }

  private convertProgressiveDiscountModelToFormGroup(progressiveDiscount: ProgressiveDiscountModel): FormGroup {
    const formGroup = this.buildProgressiveDiscountFormGroup();
    formGroup.patchValue(progressiveDiscount);
    formGroup.get('discountPercentage').setValue(progressiveDiscount.discountPercentage * 100);
    return formGroup;
  }

  private buildProgressiveDiscountFormGroup(): FormGroup {
    return this.formBuilder.group({
      id: null,
      initialRange: [0],
      finalRange: [0],
      discountPercentage: [0],
      edited: false
    });
  }

  contactRefactor() {
      let cont = this.proposal.contactDetailedDTO;

      this.contact = new Contact();

      if(cont[0]!=null)
      {

        this.contact.email = cont[0].email;

        if(cont[0].fixedDdd && cont[0].fixedNumber)
        {
          this.contact.phoneNumber1 = cont[0].fixedDdd + cont[0].fixedNumber;
        }
        else
        if(!cont[0].fixedDdd && !cont[0].fixedNumber)
        {
          this.contact.phoneNumber1 = "";
        }
        else
        if(!cont[0].fixedDdd && cont[0].fixedNumber)
        {
          this.contact.phoneNumber1 = cont[0].fixedNumber;
        }
        else
        if(cont[0].fixedDdd && !cont[0].fixedNumber)
        {
          this.contact.phoneNumber1 = "";
        }




        if(cont[0].mobileDdd && cont[0].mobileNumber)
        {
          this.contact.mobileNumber = cont[0].mobileDdd + cont[0].mobileNumber;
        }
        else
        if(!cont[0].mobileDdd && !cont[0].mobileNumber)
        {
          this.contact.mobileNumber = "";
        }
        else
        if(!cont[0].mobileDdd && cont[0].mobileNumber)
        {
          this.contact.mobileNumber = cont[0].mobileNumber;
        }
        else
        if(cont[0].mobileDdd && !cont[0].mobileNumber)
        {
          this.contact.mobileNumber = "";
        }



        if(cont[0].lastName && cont[0].firstName)
        {
          this.contact.name = cont[0].firstName + ' ' + cont[0].lastName;
        }
        else
        if(!cont[0].lastName && !cont[0].firstName)
        {
          this.contact.name = "";
        }
        else
        if(!cont[0].lastName && cont[0].firstName)
        {
          this.contact.name = cont[0].firstName;
        }
        else
        if(cont[0].lastName && !cont[0].firstName)
        {
          this.contact.name = cont[0].lastName;
        }

      }

      if(cont[1]!=null)
      {
        if(cont[1].fixedDdd && cont[1].fixedNumber)
        {
          this.contact.phoneNumber1 = cont[1].fixedDdd + cont[1].fixedNumber;
        }
        else
        if(!cont[1].fixedDdd && !cont[1].fixedNumber)
        {
          this.contact.phoneNumber1 = "";
        }
        else
        if(!cont[1].fixedDdd && cont[1].fixedNumber)
        {
          this.contact.phoneNumber1 = cont[1].fixedNumber;
        }
        else
        if(cont[1].fixedDdd && !cont[1].fixedNumber)
        {
          this.contact.phoneNumber1 = "";
        }
      }
  }

  canShowEndAutoPrepayment(dateString: string) {
    const date = moment(dateString, 'DD/MM/YYYY').toDate();
    return date <= new Date();
  }

  getAutoprepaymentFrequencyPtDescription(value: string) {
    return PrepaymentFrequencyPt[value];
  }

  getAccountModalityDescription(value: string) {
    return AccountModalityEnum[value];
  }

  isAnticipationExternal(prepaymentEnable: boolean, prepaymentMerchantType: boolean) {
    return prepaymentEnable && !prepaymentMerchantType;
  }


  showLogSerproError(): boolean {
    let userRole = AdminRolesEnum.LOG_SERPRO;
    let roles: AdminRolesEnum[] = [];
    roles.push(userRole);
    return this.authService.isUserInRoles(roles);
  }

  isInvoice(licenseTypeName: LicenseTypeName): Boolean{
    return licenseTypeName === LicenseTypeName.INVOICE;
  }

  isProfessionalCouncil(licenseTypeName: LicenseTypeName): Boolean{
    return licenseTypeName === LicenseTypeName.PROFESSIONAL_COUNCIL;
  }

  isOperatingLicense(licenseTypeName: LicenseTypeName): Boolean{
    return licenseTypeName === LicenseTypeName.OPERATING_LICENSE;
  }

  getStatusTerminal(status: string) {
    if (!status) {
      return 'Não foi possível buscar o status do terminal';
    }

    if (status === 'ACTIVE') {
      return 'Ativo';
    }
    if (status === 'INACTIVE') {
      return 'Inativo';
    }
  }
  getProposalNote() {
    this.operationProposalService.getProposalNote(this.proposalModel.proposalNumber)
      .subscribe(response => {
        this.creditAndRiskProposalNote = response;
      });
  }

  getDeliveryType(type) {
   return type == 'CONVENTIONAL' ? 'CONVENCIONAL' : type == 'IN_AGENCY' ? 'Na Agência' : 'PRONTA ENTREGA';
  }

  getSerproValidationHistory(idProposal: string){
    return this.service.searchSerproValidationHistory(idProposal)
    .subscribe(response => {
      this.buildProposalHistorySerproNode(response);
    });
  }

  buildProposalHistorySerproNode(proposalHistorySerproV2: ProposalHistorySerproV2DTO[]){
    proposalHistorySerproV2.forEach(historySerpro => {
      const fieldsValidated = historySerpro.fieldsValidated.map(fieldValidated => {

        fieldValidated.validationDate = fieldValidated.validationDate ? moment(fieldValidated.validationDate).format("DD/MM/YYYY HH:mm:ss") : "";

        const entries = Object.entries(fieldValidated).slice(1);
        const children = entries.map(([attrName, attrValue]) => {return {name: `${attrName}: ${attrValue}`, children: []}});
        return {name: `${fieldValidated.fieldName}`, children: children}
      })

      this.proposalHistorySerproNode.push({
        name: `Endpoint: ${historySerpro.endpoint} `,
        children: historySerpro.fieldsValidated.length > 0 ? [{
          name: 'Campos Validados',
          children: fieldsValidated
        }] : []
      });
    });
  };

}

export enum LicenseTypeName {
  INVOICE = 'INVOICE',
  OPERATING_LICENSE = 'OPERATING_LICENSE',
  PROFESSIONAL_COUNCIL ='PROFESSIONAL_COUNCIL'
}

export class Contact
{
  phoneNumber1?: string;
  phoneNumber2?: string;
  mobileNumber?: string;
  email?: string;
  name?: string;
}


  export class  ProposalHistorySerproNode extends FdTreeDataNodeModel{
  }
