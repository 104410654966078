import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { PriceQueueService } from 'src/app/pricing-proposals/services/price-queue.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { LoadingService } from 'src/app/shared/service/loading.service';

@Component({
  selector: 'app-pricing-proposals-detail-table',
  templateUrl: './pricing-proposals-detail-table.component.html',
  styleUrls: ['./pricing-proposals-detail-table.component.scss'],
})
export class PricingProposalsDetailTableComponent implements OnInit{

  @Input() dataSource: MatTableDataSource<any>;
  @Input() displayedColumns: string[];
  @Input() approverTypes: string[];
  displayedColumnsPriceFeeInternal: string[];
  panelOpenState = false;
  userApproverType: string [] = [];

  constructor(private readonly authService: AuthService,
    private readonly priceQueueService: PriceQueueService,
    private readonly loadingService: LoadingService) {}

  ngOnInit(): void {
    const userData = this.authService.getUserData();
    let idPriceJudgment = this.dataSource.data[0].idPriceJudgment;
    if(idPriceJudgment) {
      this.loadingService.show()

      this.priceQueueService.getUserApproverType(userData.sub, idPriceJudgment)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(res => {
        this.userApproverType = res;
      }, () => this.loadingService.hide());

    }
  }

  renameApproverTypeDescription(value: string): string
  {
    switch (value) {
      case 'MANAGER': return 'GERENTE';
      case 'DIRECTOR': return 'DIRETOR';
      case 'EXECUTIVE DIRECTOR': return 'DIRETOR EXECUTIVO';
      default: return value;
    }
  }

  getFilteredDataSource(type: string) {
    return new MatTableDataSource(this.dataSource.data.filter(item => item.approverTypeDescription === type))
  }

  setOpen(type: string) {
    if(this.userApproverType.includes(type)){
      return true;
    }
  }
}
