export enum AdminRolesEnum {
    MONITORING = 'MONITORING',
    PRICING_READ = 'PRICING_READ',
    PRICING_WRITE = 'PRICING_WRITE',
    USERS_READ = 'USERS_READ',
    USERS_VENDOR_WRITE = 'USERS_VENDOR_WRITE',
    USERS_SUPERVISOR_WRITE = 'USERS_SUPERVISOR_WRITE',
    USERS_ADMIN_WRITE = 'USERS_ADMIN_WRITE',
    CONFIGURATION_READ = 'CONFIGURATION_READ',
    CONFIGURATION_WRITE = 'CONFIGURATION_WRITE',
    ALTER_PROPOSAL_STATUS = 'ALTER_PROPOSAL_STATUS',
    COMPLIANCE_PROPOSAL = 'COMPLIANCE_PROPOSAL',
    CNAE_CLASSIFICATION_READ = 'CNAE_CLASSIFICATION_READ',
    CNAE_CLASSIFICATION_WRITE = 'CNAE_CLASSIFICATION_WRITE',
    CNAE_CREDIT_RISK_READ = 'CNAE_CREDIT_RISK_READ',
    CNAE_CREDIT_RISK_WRITE = 'CNAE_CREDIT_RISK_WRITE',
    CONFIG_ACCREDITATION_FLOW_READ = 'CONFIG_ACCREDITATION_FLOW_READ',
    CONFIG_ACCREDITATION_FLOW_WRITE = 'CONFIG_ACCREDITATION_FLOW_WRITE',
    OPERATION_PROPOSAL = 'OPERATION_PROPOSAL',
    CREDIT_AND_RISK_ANALYSIS = 'CREDIT_AND_RISK_ANALYSIS',
    BANK_BRANCH_READ_AND_WRITE = 'BANK_BRANCH_READ_AND_WRITE',
    EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_READ = 'EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_READ',
    EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_WRITE = 'EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_WRITE',
    SCHEDULED_MAINTENANCE = 'SCHEDULED_MAINTENANCE',
    PROFESSIONAL_LICENSE_CONFIG = 'PROFESSIONAL_LICENSE_CONFIG',
    RESEND_FIRST_ACCESS_EMAIL = 'RESEND_FIRST_ACCESS_EMAIL',
    PRICING_APPROVER = 'PRICING_APPROVER',
    SERPRO_LOCK_EVALUATOR = 'SERPRO_LOCK_EVALUATOR',
    INTEGRATION_CONFIG = 'INTEGRATION_CONFIG',
    TOPIC_CONFIG = 'TOPIC_CONFIG',
    APPLICATION_CONFIGURATION = 'APPLICATION_CONFIGURATION',
    CONCILIATOR= 'CONCILIATOR',
    MANAGE_CONCILIATOR = 'MANAGE_CONCILIATOR',
    REPORTS = 'REPORTS',
    OPTIONAL_SUPERVISOR_HIERARCHY =  'OPTIONAL_SUPERVISOR_HIERARCHY',
    CONCILIATOR_BACKOFFICE_USER_MASTER = 'CONCILIATOR_BACKOFFICE_USER_MASTER',
    PRICING_APPROVER_APPROVE = 'PRICING_APPROVER_APPROVE',
    PRICING_APPROVER_REPROVE = 'PRICING_APPROVER_REPROVE',
    LOG_SERPRO = 'LOG_SERPRO',
    FAQ_CREATION = 'FAQ_CREATION',
    ACCREDITATION_CHANNELS = 'ACCREDITATION_CHANNELS',
    ECONOMIC_GROUP_CONV_CREATION = 'ECONOMIC_GROUP_CONV_CREATION',
    PROGRESS_TABLE_DISCOUNT_CREATION = 'PROGRESS_TABLE_DISCOUNT_CREATION',
    RESELLER_CLOVER_CONFIGURATION_READ = 'RESELLER_CLOVER_CONFIGURATION_READ',
    RESELLER_CLOVER_CONFIGURATION_WRITE = 'RESELLER_CLOVER_CONFIGURATION_WRITE',
    PROFILE_READ = 'PROFILE_READ',
    PROFILE_WRITE = 'PROFILE_WRITE',

    // SERVICE CONTRACT Role configurada em auth.service.setSessionInformation();
    SERVICE_CONTRACT_149 = 'SERVICE_CONTRACT_149'
}
