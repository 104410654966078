import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { WorkflowComponent } from "./workflow.component";
import { FdFormComponentsModule } from "../shared/fd-form-components/fd-form-components.module";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MatPaginatorModule } from "@angular/material/paginator";

@NgModule({
  declarations: [WorkflowComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FdFormComponentsModule,
    FormsModule,
    FlexLayoutModule,
    MatTableModule,
    MatSlideToggleModule,
    FontAwesomeModule,
    MatPaginatorModule
  ]
})
export class WorkflowModule { }
