import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DialogService } from '../../service/dialog.service';
import { Messages } from '../../messages/messages';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'fd-image-uploader',
  templateUrl: './fd-image-uploader.component.html',
  styleUrls: ['./fd-image-uploader.component.scss']
})
export class FdImageUploaderComponent implements OnInit {
  selectedFile: File = null;
  @Input() imageUrl: string | ArrayBuffer | SafeResourceUrl = null;
  @ViewChild('inputFile') fileInput: ElementRef | undefined;
  @Output() onImageSelected = new EventEmitter<File>();
  @Output() onImageDeleted = new EventEmitter<null>();
  constructor(private readonly dialogService: DialogService) { }

  ngOnInit(): void {
  }       
  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.onImageSelected.emit(file);
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageUrl = e.target?.result;
      };
      reader.readAsDataURL(file);
    }
  }
  
  deleteImage() {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_IMAGE, () => {
      this.resetFileInput();
      this.onImageDeleted.emit();
    });
  }

  resetFileInput() {
    this.imageUrl = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

}
