import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { UserTypeEnum } from 'src/app/shared/enums/user-type.enum';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { PasswordModel } from 'src/app/shared/models/password.model';
import { ProfileModel } from 'src/app/shared/models/profile.model';
import { CreateUserByEmailResponseModel } from 'src/app/shared/models/response/create-user-by-email-response.model';
import { ToggleUserStatusModel } from 'src/app/shared/models/toggle-user-status.model';
import { PageableUserModel, UserEditModel, UserListModel, UserModel, UserSearchModel } from 'src/app/shared/models/user.model';
import { environment } from 'src/environments/environment';
import { BaseResponseModel } from '../../shared/models/response/base-response-model';
import { CreateUserResponseModel } from '../components/models/create-user-response.model';
import { ExistingUserModel } from '../components/models/existing-user-model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private cache$: Observable<ProfileModel[]>;

  constructor(private http: HttpClient) { }

  getAllUsers(page: number, size: number, cpf?: string, institution?: string, serviceContract?: number, userType?: UserTypeEnum): Observable<PageableUserModel> {
    const cpfQuery = cpf ? `&cpf=${cpf}` : '';
    const institutionQuery = institution ? `&institution=${institution}` : '';
    const serviceContractQuery = serviceContract ? `&service-contract=${serviceContract}` : '';
    const userTypeQuery = userType ? `&user-type=${userType}` : '';
    return this.http.get<any>(`${environment.apiUrls.user}?page=${page}&size=${size}${cpfQuery}${institutionQuery}${serviceContractQuery}${userTypeQuery}`).pipe(map(x => this.mapToListUserModels(x)));
  }

  searchUsers(page: number, size: number, data: UserSearchModel): Observable<PageableUserModel> {
    return this.http.post<any>(`${environment.apiUrls.user}/users?page=${page}&size=${size}`, data).pipe(map(x => this.mapToListUserModels(x)));
  }

  getUserDetails(cpf: string) {
    return this.http.get<any>(`${environment.apiUrls.user}/detail/${cpf}`).pipe(map(x => this.parseToUserModel(x)));
  }

  exportUsers(serviceContract: string, institution: string, cpf: string, userType: string) {
    let filter = serviceContract ? `&service-contract=${serviceContract}` : '';
    filter = institution ? `${filter}&institution=${institution}` : filter;
    filter = cpf ? `${filter}&cpf=${cpf}` : filter;
    filter = userType ? `${filter}&user-type=${userType}` : filter;
    return this.http.get<any>(`${environment.apiUrls.user}/export-users?1=1${filter}`, { responseType: 'blob' as 'json' });
  }

  exportUsersReportData(serviceContract: string, institution: string, cpf: string, userType: string) {
    let filter = serviceContract ? `&service-contract=${serviceContract}` : '';
    filter = institution ? `${filter}&institution=${institution}` : filter;
    filter = cpf ? `${filter}&cpf=${cpf}` : filter;
    filter = userType ? `${filter}&user-type=${userType}` : filter;
    return this.http.get<any>(`${environment.apiUrls.user}/report/data?1=1${filter}`);
  }

  getAllProfiles(useCache: boolean = false): Observable<ProfileModel[]> {
    return useCache ? this.getProfilesFromCache() : this.fetchAllProfiles();
  }

  private getProfilesFromCache(): Observable<ProfileModel[]> {
    if (!this.cache$) {
      this.cache$ = this.fetchAllProfiles().pipe(
        shareReplay({ bufferSize: 1, refCount: true })
      );
    }
    return this.cache$;
  }

  private fetchAllProfiles(): Observable<ProfileModel[]> {
    return this.http.get<ProfileModel[]>(`${environment.apiUrls.userRegistrationProfiles}`);
  }

  clearProfilesCache(): void {
    this.cache$ = null;
  }

  getExistingUserInformation(cpf: string): Observable<ExistingUserModel> {
    return this.http.get<ExistingUserModel>(`${environment.apiUrls.user}/search-user?cpf=${cpf}`);
  }

  setUserStatus(data: ToggleUserStatusModel) {
    return this.http.post<UserModel>(`${environment.apiUrls.user}/status`, data);
  }

  createUserByForm(data: UserModel, tokenChannel: string): Observable<CreateUserResponseModel> {
    return this.http.post<CreateUserResponseModel>(`${environment.apiUrls.user}?token-channel=${tokenChannel}`, data);
  }

  resendToken(data: UserModel, tokenChannel: string) {
    return this.http.post<UserModel>(`${environment.apiUrls.user}/resend-token?token-channel=${tokenChannel}`, data);
  }

  createPassword(data: PasswordModel) {
    return this.http.post<UserModel>(`${environment.apiUrls.user}/password`, data);
  }

  validateUserReceivedTokenByFormCreation(receivedUserToken: string, cpf: string) {
    const headers = new HttpHeaders({
      'new-access-user': cpf
    })
    return this.http.post<UserModel>(`${environment.apiUrls.user}/token/${receivedUserToken}`, {}, { headers });
  }

  createUserByEmail(data: UserModel) {
    return this.http.post<CreateUserByEmailResponseModel>(`${environment.apiUrls.userEmail}/create-user`, data);
  }

  updateUser(data: UserEditModel) {
    return this.http.post<any>(`${environment.apiUrls.user}/edit-user`, data);
  }

  sendPasswordEmail(data: UserModel) {
    return this.http.post<any>(`${environment.apiUrls.userEmail}/send-password-email`, data);
  }

  uploadBlockUsersList(file: any) {
    return this.http.post<any>(`${environment.apiUrls.userBatch}/disable`, file, { responseType: 'blob' as 'json' });
  }

  downloadBlockUsersTemplate() {
    return this.http.post<any>(`${environment.apiUrls.userBatch}/disable/template`, { }, { responseType: 'blob' as 'json' });
  }

  downloadImportUsersTemplate() {
    return this.http.get<any>(`${environment.apiUrls.userBulk}/create/template`, { responseType: 'blob' as 'json' });
  }

  uploadImportUsers(file: any) {
    return this.http.post<any>(`${environment.apiUrls.userBulk}/create`, file, { responseType: 'blob' as 'json' });
  }

  getConciliatorName(cpfCnpj: string): Observable<BaseResponseModel<string>> {
    return this.http.get<BaseResponseModel<string>>(`${environment.apiUrls.user}/${cpfCnpj}/conciliator/name`);
  }

  private parseToUserModel(returnedUser: any) {
    const user = new UserModel();

    user.name = returnedUser.name;
    user.cpfCnpj = returnedUser.cpfCnpj,
    user.mobileNumber = returnedUser.mobileNumber;
    user.email = returnedUser.email;
    user.type = returnedUser.type;
    user.institution = returnedUser.userInformation.map(x => x.institution).length > 1 ? returnedUser.userInformation
        .map(x => x.institution) : returnedUser.userInformation.map(x => x.institution)[0];
    user.serviceContracts = returnedUser.userInformation.map((y): Item => ({ label: y.serviceContract
      .toString(), value: y.serviceContract }));
    user.userInformation = returnedUser.userInformation;
    user.channelType = returnedUser.userInformation.map(x => x.channelType)[0],
    user.channel = returnedUser.userInformation.map(x => x.channel)[0];
    user.isEnabled = returnedUser.isEnabled;
    user.profileId = returnedUser.profile ? returnedUser.profile.id : null;
    user.profile = returnedUser.profile;
    user.fullAccess = returnedUser.fullAccess;
    user.passwordSet = returnedUser.passwordSet;
    user.conciliatorId = returnedUser.conciliatorId;
    user.pExpirationDate = returnedUser.passwordExpirationDate;
    user.lastUserAccessDate = returnedUser.lastUserAccessDate;
    user.createdDate = returnedUser.createdDate;
    user.requestCode = returnedUser.requestCode;
    user.workday = returnedUser.workday;

    return user;
  }

  private mapToListUserModels(data: any) {
    const users = data.response.map((x: UserListModel) => ({
      name: x.name,
      cpfCnpj: x.cpfCnpj,
      mobileNumber: x.mobileNumber,
      email: x.email,
      type: x.type,
      isEnabled: x.isEnabled,
      roles: x.roles,
      profileId: x.profile ? x.profile.id : null,
      profile: x.profile,
      fullAccess: x.fullAccess,
      passwordSet: x.passwordSet,
      conciliatorId: x.conciliatorId
    }));

    const pageable: PageableUserModel = {
      totalPages: data.totalPages,
      size: data.size,
      totalItens: data.totalItens,
      users
    };

    return pageable;
  }
}
