<div *ngIf="userData">
      <h4 class="proposal-detail-title">Dados do usuário</h4>
      <div class="proposal-detail">
        <span *ngIf="userData.name">
          <strong>Nome: </strong> {{userData.name}}<br>
        </span>
        <span *ngIf="userData.cpfCnpj">
          <strong>CPF: </strong> {{userData.cpfCnpj | cpfCnpj}}<br>
        </span>
        <span *ngIf="userData.mobileNumber">
          <strong>Celular: </strong> {{userData.mobileNumber | phone}}<br>
        </span>
        <span *ngIf="userData.email">
          <strong>E-mail: </strong> {{userData.email}}<br>
        </span>

        <span *ngIf="userData.type">
          <strong>Tipo de usuário: </strong> {{getTypeDescription(userData.type)}}<br>
        </span>
        <span *ngIf="userData.isEnabled">
          <strong>Status: </strong> {{userData.isEnabled ? 'Habilitado' : 'Desabilitado'}}<br>
        </span>

        <span *ngIf="userData.createdDate">
          <strong>Data Cadastro: </strong> {{ userData.createdDate | date:'dd/MM/yyyy' }}<br>
        </span>

        <span *ngIf="userData.pExpirationDate">
          <strong>Data Expiração: </strong> {{formatExpirationDate()}}<br>
        </span>

        <span *ngIf="userData.lastUserAccessDate">
          <strong>Data Último Acesso: </strong> {{userData.lastUserAccessDate | date:'dd/MM/yyyy'}}<br>
        </span>

        <span *ngIf="userData.roles">
          <strong>Permissões: </strong> {{userData.roles}}<br>
        </span>
        <span *ngIf="userData.profile">
          <strong>Perfil: </strong> {{userData.profile.description}}<br>
        </span>

        <span *ngIf="userData.institution && !isConciliator">
          <strong>Instituição: </strong> {{institutionList}}<br>
        </span>
        <span *ngIf="userData.serviceContracts && !isConciliator">
          <strong>Service Contract: </strong> {{serviceContractList}}<br>
        </span>
        <span *ngIf="userData.channelType">
          <strong>Tipo de canal: </strong> {{userData.channelType}}<br>
        </span>
        <span *ngIf="userData.channel">
          <strong>Canal: </strong> {{userData.channel}}<br>
        </span>

        <span *ngIf="userData.requestCode">
          <strong>Código Requisição/Observação: </strong> {{userData.requestCode}}<br>
        </span>

        <span *ngIf="shouldViewWorkdayField() && userData.workday">
          <strong>Workday: </strong> {{userData.workday}}<br>
        </span>

        <span *ngIf="isConciliator()">
          <strong>Conciliador(a): </strong> {{conciliatorName}}<br>
        </span>


        <ng-container *ngIf="hasUserHierarchyInformation()">
          <h4 class="proposal-detail-title">Sub-canais do usuário</h4>
          <div *ngFor="let item of userHierarchyInformation">
            <span *ngIf="item.subChannel">
              <strong>Sub-canal: </strong> {{item.subChannel}}<br>
            </span>
            <span *ngIf="item.agentId">
              <strong>Agente: </strong> {{item.agentId}}<br>
            </span>
            <br>
          </div>
        </ng-container><br>
        
        <span *ngIf="canResendEmail()">
          <a class="token-resend" (click)="sendEmailPassword()">Enviar e-mail de
              redefinição de senha</a>
        </span><br>
        <fd-button mat-button fdSecondaryLarge [content]="'Sair'" (trigger)="close()" > </fd-button>
      </div>
</div>
