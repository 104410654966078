import { Routes } from 'src/app/shared/routing/routes';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import {
  faBars,
  faFile,
  faCog,
  faTools,
  faTasks,
  faMoneyCheckAlt,
  faUsers,
  faChartBar,
  faClipboardCheck,
  faSearchDollar,
  faDollarSign,
  faMoneyCheck,
  faCheck,
  faUserTie

} from '@fortawesome/free-solid-svg-icons';

const searchProposals = {
  text: 'Monitoramento de propostas',
  link: Routes.SEARCH_PROPOSALS,
  mainMenuLinkRole: [AdminRolesEnum.MONITORING],
  allowedRoles: [AdminRolesEnum.MONITORING],
  icon: faBars,
  hasOptions: true,
  optionsName: '',
  options:[]
};

const reports = {
  text: 'Relatórios',
  link: Routes.REPORTS,
  mainMenuLinkRole: [AdminRolesEnum.REPORTS],
  allowedRoles: [AdminRolesEnum.REPORTS],
  icon: faFile,
  hasOptions: true,
  optionsName: '',
  options:[]
};

const serviceType = {
  text: 'Configurações',
  link: Routes.SERVICE_CONFIG,
  mainMenuLinkRole: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE],
  allowedRoles: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE,
    AdminRolesEnum.INTEGRATION_CONFIG,AdminRolesEnum.TOPIC_CONFIG],
  icon: faCog,
  hasOptions: true,
  optionsName: '',
  options: [
    {
      text: 'Habilitar Tecnologias',
      allowedRoles: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE],
      link: Routes.SERVICE_CONFIG,
      icon: faTools,
    },
    {
      text: 'Definir Configurações por Service Contract',
      allowedRoles: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE],
      link: Routes.CONFIGURATION_SETUP,
      icon: faCog,
    },
    {
      text: 'Checklist de configurações',
      allowedRoles: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE],
      link: Routes.CONFIGURATION_CHECKLIST,
      icon: faTasks,
    },
    {
      text: 'Gateway de pagamento',
      allowedRoles: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE],
      link: Routes.PAYMENT_GATEWAY,
      icon: faMoneyCheckAlt,
    },
    {
      text: 'Configuração API Bankworks',
      allowedRoles: [AdminRolesEnum.CONFIG_ACCREDITATION_FLOW_READ, AdminRolesEnum.CONFIG_ACCREDITATION_FLOW_WRITE],
      link: Routes.BW_SERVICE_CONTRACT_CONFIG,
      icon: faCog,
    },
    {
      text: 'Disponibilidade Integração de API(s)',
      allowedRoles: [AdminRolesEnum.SCHEDULED_MAINTENANCE],
      link: Routes.APIS_STATUS,
      icon: faTools,
    },
    {
      text: 'Definir configuração da Aplicação',
      allowedRoles: [AdminRolesEnum.APPLICATION_CONFIGURATION],
      link: Routes.CONFIGURATION_APPLICATION,
      icon: faCog,
    },
    {
      text: 'Configuração de Tokens de Integração',
      allowedRoles: [AdminRolesEnum.INTEGRATION_CONFIG],
      link: Routes.INTEGRATION_TOKEN_CONFIGURATION,
      icon: faTools,
    },
    {
      text: 'Configuração Tópicos Kafka',
      allowedRoles: [AdminRolesEnum.TOPIC_CONFIG],
      link: Routes.TOPIC_CONFIGURATION,
      icon: faTools,
    },
    {
      text: 'FAQ - Categorias',
      allowedRoles: [AdminRolesEnum.FAQ_CREATION],
      link: Routes.FAQ_CATEGORIES,
      icon: 'tools',
    },
    {
      text: 'FAQ - Configuração',
      allowedRoles: [AdminRolesEnum.FAQ_CREATION],
      link: Routes.FAQ_CONFIGURATION,
      icon: faCog,
    },
    {
      text: "Configuração Motor Crédito e Risco",
      allowedRoles: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE],
      link: Routes.FEATURE_TOGGLE,
      icon: faTools,
    },
    {
      text: "Configuração 3DS",
      allowedRoles: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE],
      link: Routes.THREE_DS_CONFIGURATION,
      icon: faTools,
    },
    {
      text: "Configuração Simulador",
      allowedRoles: [AdminRolesEnum.SERVICE_CONTRACT_149],
      link: Routes.CONFIGURATION_SIMULATOR,
      icon: faTools,
    },
    {
      text: "Workflow",
      allowedRoles: [AdminRolesEnum.CONFIGURATION_READ, AdminRolesEnum.CONFIGURATION_WRITE],
      link: Routes.WORKFLOW,
      icon: faTools,
    }
  ]
};

const users = {
  text: 'Usuários',
  link: Routes.USERS,
  mainMenuLinkRole: [AdminRolesEnum.CREDIT_AND_RISK_ANALYSIS],
  allowedRoles: [AdminRolesEnum.USERS_READ, AdminRolesEnum.USERS_ADMIN_WRITE, AdminRolesEnum.USERS_VENDOR_WRITE],
  icon: faUsers,
  hasOptions: true,
  optionsName: '',
  options: [
    {
      text: 'Usuários',
      allowedRoles: [AdminRolesEnum.USERS_READ, AdminRolesEnum.USERS_ADMIN_WRITE, AdminRolesEnum.USERS_VENDOR_WRITE],
      link: Routes.USERS,
      icon: faUsers,
    },
    {
      text: 'Perfis',
      allowedRoles: [AdminRolesEnum.PROFILE_READ, AdminRolesEnum.PROFILE_WRITE],
      link: Routes.PROFILES,
      icon: faChartBar,
    }
  ]
};


const pricePremiumUf = {
  text: 'Preço',
  link: Routes.PRICE_CAMPAIGN,
  mainMenuLinkRole: [AdminRolesEnum.PRICING_READ, AdminRolesEnum.PRICING_WRITE],
  allowedRoles: [AdminRolesEnum.PRICING_READ, AdminRolesEnum.PRICING_WRITE, AdminRolesEnum.PRICING_APPROVER],
  icon: faDollarSign,
  hasOptions: true,
  optionsName: 'pricePremiumUf',
  options: [

    {
      text: 'Campanha',
      allowedRoles: [AdminRolesEnum.PRICING_READ, AdminRolesEnum.PRICING_WRITE],
      link: Routes.PRICE_CAMPAIGN,
      icon: faTasks,
    },
    {
      text: 'Premium por UF',
      allowedRoles: [AdminRolesEnum.PRICING_READ, AdminRolesEnum.PRICING_WRITE],
      link: Routes.PRICE_PREMIUM_UF,
      icon: faChartBar,
    },
    {
      text: 'Grupos econômicos',
      allowedRoles: [AdminRolesEnum.PRICING_READ, AdminRolesEnum.PRICING_WRITE],
      link: Routes.ECONOMICAL_GROUP,
      icon: faUserTie,
    },
    {
      text: 'Configuração de checagem de preços',
      allowedRoles: [AdminRolesEnum.PRICING_READ, AdminRolesEnum.PRICING_WRITE],
      link: Routes.PRICE_CHECK_CONFIG,
      icon: faMoneyCheck,
    },
    {
      text: 'Aprovações',
      allowedRoles: [AdminRolesEnum.PRICING_APPROVER],
      icon: faMoneyCheckAlt,
      hasOptions: false,
      link: Routes.PRICING_PROPOSALS,
    },
    // {
    //   text: 'Grupo Econômico por Conveniência',
    //   allowedRoles: [AdminRolesEnum.ECONOMIC_GROUP_CONV_CREATION],
    //   link: Routes.ECONOMICAL_GROUP_CONVENIENCE,
    //   icon: 'user-tie',
    // },
  ]
};


const compliance = {
  text: 'Compliance',
  link: Routes.CNAE_COMPLIANCE_REGISTER,
  mainMenuLinkRole: [AdminRolesEnum.CNAE_CLASSIFICATION_READ, AdminRolesEnum.CNAE_CLASSIFICATION_WRITE],
  allowedRoles: [AdminRolesEnum.COMPLIANCE_PROPOSAL, AdminRolesEnum.EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_READ, AdminRolesEnum.EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_WRITE, AdminRolesEnum.CNAE_CLASSIFICATION_READ, AdminRolesEnum.CNAE_CLASSIFICATION_WRITE],
  icon: faClipboardCheck,
  hasOptions: true,
  optionsName: 'compliance',
  options: [
    {
      text: "Classificação de CNAE's",
      allowedRoles: [AdminRolesEnum.CNAE_CLASSIFICATION_READ, AdminRolesEnum.CNAE_CLASSIFICATION_WRITE],
      link: Routes.CNAE_COMPLIANCE_REGISTER,
      icon: faClipboardCheck,
    },
    {
      text: "Aprovação de Compliance",
      allowedRoles: [AdminRolesEnum.COMPLIANCE_PROPOSAL],
      link: Routes.COMPLIANCE_PROPOSAL_LIST,
      icon: faTasks,
    },
  ]
};

const operationsProposals = {
  text: 'Ilhas Especiais',
  link: Routes.OPERATIONS_PROPOSALS,
  mainMenuLinkRole: [AdminRolesEnum.OPERATION_PROPOSAL],
  allowedRoles: [AdminRolesEnum.OPERATION_PROPOSAL, AdminRolesEnum.CONCILIATOR, AdminRolesEnum.MANAGE_CONCILIATOR],
  icon: faUsers,
  hasOptions: true,
  optionsName: 'operations-proposals',
  options: [
    {
      text: "Aprovação de ilhas especiais",
      allowedRoles: [AdminRolesEnum.OPERATION_PROPOSAL],
      link: Routes.OPERATIONS_PROPOSALS,
      icon: faUsers
    },
    {
      text: 'Empresas Conciliadoras',
      link: Routes.CONCILIATORS,
      allowedRoles: [AdminRolesEnum.CONCILIATOR, AdminRolesEnum.MANAGE_CONCILIATOR],
      icon: faCheck,
    },
    {
      text: 'Configuração Reseller Clover',
      link: Routes.RESELLER_CLOVER_CONFIGURATION,
      allowedRoles: [AdminRolesEnum.RESELLER_CLOVER_CONFIGURATION_READ, AdminRolesEnum.RESELLER_CLOVER_CONFIGURATION_WRITE],
      icon: faClipboardCheck,
    }
  ]
};

const creditRisk = {
  text: 'Crédito e Risco',
  link: Routes.CREDIT_RISK,
  mainMenuLinkRole:[AdminRolesEnum.CREDIT_AND_RISK_ANALYSIS],
  allowedRoles: [AdminRolesEnum.CREDIT_AND_RISK_ANALYSIS, AdminRolesEnum.EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_READ, AdminRolesEnum.EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_WRITE,AdminRolesEnum.CNAE_CREDIT_RISK_READ, AdminRolesEnum.CNAE_CREDIT_RISK_WRITE],
  icon: faSearchDollar,
  hasOptions: true,
  optionsName: 'credit-risk-proposals',
  options: [
    {
      text: "Visualizar propostas pendentes",
      allowedRoles: [AdminRolesEnum.CREDIT_AND_RISK_ANALYSIS],
      link: Routes.CREDIT_RISK,
      icon: faSearchDollar
    },
    {
      text: "Restrição de CNAE's para E-Commerce",
      allowedRoles: [AdminRolesEnum.EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_READ, AdminRolesEnum.EXCEPTION_CNAE_ECOMMERCE_CLASSIFICATION_WRITE],
      link: Routes.ECOMMERCE_CNAE_WHITELIST,
      icon: faClipboardCheck,
    },
    {
      text: 'Configuração Licença Profissional',
      allowedRoles: [AdminRolesEnum.PROFESSIONAL_LICENSE_CONFIG],
      link: Routes.CONFIGURATION_PROFESSIONAL_LICENSE,
      icon: faTools,
    },
    {
      text: "Gestão de CNAE's",
      allowedRoles: [AdminRolesEnum.CNAE_CREDIT_RISK_READ, AdminRolesEnum.CNAE_CREDIT_RISK_WRITE],
      link: Routes.CNAE_CREDIT_RISK_REGISTER,
      icon: faClipboardCheck,
    },
  ]
}

export const SideNavItems = [
  searchProposals,
  serviceType,
  pricePremiumUf,
  users,
  compliance,
  operationsProposals,
  creditRisk,
  reports
];
