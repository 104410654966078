<h3 fxLayoutAlign="center">Novo usuário</h3>
<form [formGroup]="formGroup">
  <div fxLayout="column" fxLayoutAlign="center">
    <ng-container *ngIf="isInitialStep">
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <fd-input (blur)="cpfCnpjChange($event)" [parentForm]="formGroup" [field]="fields.cpfCnpj"></fd-input>
        </div>
        <div fxFlex="50">
          <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
        </div>
      </div>
      <div fxLayoutGap="20px">
        <div fxFlex="50">
          <fd-input [parentForm]="formGroup" [field]="fields.mobileNumber"></fd-input>
        </div>
        <div fxFlex="50">
          <fd-input (blur)="emailChange()" [parentForm]="formGroup" [field]="fields.email"></fd-input>
        </div>
      </div>
      <div *ngIf="hasMoreThanOnePermission">
        <fd-select [parentForm]="formGroup" [field]="fields.userType"
          (optionClick)="setUserType(formGroup.value.userType)">
        </fd-select>
      </div>
      <div *ngIf="isConciliator">
        <fd-select [parentForm]="formGroup" [field]="fields.userConciliator">
        </fd-select>
      </div>
      <div *ngIf="isVendor || isSupervisor">
        <fd-select (loadItemsFn)="loadInstitutions()" [parentForm]="formGroup" [field]="fields.institution"
          (optionClick)="loadServiceContracts()">
        </fd-select>
      </div>
      <div *ngIf="isAdmin">
        <h5>Marque esta opção caso o usuário deva ter acesso completo</h5>
        <div class="checkbox-wrapper">
          <fd-checkbox [parentForm]="formGroup" [field]="fields.fullAccess">
          </fd-checkbox>
        </div>
      </div>
      <div *ngIf="isAdmin && !isFullAccessMarked">
        <fd-option-picker (openDialogFn)="openDialog()" [parentForm]="formGroup" [field]="fields.serviceContracts">
        </fd-option-picker>
      </div>
      <div *ngIf="isVendor || isSupervisor">
        <fd-select [parentForm]="formGroup" [field]="fields.serviceContracts"
          (optionClick)="loadChannelTypes()">
        </fd-select>
      </div>
      <ng-container *ngIf="isVendor || isSupervisor">
        <div>
          <fd-select [parentForm]="formGroup" [field]="fields.channelType"
            (optionClick)="loadChannels()">
          </fd-select>
        </div>
        <div>
          <fd-select [parentForm]="formGroup" [field]="fields.channel"
            (optionClick)="loadSubChannels()">
          </fd-select>
        </div>
        <div>
          <fd-select [parentForm]="formGroup" [field]="fields.subChannel"
            (optionClick)="loadAgentChannels()">
          </fd-select>
        </div>
        <div *ngIf="isVendor">
          <fd-select [parentForm]="formGroup" [field]="fields.agent">
          </fd-select>
        </div>
      </ng-container>
      <ng-container *ngIf="isAdmin || isConciliator">
        <div>
          <fd-select (loadItemsFn)="loadProfiles()" [parentForm]="formGroup" [field]="fields.userProfile">
          </fd-select>
        </div>
      </ng-container>
      <ng-container *ngIf="shouldShowRequestCodeAndWorkday">
        <div>
          <fd-input [parentForm]="formGroup" [field]="fields.requestCode"></fd-input>
        </div>
      </ng-container>
      <div *ngIf="shouldShowRequestCodeAndWorkday">
        <fd-input [parentForm]="formGroup" [field]="fields.workday"></fd-input>
      </div>
    </ng-container>
  </div>
</form>
<div mat-dialog-actions>
  <div>
    <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
    <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
  </div>
</div>
