import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProposalModel } from 'src/app/shared/models/proposal.model';
import { ProposalStatusEnum } from '../../enums/proposal-status.enum';
import { ProposalCodeEnum } from '../../enums/proposal-code.enum';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { AuthService } from 'src/app/shared/service/auth.service';
import {MonitoringProposalTabsEnum} from '../../../shared/enums/monitoring-proposal-tabs.enum';

@Component({
  selector: 'app-list-proposals',
  templateUrl: './list-proposals.component.html',
  styleUrls: ['./list-proposals.component.scss']
})
export class ListProposalsComponent implements OnInit, OnChanges {

  displayedColumns = ['proposalNumber', 'cpfCnpj', 'merchantId', 'serviceContract',
    'proposalStatusDescription', 'createdDate', 'completedDate', 'logicNumber', 'rnid', 'canalCredenciamento', 'options_edit', 'actions'];

  hasValue = false;
  hasCredencialChannelPermission = false;
  previousSizeItems = 0;
  defaultSizeItems = 50;
  readonly INSTITUTION_PAY_PAL = "00000009";

  @Input() dataSource: MatTableDataSource<ProposalModel>;

  @Input() sizeItems: number;
  @Input() actionsTabActive: boolean;

  @Input() activeTab: 0;

  @Input() totalPages: 0;

  @ViewChild('binding') binding: ElementRef;

  @Output() changePage = new EventEmitter<PageEvent>();

  @Output() detailEmmiter = new EventEmitter<ProposalModel>();
  @Output() resubmitEmitter = new EventEmitter<string>();


  constructor(
    private dialog: MatDialog,
    private authService: AuthService
  ) {
  }

  ngOnInit(): void {
    this.sizeItems = this.defaultSizeItems;
    this.previousSizeItems = this.sizeItems;
  }

  ngOnChanges() {
    if (this.dataSource.data.length > 0) {
      this.hasValue = true;
    } else {
      this.hasValue = false;
    }
    this.showCredencialChannelColumn();
  }

  isPendingCredit(proposal: ProposalModel) {
    return proposal.proposalStatusCode === ProposalCodeEnum.PENDING_CREDIT;
  }

  isSerproUnavailable(proposal: ProposalModel) {
    return proposal.proposalStatusCode === ProposalCodeEnum.SERPRO_UNAVAILABLE;
  }

  isAffiliated(proposal: ProposalModel) {
    return proposal.proposalStatusCode === ProposalCodeEnum.AFFILIATED;
  }

  isAffiliatedNotEnable(proposal: ProposalModel) {
    return proposal.proposalStatusCode === ProposalCodeEnum.AFFILIATED_NOT_ENABLED;
  }

  isErrorBaseProductContracted(proposal: ProposalModel) {
    return proposal.baseProductStatus === 2;
  }

  isAffiliatedTabActive() {
    return MonitoringProposalTabsEnum[MonitoringProposalTabsEnum[this.activeTab]]
      === MonitoringProposalTabsEnum.AFFILIATED;
  }

  isNotAffiliatedTabActive() {
    return MonitoringProposalTabsEnum[MonitoringProposalTabsEnum[this.activeTab]]
      === MonitoringProposalTabsEnum.NOT_AFFILIATED;
  }

  isPendingCreditTabActive() {
    return MonitoringProposalTabsEnum[MonitoringProposalTabsEnum[this.activeTab]]
      === MonitoringProposalTabsEnum.PENDING_CREDIT;
  }

  isBaseProductTabActive() {
    return MonitoringProposalTabsEnum[MonitoringProposalTabsEnum[this.activeTab]]
      === MonitoringProposalTabsEnum.PRODUCT_BASE;
  }

  isSERPROTabActive() {
    return MonitoringProposalTabsEnum[MonitoringProposalTabsEnum[this.activeTab]]
      === MonitoringProposalTabsEnum.SERPRO;
  }

  showCredencialChannelColumn(){
    this.hasCredencialChannelPermission = this.authService.isUserInRoles([AdminRolesEnum.ACCREDITATION_CHANNELS]) ? true : false;

    if(!this.displayedColumns.includes('canalCredenciamento') && this.hasCredencialChannelPermission){
      this.displayedColumns.push('canalCredenciamento');
    }

    if(this.displayedColumns.includes('canalCredenciamento') && !this.hasCredencialChannelPermission){
      const credentialChannelIndex = this.displayedColumns.indexOf('canalCredenciamento');
      this.displayedColumns.splice(credentialChannelIndex, 1);
    }
  }

  get hasAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.ALTER_PROPOSAL_STATUS]);
  }

  changeActionStatus(event: MatCheckboxChange, proposal: ProposalModel) {
    proposal.selected = event.checked;
  }

  detail(proposal: ProposalModel) {
    this.detailEmmiter.emit(proposal);
  }

  resubmit(proposal: ProposalModel) {
    this.resubmitEmitter.emit(proposal.proposalNumber);
  }


  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

}



