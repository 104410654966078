<h3 fxLayoutAlign="center">Editar usuário</h3>
<form [formGroup]="formGroup">
    <div fxLayout="column" fxLayoutAlign="center">
        <ng-container>
            <div fxLayoutGap="20px">
                <div fxFlex="100">
                    <fd-input [parentForm]="formGroup" [field]="fields.name"></fd-input>
                </div>
            </div>
            <div fxLayoutGap="20px">
                <div fxFlex="50">
                    <fd-input [parentForm]="formGroup" [field]="fields.mobileNumber"></fd-input>
                </div>
                <div fxFlex="50">
                    <fd-input [parentForm]="formGroup" [field]="fields.email"></fd-input>
                </div>
            </div>
            <div>
                <fd-select [parentForm]="formGroup" [field]="fields.userType"
                    (selectionChange)="setUserType(formGroup.value.userType, true)">
                </fd-select>
            </div>
            <div *ngIf="isConciliator">
              <fd-select [parentForm]="formGroup" [field]="fields.conciliatorId">
              </fd-select>
            </div>
            <div *ngIf="isVendor || isSupervisor">
                <fd-select (loadItemsFn)="loadInstitutions()" [parentForm]="formGroup" [field]="fields.institution"
                    (optionClick)="loadServiceContracts(formGroup.value.institution)">
                </fd-select>
            </div>
            <div *ngIf="isAdmin">
                <h5>Marque esta opção caso o usuário deva ter acesso completo</h5>
                <div class="checkbox-wrapper">
                    <fd-checkbox (change)="onFullAccessChange()" [parentForm]="formGroup" [field]="fields.fullAccess">
                    </fd-checkbox>
                </div>
            </div>
            <div *ngIf="isAdmin && !isFullAccessMarked">
                <fd-option-picker (openDialogFn)="openDialog()" [parentForm]="formGroup"
                    [field]="fields.serviceContracts">
                </fd-option-picker>
            </div>
            <div *ngIf="isVendor || isSupervisor">
                <fd-select [parentForm]="formGroup" [field]="fields.serviceContracts"
                    (optionClick)="loadChannelTypes()">
                </fd-select>
            </div>
            <ng-container *ngIf="isVendor || isSupervisor">
                <div>
                    <fd-select [parentForm]="formGroup" [field]="fields.channelType"
                        (optionClick)="loadChannels()">
                    </fd-select>

                </div>
                <div>
                    <fd-select [parentForm]="formGroup" [field]="fields.channel"
                        (optionClick)="loadSubChannels()">
                    </fd-select>

                </div>
                <div>
                    <fd-select [parentForm]="formGroup" [field]="fields.subChannel"
                        (optionClick)="loadAgentChannels()">
                    </fd-select>
                </div>
                <div *ngIf="isVendor">
                    <fd-select [parentForm]="formGroup" [field]="fields.agent">
                    </fd-select>
                </div>
            </ng-container>
            <ng-container *ngIf="isAdmin || isConciliator">
                <div>
                    <fd-select [parentForm]="formGroup" [field]="fields.userProfile"
                        (selectionChange)="changeProfile(formGroup.value.userProfile)"></fd-select>
                </div>
            </ng-container>
            <ng-container *ngIf="shouldShowRequestCodeAndWorkday">
                <div>
                  <fd-input [parentForm]="formGroup" [field]="fields.requestCode"></fd-input>
                </div>
              </ng-container>
            <div *ngIf="shouldShowRequestCodeAndWorkday">
                <fd-input [parentForm]="formGroup" [field]="fields.workday"></fd-input>
            </div>

            <div *ngIf="canResendEmail()">
                <a class="token-resend" (click)="sendEmailPassword()">Enviar e-mail de
                    redefinição de senha</a>
            </div>
        </ng-container>
    </div>
</form>
<div mat-dialog-actions>
    <div>
        <fd-button mat-button fdSecondaryLarge [content]="'Cancelar'" (trigger)="close()"> </fd-button>
        <fd-button fdPrimaryLarge mat-flat-button [content]="'Salvar'" (trigger)="save()"> </fd-button>
    </div>
</div>
