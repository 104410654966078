<div class="fd-image-upload">
    <input type="file" style="display: none;" #inputFile accept="image/*" (change)="onFileSelected($event)" />
    <fd-button fdSmallButton [hidden]="imageUrl" mat-flat-button content="Carregar imagem" (trigger)="inputFile.click()"> </fd-button>
    <div *ngIf="imageUrl" class="fd-image-view">
        <img [src]="imageUrl" alt="">
        <div class="buttons">
            <fd-button fdSmallButton fdExport mat-flat-button content="Deletar imagem" (trigger)="deleteImage()"> </fd-button>
            <fd-button fdSmallButton mat-flat-button content="Alterar imagem" (trigger)="inputFile.click()"> </fd-button>
        </div>
    </div>
</div>
  