<div *ngIf="hasValue">
  <table mat-table matSort class="table" [dataSource]="dataSource.data">

    <ng-container matColumnDef="proposalNumber" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Nº da proposta</th>
      <td mat-cell *matCellDef="let element"> {{element.proposalNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="cpfCnpj" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>CPF/CNPJ</th>
      <td mat-cell *matCellDef="let element"> {{element.cpfCnpj}} </td>
    </ng-container>

    <ng-container matColumnDef="merchantId" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Merchant Id</th>
      <td mat-cell *matCellDef="let element"> {{element.merchantId}} </td>
    </ng-container>

    <ng-container matColumnDef="serviceContract" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Service Contract</th>
      <td mat-cell *matCellDef="let element"> {{element.serviceContract}} </td>
    </ng-container>

    <ng-container matColumnDef="proposalStatusDescription" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef> Status da Proposta</th>
      <td mat-cell *matCellDef="let element"> {{element.proposalStatusDescription}} </td>
    </ng-container>

    <ng-container matColumnDef="riskCenterStatusDescription" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Risk Center </th>
      <td mat-cell *matCellDef="let element"> {{element.riskCenterStatusDescription}} </td>
    </ng-container>

    <ng-container matColumnDef="createdDate" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Data de Criação</th>
      <td mat-cell *matCellDef="let element"> {{element.createdDate}} </td>
    </ng-container>

    <ng-container matColumnDef="completedDate" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Data de Finalização</th>
      <td mat-cell *matCellDef="let element"> {{element.completedDate}} </td>
    </ng-container>

    <ng-container matColumnDef="logicNumber" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Logic Number</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngFor="let activation of element.activations">{{activation.logicNumber}}<br></ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="rnid" class="table__column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Num da OS</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngFor="let activation of element.activations">{{activation.os}}<br></ng-container>
      </td>
    </ng-container>

    <ng-container *ngIf="hasCredencialChannelPermission" matColumnDef="canalCredenciamento" class="table__column">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Canal de Credenciamento</th>
      <td mat-cell *matCellDef="let element"> {{element.accreditationChannel != null ? element.accreditationChannel : element.accreditationSource}} </td>
    </ng-container>


    <ng-container matColumnDef="options_edit" class="table_column">
      <th mat-header-cell mat-sort-header *matHeaderCellDef>Detalhes</th>
      <td mat-cell *matCellDef="let element">
        <fa-icon *ngIf="element.institution !== INSTITUTION_PAY_PAL" class="proposal_detail" matTooltip="Detalhes da proposta" (click)="detail(element)" icon="file">
        </fa-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" class="table_column">
      <th [ngClass]="{'hidden': !actionsTabActive || !hasAccess }" mat-header-cell mat-sort-header *matHeaderCellDef>Ações</th>
      <td [ngClass]="{'hidden': !actionsTabActive || !hasAccess }" mat-cell *matCellDef="let element">

        <mat-checkbox  (change)="changeActionStatus($event, element)" [value]="element" [checked]="element.selected" class="fd-checkbox">
        </mat-checkbox>

      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator *ngIf="hasValue" #paginator
    [pageSizeOptions]="[defaultSizeItems,defaultSizeItems*2,defaultSizeItems*3,defaultSizeItems*4,defaultSizeItems*5,defaultSizeItems*6,defaultSizeItems*7,defaultSizeItems*8,defaultSizeItems*9,defaultSizeItems*10]"
    (page)="change($event)" [length]="totalPages"></mat-paginator>
</div>

<h5 *ngIf="!hasValue">Nenhum registro foi encontrado</h5>
