import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { finalize } from 'rxjs/operators';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { FileContentTypeEnum } from '../shared/enums/file-content-type.enum';
import { ReportTypeEnum } from '../shared/enums/report-type.enum';
import { UserTypeEnum } from '../shared/enums/user-type.enum';
import { FdAlertComponent, ModalDefinitions } from '../shared/fd-form-components/fd-alert/fd-alert.component';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { FdSelectConfig, Item } from '../shared/fd-form-components/fd-select/fd-select.component';
import { cpfMask } from '../shared/masks/document-masks';
import { Messages } from '../shared/messages/messages';
import { ToggleUserStatusModel } from '../shared/models/toggle-user-status.model';
import { UserModel, UserSearchModel } from '../shared/models/user.model';
import { AuthService } from '../shared/service/auth.service';
import { DialogService } from '../shared/service/dialog.service';
import { ErrorService } from '../shared/service/error.service';
import { ExportReportService } from '../shared/service/export-report.service';
import { FileService } from '../shared/service/file.service';
import { HierarchyService } from '../shared/service/hierarchy.service';
import { LoadingService } from '../shared/service/loading.service';
import { AddUserComponent } from './components/add-user/add-user.component';
import { EditUserComponent } from './components/edit-user/edit-user.component';
import { UserDetailsComponent } from './components/user-details/user-details.component';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  dataSource: MatTableDataSource<UserModel>;
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  pageNumber = 0;
  pageNumberFilter = 0;
  size = 10;
  totalItens;
  userTypes: Item[] = [
    {
      value: '',
      label: 'Selecione uma opção'
    },
    {
      value: UserTypeEnum.ADMIN,
      label: 'Administrador'
    },
    {
      value: UserTypeEnum.VENDOR,
      label: 'Vendedor'
    },
    {
      value: UserTypeEnum.SUPERVISOR,
      label: 'Supervisor'
    },
    {
      value: UserTypeEnum.CONCILIATOR,
      label: 'Conciliadora'
    }
  ];
  pdf = ReportTypeEnum.PDF;
  xlsx = ReportTypeEnum.XLSX;

  @ViewChild('uploadBlockUserList') inputUploadBlockList: ElementRef;
  @ViewChild('uploadImportUser') uploadImportUser: ElementRef;

  constructor(
    private hierarchyService: HierarchyService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private loadingService: LoadingService,
    private userService: UserService,
    private dialog: MatDialog,
    private dialogService: DialogService,
    private errorService: ErrorService,
    private fileService: FileService,
    private exportReportService: ExportReportService
  ) {
    this.userService.clearProfilesCache();
    this.userService.getAllProfiles(true).subscribe();
  }

  ngOnInit() {
    this.startForms();

    if (!this.hasWriteAccess) {
      this.search();
    }
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.USERS_ADMIN_WRITE, AdminRolesEnum.USERS_VENDOR_WRITE]);
  }

  uploadBlockList() {
    const el = this.inputUploadBlockList.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
    el.click();
  }

  uploadImportUsers() {
    const el = this.uploadImportUser.nativeElement.querySelector('ngx-mat-file-input[name="upload-start"] input[type="file"]') as HTMLInputElement;
    el.click();
  }

  startUpload(filesParams: FileList, event: any) {
    this.loadingService.show();

    const formData: FormData = this.createFileFormData(filesParams);
    const methodToInvoke: string = event.currentTarget.attributes.method.value;

    this.userService[methodToInvoke](formData)
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(file => {
      if (file) {
        this.fileService.saveFile(file, FileContentTypeEnum.CSV, 'resultado.csv');
      }
    }, err => this.errorService.handleXHRErrorDownload(err, Messages.UPLOAD_FILE));

    this.inputUploadBlockList.nativeElement.value = '';
    this.uploadImportUser.nativeElement.value = '';
}

  downloadTemplate(event: any) {
    const methodToInvoke: string = event.currentTarget.attributes.method.value;
    const fileName: string = event.currentTarget.attributes.fileName.value;

    this.userService[methodToInvoke]()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          this.fileService.saveFile(data, FileContentTypeEnum.CSV, fileName);
        }
      }, err => this.errorService.handleXHRError(err, Messages.DOWNLOAD_FILE));
  }

  addUser() {

    const dialogRef = this.dialog.open(AddUserComponent, {
      width: '40%',
      height: '85%',
      disableClose: true,
      data: {
        serviceContract: this.formGroup.value.serviceContract,
        institutionId: this.formGroup.value.institutionNumber
      }
    });
    dialogRef.afterClosed().subscribe(() => {
      setTimeout(() => this.search(), 200);
    });
  }

  setUserStatus(cpfCnpj: string, isEnabled: boolean) {
    if (!cpfCnpj) {
      return;
    }

    const data: ToggleUserStatusModel = {
      cpfCnpj,
      isEnabled
    };

    this.loadingService.show();

    this.userService.setUserStatus(data).subscribe(
      () => {
        this.dialogService.openDialog(Messages.EDIT_SAVE_SUCCESS, () => this.search());
      },
      (err: HttpErrorResponse) => { this.errorService.handleXHRError(err, Messages.SEARCH_ERROR); }
    );
  }

  inactivateUser(cpfCnpj: string) {
    this.setUserStatus(cpfCnpj, false);
  }

  activateUser(cpfCnpj: string) {
    this.setUserStatus(cpfCnpj, true);
  }

  editUser(data: UserModel) {
    const dialogRef = this.dialog.open(EditUserComponent, {
      width: '40%',
      height: '95%',
      data
    });
    dialogRef.afterClosed().subscribe(() => {
      // Necessário setar o timer pois a pesquisa não é feita corretamente em casos aleatórios.
      setTimeout(() => this.search(true), 200);
    });
  }

  viewUserDetails(userModel: UserModel) {
    const dialogRef = this.dialog.open(UserDetailsComponent, {
      width: '35%',
      height: '65%',
      data: userModel
    });
  }

  search(searchWithPageNumber: boolean = false) {

    let cpf = this.formGroup.controls.cpf1.value;
    const institution = this.formGroup.controls.institution.value;
    const serviceContract = this.formGroup.controls.serviceContract.value;
    const userType = this.formGroup.controls.userType.value;
    this.pageNumberFilter = searchWithPageNumber ? this.pageNumber : 0;

    if (cpf) {
      cpf = cpf.replace(/\D/g, '');
    }

    this.loadingService.show();

    const data: UserSearchModel = {
      cpf: cpf === '' ? null : cpf,
      type: userType === '' ? null : userType,
      institution: institution === '' ? null : institution,
      serviceContract: serviceContract === '' ? null : serviceContract
    };

    this.userService.searchUsers(this.pageNumberFilter, this.size, data)
      .subscribe(pageable => {
        if (pageable) {
          this.dataSource = new MatTableDataSource(pageable.users);
          this.loadingService.hide();
          this.totalItens = pageable.totalItens;
        }
      },
        err => {
          this.loadingService.hide();
          if (err.status === 404) {
            const dialogRef = this.dialog.open(FdAlertComponent, {
              disableClose: true,
              width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
              data: Messages.SEARCH_NOT_FOUND
            });
          }
        });
  }

  exportUsers(reportType: ReportTypeEnum) {
    this.loadingService.show();

    let cpf = this.formGroup.controls.cpf1.value;
    const institution = this.formGroup.controls.institution.value;
    const serviceContract = this.formGroup.controls.serviceContract.value;
    const userType = this.formGroup.controls.userType.value;
    const subtitle = this.getFilterReport(serviceContract, institution, cpf, userType);

    if (cpf) {
      cpf = cpf.replace(/\D/g, '');
    }

    this.userService.exportUsersReportData(serviceContract, institution, cpf, userType)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(users => {
        if (users) {
          this.exportReportService.export('Relatório de usuários', subtitle, users, reportType, true);
        }
      }),
      err => this.errorService.handleXHRError(err, Messages.SEARCH_ERROR);

  }

  getFilterReport(serviceContract: any, institution: any, cpf: any, userType: any) {
    let filter: string[] = ['Filtros:']
    if (serviceContract) {
      filter.push('Service contract: ' + serviceContract);
    }
    if (institution) {
      filter.push('Instituição: ' + institution);
    }
    if (cpf) {
      filter.push('CPF: ' + cpf);
    }
    if (userType) {
      filter.push('Tipo do usuário: ' + userType);
    }
    return filter.length > 1 ? filter : [];
  }

  loadServiceContracts(institutionNumber: string): void {

    if (!institutionNumber) {
      institutionNumber = this.formGroup.value.institutionNumber;
    }

    if (!institutionNumber) {
      (this.fields.serviceContract as FdSelectConfig).items = [];
      this.formGroup.controls.serviceContract.disable();
      this.formGroup.controls.serviceContract.setValue('');
      return;
    }
    this.loadingService.show();
    this.hierarchyService.serviceContractByInstitution(institutionNumber)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(data => {
        if (data) {
          (this.fields.serviceContract as FdSelectConfig).items = [];
          (this.fields.serviceContract as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.serviceContract as FdSelectConfig).items.push(...data);
          this.formGroup.controls.serviceContract.enable();
        }
      }, (error: HttpErrorResponse) => {
        this.formGroup.controls.serviceContract.disable();
        this.formGroup.controls.serviceContract.setValue('');

        if (error.status === 404) {
          this.dialogService.openDialog(Messages.SERVICE_CONTRACT_NOT_FOUND);
          return;
        }
        this.dialogService.openDialog(Messages.SERVICE_CONTRACT_LOAD_ERROR);
      });
  }

  loadInstitutions(): void {
    this.loadingService.show();

    (this.fields.serviceContract as FdSelectConfig).items = [];

    this.formGroup.controls.serviceContract.disable();
    this.formGroup.controls.serviceContract.setValue('');


    this.hierarchyService.institution()
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(list => {
        if (list != null) {
          (this.fields.institution as FdSelectConfig).items = [];
          (this.fields.institution as FdSelectConfig).items.push({ label: 'Selecione uma opção', value: '' });
          (this.fields.institution as FdSelectConfig).items.push(...list);
        }
      }, (error: HttpErrorResponse) => {
        if (error.status === 404) {
          this.dialogService.openDialog(Messages.INSTITUTION_NOT_FOUND);
          return;
        }

        this.dialogService.openDialog(Messages.INSTITUTION_LOAD_ERROR);
      });
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institution: [''],
      serviceContract: [''],
      cpf1: [''],
      userType: [''],
    });

    this.fields = {
      institution: {
        label: 'Instituição',
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um usuário válido',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um usuário válido',
          invalid: 'Usuário inválido'
        }
      },
      cpf1: {
        label: 'CPF',
        mask: cpfMask,
        controlName: 'cpf1',
        messages: {
          required: 'Informe um CPF',
          invalid: 'CPF inválido'
        }
      },
      userType: {
        label: 'Tipo do usuario',
        items: this.userTypes,
        controlName: 'userType',
        messages: {
          required: 'Informe o tipo'
        }
      },
    };
  }

  changePage(event: PageEvent) {
    this.size = event.pageSize;
    this.pageNumber = event.pageIndex;
    this.search(true);
  }

  createFileFormData(filesParams: FileList): FormData {
    const formData = new FormData();

    if (filesParams && filesParams.length > 0) {
      const file = filesParams[0];
      const csvType = '.csv';
      if (file && file.name.match(csvType)) {
        const blob = new Blob([file], { type: 'text/csv' });
        formData.append('file', blob, file.name);
      }
    }

    return formData;
  }
}
