import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PageablePriceCampaignModel, PriceCampaignModel } from 'src/app/shared/models/price-campaign';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { RevenueRangeModel } from 'src/app/shared/models/revenue-range.model';
import { TechnologyPriceModel } from 'src/app/shared/models/technology-price.model';
import { TechnologyModel } from 'src/app/shared/models/technology.model';
import { PriceCampaignUpdateModel } from 'src/app/shared/models/price-campaign-update.model';


@Injectable({ providedIn: 'root' })
export class PriceCampaignService {

  constructor(private http: HttpClient) { }

  saveRevenueRange(data: RevenueRangeModel[], idCampaign: string): Observable<any> {
    return this.http.post<RevenueRangeModel[]>(`${environment.apiUrls.revenueRange}/${idCampaign}`, data);
  }

  deleteRevenueRange(revenueRangeId: string, idCampaign: string): Observable<any> {
    return this.http.post<any>(`${environment.apiUrls.priceCampaign}/delete/billing-range/${revenueRangeId}?id-campaign=${idCampaign}`, null);
  }

  saveTechnologyPrices(data: TechnologyPriceModel[], idCampaign: string, serviceContract: string): Observable<any> {
    return this.http.post<TechnologyPriceModel[]>
      (`${environment.apiUrls.priceTechnology}/${idCampaign}?service-contract=${serviceContract}`, data);
  }

  getTechnologyPrices(idCampaign: number, serviceContract: number): Observable<TechnologyPriceModel[]> {
    return this.http.get<TechnologyPriceModel[]>
      (`${environment.apiUrls.priceTechnology}/${idCampaign}?service-contract=${serviceContract}`);
  }

  getTechnologiesByServiceContract(institution: string, serviceContract: number): Observable<TechnologyModel[]> {
    return this.http.get<TechnologyModel[]>(`${environment.apiUrls.serviceConfig}/${institution}/${serviceContract}`)
      .pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  getTechnologiesByServiceContractAndStatus(institution: string, serviceContract: number, isEnabled: boolean): Observable<TechnologyModel[]> {
    return this.http.get<TechnologyModel[]>(`${environment.apiUrls.serviceConfig}/${institution}/${serviceContract}?isEnabled=${isEnabled}`)
      .pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  getRevenueRange(idCampaign: number): Observable<RevenueRangeModel[]> {
    return this.http.get<RevenueRangeModel[]>(`${environment.apiUrls.revenueRange}/${idCampaign}`);
  }

  // SEARCH SERVICES
  getAllCampaigns(page: number, size: number, serviceContract: string, isEnabled?: boolean): Observable<PageablePriceCampaignModel> {
    let url = `${environment.apiUrls.priceCampaign}?service-contract=${serviceContract}&page=${page}&size=${size}`;

    if(isEnabled != null || isEnabled != undefined) {
      url += `&enabled=${isEnabled}`;
    }

    return this.http.get<PageablePriceCampaignModel>(url)
      .pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  getAllCampaignsList(institution: string, serviceContract: number, isEnabled: boolean, isExpired: boolean): Observable<PriceCampaignModel[]> {
    return this.http.get<PriceCampaignModel[]>(`${environment.apiUrls.priceCampaign}/list?institution=${institution}&service-contract=${serviceContract}&enabled=${isEnabled}&expired=${isExpired}`)
      .pipe(map(x => this.invalidResponse(x) ? null : x));
  }

  getBasedOnList(serviceContract: string): Observable<Item[]> {
    return this.http.get<PriceCampaignModel[]>(`${environment.apiUrls.priceCampaign}/with-files/${serviceContract}`)
      .pipe(map(x => this.invalidResponse(x) ? null : this.mapToPriceCampaignModels(x)));
  }

  // UPDATE SERVICES
  updateCampaign(data: PriceCampaignUpdateModel): Observable<any> {
    return this.http.post<PriceCampaignUpdateModel>(`${environment.apiUrls.priceCampaign}/create`, data);
  }

  deleteCampaign(campaignId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrls.priceCampaign}/delete/${campaignId}`, null);
  }

  sendFile(data: any): Observable<any> {
    return this.http.post<PriceCampaignModel>(`${environment.apiUrls.priceCampaign}/send`, data);
  }

  uploadFile(file: any, type: string, campaignId: number): Observable<any> {
    return this.http.post<any>(`${environment.apiUrls.priceCampaign}/send/${type}/${campaignId}`, file);
  }

  downloadFile(type: string, idCampaign: number): Observable<any> {
    return this
          .http
          .get<any>(`${environment.apiUrls.priceCampaign}/download-file/${type}?id-campaign=${idCampaign}`,
                      { responseType: 'blob' as 'json'  });
  }

  runningProcess(type: string, idCampaign: number): Observable<any> {
    return this.http.get<any>(`${environment.apiUrls.priceCampaign}/running-process-file/${idCampaign}?type-file=${type}`);
  }

  // MAP RESPONSE
  private mapToPriceCampaignModels(response: PriceCampaignModel[]) {
    if (!response) {
      return [];
    }
    return response.map(val => ({
      value: val.idCampaign,
      label: val.description,
    }));
  }

  private mapToAllServiceContractModels(response: number[]): Item[] {
    if (!response) {
      return [];
    }
    return response.map(val => ({
      value: val,
      label: val.toString(),
    }));
  }

  // VALID RESPONSE
  private invalidResponse(baseApiResponse: any): boolean {
    return !baseApiResponse;
  }
}
