import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HierarchyComplementaryInformationModel, UserDetailsModel, UserModel } from 'src/app/shared/models/user.model';
import { UserTypeEnum } from 'src/app/shared/enums/user-type.enum';
import { Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { UserService } from '../../services/user.service';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../../../shared/service/loading.service';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { Messages } from 'src/app/shared/messages/messages';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.scss']
})
export class UserDetailsComponent implements OnInit {

  serviceContractList: string;
  institutionList: string;
  userHierarchyInformation: HierarchyComplementaryInformationModel[];
  conciliatorName: string;
  constructor(private dialogRef: MatDialogRef<UserDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public userData: UserDetailsModel,
              private userService: UserService,
              private authService: AuthService,
              private dialogService: DialogService,
              private loadingService: LoadingService
            ) { }

  ngOnInit() {
    this.serviceContractList = (this.userData.serviceContracts as Item[])
        .map(x => x.value).join(', ');
    this.institutionList = !Array.isArray(this.userData.institution) ? this.userData.institution : (this.userData.institution as string[])
      .filter((value, index, self) => self.indexOf(value) === index).join(', ');

    this.userHierarchyInformation = this.getUserHierarchyInformation();

    this.getConciliatorName();

  }

  private getConciliatorName() {
    if (this.userData.type === UserTypeEnum.CONCILIATOR) {
      this.loadingService.show();
      this.userService.getConciliatorName(this.userData.cpfCnpj)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(res => {
          this.conciliatorName = res.conteudo;
        }, error => {
          console.error(error);
        });
    }
  }

  hasUserHierarchyInformation(){
    return !!this.userData.userInformation &&
           !!this.userData.userInformation.length &&
           !!this.userData.userInformation[0].hierarchyComplementaryInformation &&
           !!this.userData.userInformation[0].hierarchyComplementaryInformation.length;
  }

  getUserHierarchyInformation() {
    if(!this.hasUserHierarchyInformation()){
      return;
    }
    return this.userData.userInformation[0].hierarchyComplementaryInformation;
  }

    sendEmailPassword() {
      this.loadingService.show();


      const institution = !Array.isArray(this.userData.institution) ? this.userData.institution : (this.userData.institution as string[]).length !== 0 ? (this.userData.institution as string[]).join(', ') : null
      const serviceContract = (this.userData.serviceContracts as Item[]).map(x => x.value);
      const data: UserModel = {
        agent: this.userData.agent || null,
        channel: this.userData.channel || null,
        channelType: this.userData.channelType || null,
        subChannel: this.userData.subChannel || null,
        cpfCnpj: this.userData.cpfCnpj,
        email: this.userData.email,
        type: this.userData.type,
        institution: institution,
        name: this.userData.name,
        mobileNumber: this.returnOnlyDigits(this.userData.mobileNumber),
        serviceContracts: serviceContract,
        profileId: this.userData.profile.id
      }


      this.userService.sendPasswordEmail(data).
        pipe(finalize(() => this.loadingService.hide())).
        subscribe(() => this.dialogService.openDialog(Messages.RESEND_PASSWORD_EMAIL));
    }

      canResendEmail() {
        return this.authService.isUserInRoles([AdminRolesEnum.RESEND_FIRST_ACCESS_EMAIL]) && !this.userData.passwordSet;
      }

    returnOnlyDigits(value: string) {
      if (value) {
        return value.replace(/[^0-9]/g, '');
      }
    }

  formatExpirationDate() {
     if (this.userData.pExpirationDate != null) {
        return this.userData.pExpirationDate[2] + '/' + this.userData.pExpirationDate[1] + '/' +
              this.userData.pExpirationDate[0];
     }
  }



  getTypeDescription(userType: UserTypeEnum) {
    switch (userType) {
      case UserTypeEnum.ADMIN:
        return "Administrador";

      case UserTypeEnum.VENDOR:
        return "Vendedor";

      case UserTypeEnum.SUPERVISOR:
        return "Supervisor";

      case UserTypeEnum.CONCILIATOR:
        return "Conciliadora";
    }
  }

  isConciliator() {
    return this.userData.type === UserTypeEnum.CONCILIATOR;
  }

  shouldViewWorkdayField() {
    let institutionNumber = this.userData.institution;
    return (this.userData.type == UserTypeEnum.VENDOR || this.userData.type == UserTypeEnum.SUPERVISOR) && institutionNumber == '00000007';
  }

  close() {
    this.dialogRef.close();
  }

}
