import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FdFieldConfigs } from '../shared/fd-form-components/fd-form-components.module';
import { LoadingService } from '../shared/service/loading.service';
import { WorkflowService } from './services/workflow.service';
import { finalize } from 'rxjs/operators';
import { WorkflowModel, WorkflowTableSourceModel } from './models/workflow.model';
import { AuthService } from '../shared/service/auth.service';
import { AdminRolesEnum } from '../shared/enums/admin-roles.enum';
import { MatDialog } from '@angular/material/dialog';
import { FdAlertComponent, ModalDefinitions } from '../shared/fd-form-components/fd-alert/fd-alert.component';
import { Messages } from '../shared/messages/messages';
import { ErrorService } from '../shared/service/error.service';
import { FileService } from '../shared/service/file.service';
import { FileContentTypeEnum } from '../shared/enums/file-content-type.enum';
import { HttpErrorResponse } from '@angular/common/http/http';
import { FormValidationService } from '../shared/service/form-validation.service';

@Component({
  selector: 'app-workflow',
  templateUrl: './workflow.component.html',
  styleUrls: ['./workflow.component.scss']
})
export class WorkflowComponent implements OnInit {

  displayedColumns = ['validacoes', 'simNao'];
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  selectedInstitution: string;
  selectedServiceContract: string;
  workflowList: WorkflowTableSourceModel[];
  workflowPayload: WorkflowModel;

  constructor(private formBuilder: FormBuilder,
    private loadingService: LoadingService,
    private workflowService: WorkflowService,
    private fileService: FileService,
    private authService: AuthService,
    private errorService: ErrorService,
    private dialog: MatDialog,
    private formValidationService: FormValidationService
  ) { }

  ngOnInit(): void {
    this.startForms();
  }

  get formControls() {
    return this.formGroup.controls;
  }

  get isFormGroupValid() {
    return this.formValidationService.isFormGroupValid(this.formGroup);
  }

  startForms(): void {

    this.formGroup = this.formBuilder.group({
      institution: ['', Validators.required],
      serviceContract: ['', Validators.required]
    });

    this.fields = {
      institution: {
        label: 'Instituição',
        items: [],
        searchable: true,
        required: true,
        searchPlaceholder: "Digite algo",
        controlName: 'institution',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe uma instituição',
          invalid: 'Usuário inválido'
        }
      },
      serviceContract: {
        label: 'Service Contract',
        items: [],
        searchable: true,
        required: true,
        searchPlaceholder: "Digite algo",
        controlName: 'serviceContract',
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um service contract',
          invalid: 'Usuário inválido'
        }
      }
    };
  }

  changeSelectedInstitution(institution: string) {
    this.selectedInstitution = institution;
    this.workflowList = [];
  }

  changeSelectedServiceContract(serviceContract: string) {
    this.selectedServiceContract = serviceContract;
    this.workflowList = [];
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CONFIGURATION_WRITE])
  }

  search() {
    if (!this.isFormGroupValid) {
      return;
    } else {
      let institution =  this.formControls.institution.value;
      let serviceContract = this.formControls.serviceContract.value;
      this.loadingService.show();
      this.workflowService.getWorkflow(institution, serviceContract)
      .pipe(finalize(() => this.loadingService.hide()))
      .subscribe(response => {
        this.buildWorkflowList(response);
        },
        error => {
          this.errorService.handleXHRError(error, Messages.SEARCH_ERROR);
        }
      );
    }
  }

  downloadWorkflowHistory() {
    if (!this.isFormGroupValid) {
      return;
    } else {
      let institution =  this.formControls.institution.value;
      let serviceContract = this.formControls.serviceContract.value;
      this.loadingService.show();
      this.workflowService.downloadWorkflowHistory(institution, serviceContract)
        .pipe(finalize(() => this.loadingService.hide()))
        .subscribe(data => {
          this.fileService.saveFile(data, FileContentTypeEnum.CSV, 'workflow_history.csv');
          },
          (error: HttpErrorResponse) => {
            this.errorService.handleXHRErrorDownload(error, Messages.SEARCH_ERROR);
          }
        );
    }
  }

  save() {
    this.loadingService.show();
    this.buildWorkflowPayload();
    this.workflowService.editWorkflow(this.workflowPayload)
    .pipe(finalize(() => this.loadingService.hide()))
    .subscribe(() => {
        const successDialogRef = this.dialog.open(FdAlertComponent, {
          disableClose: true,
          width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
          data: Messages.EDIT_SAVE_SUCCESS
        });
      },
      error => {
        this.loadingService.hide();
        this.errorService.handleXHRError(error, Messages.GENERAL_ERROR);
      }
    );
  }

  buildWorkflowPayload() {
    this.workflowPayload = {
      institution: this.formControls.institution.value,
      serviceContract: this.formControls.serviceContract.value,
      serproValidation: this.getValueByControlName('serproValidation'),
      blackListValidation: this.getValueByControlName('blackListValidation'),
      creditAndRiskValidation: this.getValueByControlName('creditAndRiskValidation'),
      terminalsValidation: this.getValueByControlName('terminalsValidation'),
      merchantIDValidation: this.getValueByControlName('merchantIDValidation'),
      complianceValidation: this.getValueByControlName('complianceValidation'),
      paymentSplitValidation: this.getValueByControlName('paymentSplitValidation'),
      riskCenterValidation: this.getValueByControlName('riskCenterValidation'),
      accreditationBoardingValidation: this.getValueByControlName('accreditationBoardingValidation'),
      tmsShippingValidation: this.getValueByControlName('tmsShippingValidation'),
      digitalBoardingIntegrationSeValidation: this.getValueByControlName('digitalBoardingIntegrationSeValidation'),
      contractedBaseProductValidation: this.getValueByControlName('contractedBaseProductValidation'),
      bwConsultationValidation: this.getValueByControlName('bwConsultationValidation'),
    }
  }

  buildWorkflowList(workflowModel: WorkflowModel) {
    this.workflowList = [
      {
        label: 'Validação Serpro',
        value: workflowModel.serproValidation,
        controlName: 'serproValidation'
      },
      {
        label: 'Validação Blacklist',
        value: workflowModel.blackListValidation,
        controlName: 'blackListValidation'
      },
      {
        label: 'Validação Crédito e Risco',
        value: workflowModel.creditAndRiskValidation,
        controlName: 'creditAndRiskValidation'
      },
      {
        label: 'Validação de Terminais',
        value: workflowModel.terminalsValidation,
        controlName: 'terminalsValidation'
      },
      {
        label: 'Merchant ID',
        value: workflowModel.merchantIDValidation,
        controlName: 'merchantIDValidation'
      },
      {
        label: 'Validação Compliance',
        value: workflowModel.complianceValidation,
        controlName: 'complianceValidation'
      },
      {
        label: 'Validação Split de pagamentos',
        value: workflowModel.paymentSplitValidation,
        controlName: 'paymentSplitValidation'
      },
      {
        label: 'Validação Risk center',
        value: workflowModel.riskCenterValidation,
        controlName: 'riskCenterValidation'
      },
      {
        label: 'Credenciamento Boarding',
        value: workflowModel.accreditationBoardingValidation,
        controlName: 'accreditationBoardingValidation'
      },
      {
        label: 'Envio TMS',
        value: workflowModel.tmsShippingValidation,
        controlName: 'tmsShippingValidation'
      },
      {
        label: 'Envio Digital Boarding Integração - SE',
        value: workflowModel.digitalBoardingIntegrationSeValidation,
        controlName: 'digitalBoardingIntegrationSeValidation'
      },
      {
        label: 'Envio Digital boarding - Produto base contratado',
        value: workflowModel.contractedBaseProductValidation,
        controlName: 'contractedBaseProductValidation'
      },
      {
        label: 'Consulta BW',
        value: workflowModel.bwConsultationValidation,
        controlName: 'bwConsultationValidation'
      }
    ];
  }

  getValueByControlName(controlName: string): boolean | undefined {
    const item = this.workflowList.find(workflow => workflow.controlName === controlName);
    return item ? item.value : undefined;
  }
}
