
<mat-expansion-panel [expanded]="setOpen(type)" (opened)="panelOpenState = true" (closed)="panelOpenState = false" *ngFor="let type of approverTypes">
  <mat-expansion-panel-header>
    <mat-panel-title>{{ renameApproverTypeDescription(type) }}</mat-panel-title>
  </mat-expansion-panel-header>

  <table mat-table matSort class="table" [dataSource]="getFilteredDataSource(type)" style="width: 100%;" >
    <ng-container matColumnDef="approverTypeDescription" class="table__column">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Tipo do Avaliador</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;" > {{renameApproverTypeDescription(element.approverTypeDescription)}} </td>
      </ng-container>

        <ng-container matColumnDef="name" class="table__column">
            <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;" >Nome do Avaliador</th>
            <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.name}} </td>
          </ng-container>

      <ng-container matColumnDef="fee" class="table__column">
        <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;" >Taxa/Preço solicitado(a)</th>
        <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.fee}} </td>
      </ng-container>

      <ng-container matColumnDef="minimunFee" class="table__column">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;" >Taxa/Preço mínimo(a)</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.minimunFee}} </td>
      </ng-container>

      <ng-container matColumnDef="suggestedFee" class="table__column">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;" >Taxa/Preço Sugerido(a)</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.suggestedFee}} </td>
      </ng-container>

      <ng-container *ngIf="displayedColumns.includes('idService')" matColumnDef="idService" class="table__column">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;" >Serviço</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.idService}} - {{element.serviceName}} </td>
      </ng-container>

      <ng-container *ngIf="displayedColumns.includes('transactionTypeId')" matColumnDef="transactionTypeId" class="table__column">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;" >Transação</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.transactionTypeDescription}} </td>
      </ng-container>

      <ng-container matColumnDef="judgment" class="table__column">
          <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center;" >Resultado</th>
          <td mat-cell *matCellDef="let element" style="text-align: center;" >
            <span *ngIf="element.judgment !== null && element.judgment !== undefined">
                {{element.judgment ? 'Aprovado' : 'Rejeitado'}}
            </span>
            <span *ngIf="element.judgment === null || element.judgment === undefined">
                Aguardando
            </span>
          </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-expansion-panel>
