import { Component, OnInit, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { DialogService } from 'src/app/shared/service/dialog.service';
import { AuthService } from 'src/app/shared/service/auth.service';
import { AdminRolesEnum } from 'src/app/shared/enums/admin-roles.enum';
import { Messages } from 'src/app/shared/messages/messages';
import { ComplianceActivitiesModel, ActivitiesStatusEnum } from '../../models/compliance-activities.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { FdFieldConfigs } from 'src/app/shared/fd-form-components/fd-form-components.module';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

@Component({
  selector: 'app-list-cnaes',
  templateUrl: './list-cnaes.component.html',
  styleUrls: ['./list-cnaes.component.scss']
})
export class ListCnaesComponent {

  displayedColumns =
    ['cnaeNumber', 'status', 'lastModifiedDate', 'options_edit'];

  defaultSizeItems = 10;
  formGroup: FormGroup;
  fields: FdFieldConfigs;
  totalPages: number;
  itemsNotFoundByFilter = false;

  @Input() totalItens;

  @Input() dataSource: MatTableDataSource<ComplianceActivitiesModel>;
  originalDataSource = new MatTableDataSource<ComplianceActivitiesModel>();

  @Output() changePage = new EventEmitter<PageEvent>();
  @Output() onSearch = new EventEmitter<void>();
  @Output() deleteItem = new EventEmitter<ComplianceActivitiesModel>();
  @Output() enableItem = new EventEmitter<ComplianceActivitiesModel>();
  @Output() itemDetails = new EventEmitter<ComplianceActivitiesModel>();
  @Output() editItem = new EventEmitter<ComplianceActivitiesModel>();

  @ViewChild('binding') binding: ElementRef;
  expandedElement: ComplianceActivitiesModel;

  icons: { [key:string]: IconDefinition }

  constructor(
    private dialogService: DialogService,
    private authService: AuthService,
    private formBuilder: FormBuilder
  ) { 

  }

  ngOnInit(){
    this.startForms();
    this.dataSource.connect().subscribe(response => {
      if(response && response.length){
        this.originalDataSource.data = this.dataSource.data;
      }
    })
  }

  disconnect() {
    this.dataSource.disconnect();
  }
  
  startForms() {
    this.formGroup = this.formBuilder.group({
      cnaeFilter: [''],
    });

    this.fields = {
      cnaeFilter: {
        label: 'Filtrar CNAE',
        controlName: 'cnaeFilter'
      },
    };
  }

  get hasWriteAccess() {
    return this.authService.isUserInRoles([AdminRolesEnum.CNAE_CLASSIFICATION_WRITE]);
  }

  get hasValue() {
    return this.dataSource && this.dataSource.data && this.dataSource.data.length;
  }

  getStatus(status: ActivitiesStatusEnum) {
    switch (status) {
      case ActivitiesStatusEnum.RESTRICTED:
        return "Restrito";

      case ActivitiesStatusEnum.UNWANTED:
        return "Indesejado";
      
      default:
        return '';
    }
  }

  delete(select: any) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_DELETE_ACTION, () => this.deleteItem.emit(select));
  }

  enable(select: any) {
    this.dialogService.openConfirmDialog(Messages.CONFIRM_ENABLE_ACTION, () => this.enableItem.emit(select));
  }

  detail(complianceActivity: ComplianceActivitiesModel) {
    this.itemDetails.emit(complianceActivity);
  }

  edit(complianceActivity: ComplianceActivitiesModel) {
    this.editItem.emit(complianceActivity);
  }

  change(event: PageEvent): void {
    this.changePage.emit(event);
  }

}
