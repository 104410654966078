import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule } from '../shared/pipes/pipes.module';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FdFormComponentsModule } from '../shared/fd-form-components/fd-form-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ListPricingProposalsComponent } from './components/list-pricing-proposals/list-pricing-proposals.component';
import { ListPricingProposalsHistoryComponent } from './components/list-pricing-proposals-history/list-pricing-proposals-history.component';
import { PricingProposalsListFiltersComponent } from './components/pricing-proposals-list-filters/pricing-proposals-list-filters.component';
import { PricingProposalsDetailComponent } from './components/pricing-proposals-detail/pricing-proposals-detail.component';
import { PricingProposalsComponent } from './pricing-proposals.component';
import { PricingProposalsDetailTableComponent } from './components/pricing-proposals-detail/pricing-proposals-detail-table/pricing-proposals-detail-table.component';



@NgModule({
  declarations: [
    ListPricingProposalsComponent,
    ListPricingProposalsHistoryComponent,
    PricingProposalsListFiltersComponent,
    PricingProposalsDetailComponent,
    PricingProposalsComponent,
    PricingProposalsDetailTableComponent
  ],
  imports: [
    CommonModule,
    MatTableModule,
    PipesModule,
    MatCheckboxModule,
    FdFormComponentsModule,
    FormsModule,
    MatTabsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatToolbarModule,
    FontAwesomeModule,
    MatPaginatorModule,
    MatExpansionModule
  ],
  exports: [],
})
export class PricingProposalsModule { }
