<h3>Configurações Workflow</h3>

<form [formGroup]="formGroup">
  <div fxLayout="row" fxLayoutAlign="left stretch">
    <div fxLayoutGap="20px" class="filter-container">
      <div fxFlex="40">
        <fd-institution (institutionSelected)="changeSelectedInstitution($event)" [formGroup]="formGroup"
          [field]="fields.institution">
        </fd-institution>
      </div>

      <div fxFlex="40">
        <fd-service-contract (serviceContractSelected)="changeSelectedServiceContract($event)" [formGroup]="formGroup" [field]="fields.serviceContract"
          [institution]="selectedInstitution">
        </fd-service-contract>
      </div>

      <div fxFlex="30" fxFlexAlign="center">
        <button class="fd-button" (click)="search()">
          <div class="d-flex  justify-content-center">
            Buscar
          </div>
        </button>
      </div>

      <div fxFlex="30" fxFlexAlign="center">
        <button class="fd-button" (click)="downloadWorkflowHistory()">
          <fa-icon cl class="history-button" matTooltip="Histórico" icon="history"></fa-icon>
        </button>
      </div>
    </div>
  </div>


</form>

<mat-table *ngIf="workflowList && workflowList.length > 0" [dataSource]="workflowList">
  <ng-container matColumnDef="validacoes" class="table-list__column">
    <mat-header-cell *matHeaderCellDef>Validações</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.label}}
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="simNao" class="table-list__column">
    <mat-header-cell *matHeaderCellDef>Sim / Não</mat-header-cell>
    <mat-cell *matCellDef="let element">
      <b *ngIf="!element.value" class="inactive-validation">Não &nbsp;</b>
      <div *ngIf="element.value">Não &nbsp;</div>
      <mat-slide-toggle [(ngModel)]="element.value" [disabled]="!hasWriteAccess">Sim</mat-slide-toggle>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns;" matRipple class="element-row"></mat-row>

</mat-table>


<div *ngIf="workflowList && workflowList.length > 0">
  <fd-button [content]="'Salvar'" (trigger)="save()" [disabled]="!hasWriteAccess"></fd-button>
</div>

