import { PlatformModule } from '@angular/cdk/platform';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import ptBr from '@angular/common/locales/pt';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule, OnDestroy } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faBan, faBars, faHistory, faPlusCircle, faTimes, fas } from '@fortawesome/free-solid-svg-icons';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { ApiStatusModule } from './api-status/api-status.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BwServiceContractConfigModule } from './bw-service-contract-config/bw-service-contract-config.module';
import { CnaeComplianceRegisterModule } from './cnae-compliance-register/cnae-compliance-register.module';
import { CnaeCreditRiskRegisterModule } from './cnae-credit-risk-register/cnae-credit-risk-register.module';
import { ComplianceProposalListModule } from './compliance-proposal-list/compliance-proposals.module';
import { ConciliatorModule } from './conciliator/conciliator.module';
import { ConfigAppComponent } from './config-app/config-app.component';
import { ConfigurationChecklistModule } from './configuration-checklist/configuration-checklist.module';
import { ConfigurationProfessionalLicenseComponent } from './configuration-professional-license/configuration-professional-license/configuration-professional-license.component';
import { ConfigurationSetupModule } from './configuration-setup/configuration-setup.module';
import { ConfigurationThreeDsModule } from './configuration-three-ds/configuration-three-ds.module';
import { ConfigurationSimulatorModule } from './configuration-simulator/configuration-simulator.module';
import { CoreComponent } from './core/core.component';
import { CoreModule } from './core/core.module';
import { CreditRiskProposalsModule } from './credit-risk-proposals/credit-risk-proposals.module';
import { EcommerceCnaeWhitelistModule } from './ecommerce-cnae-whitelist/ecommerce-cnae-whitelist.module';
import { EconomicalGroupConvenienceModule } from './economical-group-convenience/economical-group-convenience.module';
import { EconomicalGroupModule } from './economical-group/economical-group.module';
import { FAQCategoryModule } from './faq-category/faq-category.module';
import { FaqConfigurationModule } from './faq-configuration/faq-configuration.module';
import { FeatureToggleModule } from './feature-toggle/feature-toggle.module';
import { IntegrationTokenConfigurationModule } from "./integration-tokens-configuration/integration-token-configuration.module";
import { LoginModule } from './login/login.module';
import { OperationsProposalsModule } from './operations-proposals/operations-proposals.module';
import { PaymentGatewayModule } from './payment-gateway/payment-gateway.module';
import { PriceCampaignModule } from './price-campaign/price-campaign.module';
import { PriceCheckConfigModule } from './price-check-config/price-check-config.module';
import { PricePremiumUfModule } from './price-premium-uf/price-premium-uf.module';
import { PricingProposalsModule } from './pricing-proposals/pricing-proposals.module';
import { ProfilesModule } from './profile/profiles.module';
import { ReportsComponent } from './reports/reports.component';
import { SearchProposalsModule } from './search-proposals/search-proposals.module';
import { ServiceConfigModule } from './service-config/service-config.module';
import { FdComponentsModule } from './shared/components/fd-components.module';
import { FdFormComponentsModule } from './shared/fd-form-components/fd-form-components.module';
import { BackofficeGuard } from './shared/guards/backoffice.guard';
import { ErrorInterceptor } from './shared/helpers/error.interceptor';
import { JwtInterceptor } from './shared/helpers/jwt.interceptor';
import { CpfCnpjPipe } from './shared/pipes/cpf-cnpj.pipe';
import { ExportExcelService } from './shared/service/export-excel.service';
import { LoadingService } from './shared/service/loading.service';
import { TopicStatusConfigurationModule } from './topic-status-configuration/topic-status-configuration.module';
import { UsersModule } from './users/users.module';
import { ReportsModule } from './reports/reports.module';
import { RECAPTCHA_LANGUAGE, RECAPTCHA_SETTINGS, RecaptchaSettings } from 'ng-recaptcha';
import { LoginService } from './login/services/login.service';
import { ResellerCloverConfigurationModule } from './reseller-clover-configuration/reseller-clover-configuration.module';
import { WorkflowModule } from './workflow/workflow.module';

registerLocaleData(ptBr);

declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    providers?: Provider[];
  }
}
@NgModule({
  declarations: [
    AppComponent,
    CoreComponent,
    ConfigurationProfessionalLicenseComponent,
    ConfigAppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    AppRoutingModule,
    PlatformModule,
    FormsModule,
    FdFormComponentsModule,
    FdComponentsModule,
    ConfigurationSetupModule,
    SearchProposalsModule,
    PriceCheckConfigModule,
    CoreModule,
    UsersModule,
    ServiceConfigModule,
    PricePremiumUfModule,
    LoginModule,
    NgxSpinnerModule,
    PriceCampaignModule,
    ProfilesModule,
    EconomicalGroupModule,
    CnaeComplianceRegisterModule,
    CnaeCreditRiskRegisterModule,
    ConfigurationChecklistModule,
    ComplianceProposalListModule,
    BwServiceContractConfigModule,
    OperationsProposalsModule,
    CreditRiskProposalsModule,
    IntegrationTokenConfigurationModule,
    TopicStatusConfigurationModule,
    EcommerceCnaeWhitelistModule,
    ApiStatusModule,
    ConciliatorModule,
    PricingProposalsModule,
    FaqConfigurationModule,
    FAQCategoryModule,
    FormsModule,
    ReactiveFormsModule,
    EconomicalGroupConvenienceModule,
    PaymentGatewayModule,
    EconomicalGroupModule,
    FeatureToggleModule,
    ConfigurationThreeDsModule,
    ConfigurationSimulatorModule,
    ResellerCloverConfigurationModule,
    ReportsModule,
    WorkflowModule
  ],
  providers: [
    BackofficeGuard,
    CpfCnpjPipe,
    ExportExcelService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    { provide: RECAPTCHA_LANGUAGE, useFactory: (locale: string) => locale, deps: [LOCALE_ID]},
    { provide: RECAPTCHA_SETTINGS,
      useFactory: (loginService: LoginService): RecaptchaSettings => {
        return {siteKey: loginService.recaptchaSiteKey}
      },
      deps: [LoginService],
    },
    {
      provide: APP_INITIALIZER, useFactory: appLoadFactory,
      deps: [LoginService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule implements OnDestroy {
  alive = true;

  constructor(
    private spinner: NgxSpinnerService,
    private loadingService: LoadingService,
    library: FaIconLibrary
  ) {
    this.loadingService.getLoadingStatusObservable().subscribe(isActive => {
      if (isActive) {
        this.spinner.show();
      } else {
        this.spinner.hide();
      }
    });
    library.addIconPacks(fas);
    library.addIcons(faPlusCircle, faTimes, faBars, faBan, faHistory);
  }

  ngOnDestroy() {
    this.alive = false;
  }

}

function appLoadFactory(loginService: LoginService) {
  return () => loginService.loadRecaptchaSiteKey().then(() => console.log(`reCAPTCHA config loaded.`));
}
