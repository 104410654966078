<fd-button fdPrimaryLarge mat-flat-button
    [content]="'download'" (trigger)="download()"> </fd-button>
<mat-tab-group mat-align-tabs="left" animationDuration="0ms">
  <mat-tab label="Básico" *ngIf="false">


    <div fxLayout="column" fxLayoutGap="20px">
      <div fxFlex="50" *ngFor="let item of proposal.priceJudgmentDTO">
        <h4 class="item-title">{{item.idService}} - {{item.serviceName}}</h4>
        <div *ngIf="item.transactionTypeId">
          <h4 class="item-title">{{item.transactionTypeId}} - {{item.transactionTypeDescription}}</h4>
        </div>
        <br>
        <div *ngIf="item.approverTypeDescription">
          <h4 class="item-title">Tipo do Avaliador</h4>
          <b>{{item.approverTypeDescription}}</b>
        </div>

        <div *ngIf="item.cpfCnpj">
          <h4 class="item-title">CPF/CNPJ do Avaliador</h4>
          <b>{{item.cpfCnpj | cpfCnpj }}</b>
        </div>

        <div *ngIf="item.name">
          <h4 class="item-title">Nome do Avaliador</h4>
          <b>{{item.name}}</b>
        </div>

        <div *ngIf="item.fee">
          <h4 class="item-title">Taxa/Preço solicitado(a)</h4>
          <b>{{item.fee}}</b>
        </div>

        <div *ngIf="item.minimunFee">
          <h4 class="item-title">Taxa/Preço mínimo(a)</h4>
          <b>{{item.minimunFee}}</b>
        </div>

        <div *ngIf="item.proposalNumber">
          <h4 class="item-title">Número da proposta</h4>
          <b>{{item.proposalNumber}}</b>
        </div>

        <div *ngIf="item.idService">
          <h4 class="item-title">Serviço</h4>
          <b>{{item.idService}} - {{item.serviceName}}</b>
        </div>

        <div *ngIf="item.judgment !== null && item.judgment !== undefined">
          <h4 class="item-title">Resultado</h4>
          <b>{{item.judgment ? 'Aprovado' : 'Rejeitado'}}</b>
        </div>

        <div *ngIf="item.judgment === null || item.judgment === undefined">
          <h4 class="item-title">Resultado</h4>
          <b>Aguardando</b>
        </div>

        <div *ngIf="item.transactionTypeId">
          <h4 class="item-title">Transação</h4>
          <b>{{item.transactionTypeId}} - {{item.transactionTypeDescription}}</b>
        </div>
        <br>
      </div>
    </div>
  </mat-tab>

  <mat-tab label="Preços/Taxas">
    <app-pricing-proposals-detail-table [dataSource]="dataSourcePriceFeeTechAndMdr" [displayedColumns]="displayedColumnsPriceFee" [approverTypes]="approverTypesTechMdr">
    </app-pricing-proposals-detail-table>
  </mat-tab>

  <mat-tab label="Antecipação Automática">
    <app-pricing-proposals-detail-table [dataSource]="dataSourcePriceFeePrepayment" [displayedColumns]="displayedColumnsPrepayment" [approverTypes]="approverTypesPrepayment">
    </app-pricing-proposals-detail-table>
  </mat-tab>

  <mat-tab label="Endereço">
      <table mat-table matSort class="table" [dataSource]="dataSourcePriceAddress.data" style="width: 100%;" >
          <ng-container matColumnDef="city" class="table__column">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center" >Cidade</th>
              <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.city}} </td>
          </ng-container>

          <ng-container matColumnDef="state" class="table__column">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center" >Estado</th>
              <td mat-cell *matCellDef="let element" style="text-align: center;" > {{element.state}} </td>
          </ng-container>

          <ng-container matColumnDef="typeAddress" class="table__column">
              <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center" >Tipo Endereço</th>
              <td mat-cell *matCellDef="let element" style="text-align: center;" > {{renameTypeAddress(element.typeAddress)}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsPriceAddress; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsPriceAddress;"></tr>
      </table>
  </mat-tab>
  <mat-tab label="Outros">

      <div fxLayout="column" fxLayoutGap="20px" style="padding-top: 50px;">
        <div fxFlex="50">
          <div>
            <h4 class="item-title">Faturamento Anual</h4>
            <b>{{proposal.annualBillingVolume  | currency: 'BRL'}}</b>
          </div>
          <div>
            <h4 class="item-title">Descrição CNAE</h4>
            <b>{{proposal.cnaeDescription}}</b>
          </div>
          <div>
            <h4 class="item-title">Canal</h4>
            <b>{{proposal.channel}}</b>
          </div>
          <div>
            <h4 class="item-title">Sub Canal</h4>
            <b>{{proposal.subChannel}}</b>
          </div>
        </div>

        <div fxFlex="50" *ngIf="proposal && proposal.merchantPrepaymentDTO && proposal.merchantPrepaymentDTO[0]">
          <div>
            <h4 class="item-title">Antecipação Habilitada</h4>
            <b>{{proposal.merchantPrepaymentDTO[0].prepaymentEnable ? 'SIM':'NÃO'}}</b>
          </div>

          <div *ngIf="proposal && proposal.merchantPrepaymentDTO && proposal.merchantPrepaymentDTO[0] && proposal.merchantPrepaymentDTO[0].autoPrepaymentFee" >
            <h4 class="item-title">Antecipação Automática</h4>
            <b>{{proposal.merchantPrepaymentDTO[0].autoPrepaymentFee.toFixed(2)}}%</b>
          </div>

          <div *ngIf="proposal && proposal.merchantPrepaymentDTO && proposal.merchantPrepaymentDTO[0] && proposal.merchantPrepaymentDTO[0].releasedPrepaymentFee" >
            <h4 class="item-title">Antecipação Manual</h4>
            <b>{{proposal.merchantPrepaymentDTO[0].releasedPrepaymentFee.toFixed(2)}}%</b>
          </div>
        </div>
      </div>
  </mat-tab>
</mat-tab-group>
